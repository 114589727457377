import { makeStyles } from "@material-ui/core";

export type LevelLocationDetailCardPropTypes = {
  title: string;
  question: string;
  description: string;
  disabled: boolean;
  src: string;
  imgHeight?: string;
  onClick: () => void;
};

export const useLevelLocationDetailCardStyles = makeStyles({
  mainContainer: {
    width: "17.0625rem",
  },
  imgContainer: {
    width: "15rem",
    height: "15rem",
    background:
      "linear-gradient(180deg, #1873B0 0%, rgba(18, 212, 255, 0.27) 100%)",
    borderRadius: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  cardMainHeading: {
    fontWeight: 300,
    fontSize: "1.5rem",
    lineHeight: "28px",
    color: " #1C1C1C",

    marginTop: "1.5625rem",
    marginBottom: "1.3rem",
  },

  content: {
    fontSize: "0.875rem",
    lineHeight: "15px",
    color: "#1C1C1C",
    marginTop: ".5rem",
  },
  btn: {
    width: "9.5rem",
    fontSize: "0.75rem",
    lineHeight: "14px",

    marginTop: "2.5rem",
  },
});

export type WelcomeToTheArupModalPropTypes = {
  open: boolean;
  closeModalHandler: () => void;
};
