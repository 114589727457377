import React from "react";
import { Button, Typography } from "@material-ui/core";

import ChevronRightIcon from "../../../Icons/ChevronRightIcon";

import {
  ClimateVariableTypeCardPropTypes,
  useClimateVariableTypeCardStyles,
} from "./useStyles";

const ClimateVariableTypeCard = (props: ClimateVariableTypeCardPropTypes) => {
  const classes = useClimateVariableTypeCardStyles();

  const {
    title,
    description,
    btnBackGroundColor,
    icon,
    onClick,
    isAvailable,
  } = props;

  return (
    <div
      className={classes.mainContainer}
      style={{ pointerEvents: isAvailable ? "auto" : "none" }}
    >
      <div className={classes.imgContainer}> {icon} </div>

      {/* ------------CARD CONTENT------------ */}
      <div style={{ width: "75%" }}>
        <Typography variant="h6" className={classes.cardMainHeading}>
          {title}
        </Typography>

        <Typography variant="body1" className={classes.content}>
          {description}
        </Typography>
      </div>
      <Button
        onClick={onClick}
        disableElevation
        color="primary"
        variant="contained"
        className={classes.btn}
        style={{ background: btnBackGroundColor }}
        endIcon={
          <ChevronRightIcon htmlColor="#000" style={{ fontSize: "11px" }} />
        }
      >
        {isAvailable ? `Search ${title}` : "Coming Soon"}
      </Button>
    </div>
  );
};

export default ClimateVariableTypeCard;
