import React from "react";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box width="100%">{children}</Box>}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

type CustomTabsProps = {
  tabNames: string[];
  tabContent: React.ReactNode[];
};

/**
 * @props
 ** tabNames --- An Array of strings,
 ** tabContent --- it must be an array of react components with hardcoded key.
 *
 * @description the tab names array and components array order matters
 * The component on the 0 index in the components array is for the tab that is on the 0
 *  index in the tabNames array too.
 *
 * @example tabNames = ['first name', 'second name']
    componets = [<ThisCmpIsForFirstName key={0} />, <ThisCmpIsForSecondName key={1} />]
 */

export default function CustomTabs(props: CustomTabsProps) {
  const { tabNames, tabContent } = props;

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="transparent" elevation={0}>
        <Tabs
          value={value}
          onChange={handleChange}
          style={{
            fontSize: "0.75rem",
            lineHeight: "14px",
            color: "#000000",
            width: "fit-content",
          }}
          TabIndicatorProps={{
            style: { height: "2px", background: "#767676" },
          }}
          variant="fullWidth"
        >
          {tabNames.map((name, i) => (
            <Tab
              style={{ width: "88px" }}
              key={i}
              label={name}
              {...a11yProps(i)}
            />
          ))}
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {tabContent.map((item, i) => (
          <TabPanel key={i} value={value} index={i} dir={theme.direction}>
            {item}
          </TabPanel>
        ))}
      </SwipeableViews>
    </div>
  );
}
