import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

const CalendarIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.4 11.7V4.55H1.3V11.7H10.4ZM8.45 0H9.75V1.3H10.4C11.1215 1.3 11.7 1.885 11.7 2.6V11.7C11.7 12.4215 11.1215 13 10.4 13H1.3C0.5785 13 0 12.415 0 11.7V2.6C0 1.8785 0.5785 1.3 1.3 1.3H1.95V0H3.25V1.3H8.45V0ZM5.2 5.525H6.5V7.475H8.45V8.775H6.5V10.725H5.2V8.775H3.25V7.475H5.2V5.525Z" />
    </SvgIcon>
  );
};

export default CalendarIcon;
