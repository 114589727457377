import axios, { AxiosRequestConfig } from "axios";
import { indicatorThresholdOptions } from "../../modules/RequestForm/Parameters/data";
import {
  IDateRange,
  IThresholdValidation,
  IValueValidation,
} from "../../modules/RequestForm/Parameters/interfaces";
import {
  formatIndicatorInput,
  convertTemperatureIfNeeded,
} from "../../modules/RequestForm/Parameters/utils/utils";
import { axiosInstance } from "../axios";

export const getDataWithParameters = (
  indicator: string,
  historicalDateRange: IDateRange,
  futureDateRange: IDateRange,
  threshold: IThresholdValidation,
  latitudeInput: IValueValidation,
  longitudeInput: IValueValidation,
  email: IValueValidation,
) => {
  const indicatorInput = formatIndicatorInput(indicator);

  let queryThresholdValue = "" as any; // If empty a default value of 32.2 is provided in the back end.
  if (
    indicator === indicatorThresholdOptions.moreThan ||
    indicator === indicatorThresholdOptions.lessThan
  ) {
    // Converts Farenheit temperature to Celsius before appending it to the query string. Back end only accepts Celsius at the moment.
    queryThresholdValue = convertTemperatureIfNeeded(threshold);
  }

  const paramQuery =
    `indicator=${indicatorInput}` +
    `&threshold=${queryThresholdValue}` +
    `&historicalstart=${historicalDateRange.start}&historicalend=${historicalDateRange.end}` +
    `&futurestart=${futureDateRange.start}&futureend=${futureDateRange.end}` +
    `&latitude=${latitudeInput.value}` +
    `&longitude=${longitudeInput.value}` +
    `&email=${email.value}`;
  return axiosInstance
    .get(`default/runSingleClimateRequest?${paramQuery}`)
    .then((response: any) => {
      return response.data;
    });
};

export const fetchSearchedData = (requestUrl: string) => {
  return axios.get(requestUrl).then((response: any) => {
    return response.data;
  });
};

//-------------------------------------\|/ LOGIN API \|/------------------------------------

export interface Credentials {
  email: string;
  password: string;
}

interface LoginApiResponse {
  token: string;
}

export const BASE_API_URL = process.env.REACT_APP_API_BASE_URL;
export const onSignIn = async (data: Credentials) => {
  const requestConfig: AxiosRequestConfig = {
    method: "post",
    url: BASE_API_URL + "/auth/login",
    data,
  };
  try {
    const {
      data: { token },
    } = await axios.request<LoginApiResponse>(requestConfig);

    storeToken(token);
    return {
      token: token,
    };
  } catch (e) {
    return { error: e.response.data.message };
  }
};

export default onSignIn;

export const DATA_TOOLKIT_TOKEN = "data_token_tookit";
const storeToken = (token: string) => {
  localStorage.setItem(DATA_TOOLKIT_TOKEN, token);
};
