let BASE_API_URL = "https://kliima-dev-api.arup.com/";
let REDIRECTION_URL = "http://localhost:3000/";
let CLIENT_ID = "75fb7171-7911-4d5f-9d97-d8ef64700aae";

if (process.env.REACT_APP_ENV === "development") { 
  BASE_API_URL = "https://kliima-dev-api.arup.com/";
  CLIENT_ID = "75fb7171-7911-4d5f-9d97-d8ef64700aae";
  REDIRECTION_URL = "https://phase2-ui-changes.d2kf94g8xz9qdq.amplifyapp.com";
}

if (process.env.REACT_APP_ENV === "qa") { 
  BASE_API_URL = "https://qa.kliima-api.arup.com/";
  CLIENT_ID = "75fb7171-7911-4d5f-9d97-d8ef64700aae";    //this is for digiryte 
  // client_id = "cb44c30f-54da-45fd-b9b3-ae9e06044861";  //this is for Arup
  REDIRECTION_URL = "https://develop.d2aio0vun6cq2a.amplifyapp.com/";
}

if (process.env.REACT_APP_ENV === "withoutbackend") { 
  BASE_API_URL = "https://qa.kliima-api.arup.com/";
  CLIENT_ID = "cb44c30f-54da-45fd-b9b3-ae9e06044861";
  REDIRECTION_URL = "https://without-backend-phase2-ui-changes.d31wm5pq1to0aj.amplifyapp.com/";
}

if (process.env.REACT_APP_ENV === "production") { 
  BASE_API_URL = "http://kliima-api.arup.com/";
  CLIENT_ID = "cb44c30f-54da-45fd-b9b3-ae9e06044861";
  REDIRECTION_URL = "https://www.kliima.arup.com";
} 

let AZURE_AUTH_CONFIG = { 
  auth: {
    clientId: CLIENT_ID, //this is for digiryte
    // clientId: "cb44c30f-54da-45fd-b9b3-ae9e06044861", //this is for Arup
    authority: "https://login.microsoftonline.com/consumers/",
    redirectUri: REDIRECTION_URL,
  },
}; 

let MAP_BOX_CONFIG = {
  mapboxApiAccessToken:
    "pk.eyJ1IjoiYmhhcmF0ZGlnaXJ5dGUiLCJhIjoiY2tuNHBpZzhrMXR5bjJvbWx6aDZtMnBidyJ9.ESNRB4MXx_XtzSxU4zqEcQ",
  mapStyle: "mapbox://styles/bharatdigiryte/cknowpbv51oka17o1k7c3eyu2",
};

export { BASE_API_URL, AZURE_AUTH_CONFIG, MAP_BOX_CONFIG };
