import React, { useContext } from "react";
import ReactDOM from "react-dom";
import { ThemeProvider, CssBaseline } from "@material-ui/core";
// import { Route, Switch } from "react-router";
//import { createBrowserHistory } from "history";

import "./index.scss";
import { mount, route } from "navi";
import { Router } from "react-navi";
import { withAuthentication } from "./common/api/authenticatedRoute";

import * as serviceWorker from "./serviceWorker";
//import Frame from "./common/components/Frame/Frame";
import Layout from "../src/common/components/Layout/Layout";
import LocationSelectPage from "../src/common/components/Pages/LocationSelectPage/LocationSelectPage";
import ErrorBoundary from "./common/ErrorBoundary";
import MapBox from "../src/common/components/Pages/MapBox/MapBox";

import Login from "./common/components/Login/Login";
import Authentication from "./common/components/Login/Authentication";

import theme from "./common/theme";
import ServicesApi from "./common/api/services";
import {
  SnackbarContext,
  SnackbarProvider,
} from "./common/components/context/SnackbarContext";
import CustomSnackbar from "./common/components/partials/CustomSnackbar";
import { LocationSelectPageContextProvider } from "./common/components/context/LocationSelectPageContext";

const App = () => {
  //const browserHistory = createBrowserHistory();

  const { snackbarState } = useContext(SnackbarContext);

  const {
    snackbar: { open, color, message },
  } = snackbarState;

  const routes = mount({
    "/": withAuthentication(
      route({
        title: "Dashboard",
        view: (
          <Layout>
            <LocationSelectPage />
          </Layout>
        ),
      })
    ),
    "/dashboard": withAuthentication(
      route({
        title: "Dashboard",
        view: <MapBox />,
      })
    ),
    "/login": route({
      title: "Login Page",
      view: <Login />,
    }),
    "/authentication": route({
      title: "Authentication",
      view: <Authentication />,
    }),
  });

  return (
    <>
     
        <ThemeProvider theme={theme}>
          <CustomSnackbar open={open} color={color} message={message} />

          <CssBaseline />

          <Router
            routes={routes}
            context={{ token: localStorage.getItem("data_token_tookit") }}
          />
        </ThemeProvider>
      
    </>
  );
};

export default App;
ServicesApi.init();
ReactDOM.render(
  <SnackbarProvider>
    <ErrorBoundary>
      <LocationSelectPageContextProvider>
        <App />
      </LocationSelectPageContextProvider>
    </ErrorBoundary>
  </SnackbarProvider>,
  document.getElementById("root")
);

// Re-render the complete React component tree
// This should only be used to reflect language preference changes
export function updateComponentTree() {
  ReactDOM.unmountComponentAtNode(
    document.getElementById("root") as HTMLElement
  );
  ReactDOM.render(
    <ErrorBoundary>
      <LocationSelectPageContextProvider>
        <App />
      </LocationSelectPageContextProvider>
    </ErrorBoundary>,
    document.getElementById("root") as HTMLElement
  );
}

serviceWorker.unregister();
