import React, { useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";

import Grid from "@material-ui/core/Grid";
import { Alert } from "@material-ui/lab";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

import ArupLogo from "../Icons/ArupLogo";
import arupNewLogo from "../../../images/arupNewLogo.svg";

import { useSigninStyles } from "./useStyles";
import { Button } from "@material-ui/core";

export default function SignIn({ signedIn }) {
  const classes = useSigninStyles();
  const [error, setError] = useState("");

  return (
    <section className={classes.container}>
      <div className={classes.circle}>
        <svg
          style={{
            position: "absolute",
            // width: isSmallScreen ? "" : "48.75rem",
            // height: isSmallScreen ? "39.375rem" : "48.75rem",
            height: "39.375rem",
          }}
          viewBox="0 0 782 782"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="390.65"
            cy="390.788"
            r="383.816"
            stroke="#7CD4F2"
            strokeWidth="13.155"
            strokeDasharray="2.32 2.32"
          />
        </svg>

        <Container component="main" maxWidth="xs" style={{ zIndex: 1 }}>
          <CssBaseline />
          <div className={classes.paper}>
            <div>
              <img
                onClick={() =>
                  window.open(
                    "https://arup.sharepoint.com/sites/Kliima/SitePages/Home.aspx",
                    "_blank"
                  )
                }
                src={arupNewLogo}
                alt="arup-logo"
                style={{ height: "6.0625rem", cursor: "pointer" }}
              />
            </div>

            <Grid container justify="center">
              <Button
                className="newLogin"
                variant="contained"
                color="secondary"
                disableElevation
                type="submit"
                style={{ marginTop: ".5625rem", width: "9.5rem" }}
                onClick={() => {
                  signedIn();
                }}
              >
                Login
              </Button>
            </Grid>
            <Grid container justify="center">
              <Grid item>
                <a
                  href="https://arup.sharepoint.com/sites/Kliima/SitePages/Kliima-Team.aspx"
                  className={classes.link}
                  style={{ marginTop: "1.5rem", textDecoration: "none" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Having trouble accessing Kliima?
                </a>
              </Grid>

              <Grid item>
                <a
                  href="https://arup.sharepoint.com/sites/Kliima/SitePages/Legal-Disclaimer.aspx"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.link}
                  style={{ marginTop: "2.5rem", marginBottom: 0 }}
                >
                  Please read the legal disclaimer before use
                </a>
              </Grid>
            </Grid>
          </div>

          {error.length > 0 && (
            <Alert
              severity="error"
              onClose={() => {
                setError("");
              }}
            >
              {error}
            </Alert>
          )}
        </Container>
      </div>

      <Typography
        className={classes.link}
        align="center"
        style={{
          maxWidth: "27.75rem",
          fontSize: "0.875rem",
          color: "#fff",
          marginBottom: "0.5rem",
        }}
      >
        Kliima is for use by Arup employees only. Clients should not be given
        direct access to the tool.
      </Typography>

      <Typography
        style={{
          color: "#CFCFCF",

          marginBottom: "1.8125rem",
        }}
      >
        <ArupLogo
          style={{ width: "42px", height: "13px", marginBottom: "-1.5px" }}
        />
        &nbsp; {new Date().getFullYear()}
      </Typography>
    </section>
  );
}
