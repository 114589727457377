import React, { useContext, useEffect } from "react";
import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import CustomModal from "../../../partials/CustomModal";
import DataSetCard from "./DataSetCard";

import windDataSetPng from "../../../../../images/windDataSet.png";
import { LocationSelectContext } from "../../../context/LocationSelectPageContext";
// import Pagination from "../../../partials/Pagination";

import {
  temperatureRelatedSources,
  windRelatedSources,
  humdityRelatedSources,
  snowRelatedSources,
  marineRelatedDataSources,
  precipitationRelatedDataSources,
  SourceDataType,
  Ukcp18ProbTempratureRelatedResources,
  Ukcp18ProbPrecipationRelatedResources,
  HadukTemperatureRelatedResourcesTop,
  HadukTemperatureRelatedResourcesBottom,
  HadukPrecipationRelatedResourcesTop,
  HadukPrecipationRelatedResourcesBottom,
  Ukcp18HighResTemperatureRelatedResources,
  Ukcp18HighResPrecipitationRelatedResources,
  temperatureRelatedSourcesLOCA,
} from "./data";
import {
  CORDEX,
  HAD_UK,
  LOCA,
  UKCP18_HIGH_RES,
  UKCP18_PROB,
} from "../datasets";
import { doesStringContainThisText } from "../../../../../utility/helperFunctions";

const useStyles = makeStyles({
  btn: {
    width: "8.7975rem",
    fontSize: "0.8374rem",
    lineHeight: "16px",
    color: " #1C1C1C",
  },
  imageHead: {
    fontSize: "16px",
  },
  higRes: {
    marginTop: "10px",
    marginBottom: "10px",
  },
});

type PropsType = {
  open: boolean;
  closeModalHandler: () => void;
};

const WindRelatedVariablesModal = (props: PropsType) => {
  const classes = useStyles();

  const { open, closeModalHandler } = props;

  const {
    windRelatedVariablesModalState,
    configureDatasetVisibleState,
    selectedClimateVariableSourceState,
    selectedClimateVariableState,
    variableSelectionModalState,
    // climateDatasetModalState,
    selectedDatasetState,
    timePeriodSelectionModalState,
    selectedResolutionState,
    resolutionErrorState,
    metricState,
    lessThanMoreThanValuesState,
  } = useContext(LocationSelectContext);

  const { lessThanMoreThanValues } = lessThanMoreThanValuesState;

  const { state, setState } = metricState;

  const { selectedDataset } = selectedDatasetState;

  const { setIsWindRelatedVariablesModalOpen } = windRelatedVariablesModalState;

  const { setIsConfigureDatasetVisible } = configureDatasetVisibleState;

  const { setIsVariableSelectionModalOpen } = variableSelectionModalState;

  const { setIsResolutionSelected, isResolutionSelected } =
    resolutionErrorState;

  // const { setIsClimateDatasetModalOpen } = climateDatasetModalState;

  // // const { setIsProjectDescriptionModalOpen } = projectDescriptionModalState;

  const { setTimePeriodSelectionModal } = timePeriodSelectionModalState;

  const { setSelectedResolution, selectedResolution } = selectedResolutionState;

  const { selectedClimateVariableSource, setSelectedClimateVariableSource } =
    selectedClimateVariableSourceState;

  const { selectedClimateVariable } = selectedClimateVariableState;

  let data = [] as SourceDataType[];
  let dataDup = [] as SourceDataType[];

  // console.log("sleected dat set is==============", selectedDataset)

  switch (selectedClimateVariable) {
    case "Temperature":
      if (selectedDataset === UKCP18_PROB) {
        data = Ukcp18ProbTempratureRelatedResources;
      } else if (selectedDataset === HAD_UK) {
        data = HadukTemperatureRelatedResourcesTop;
        dataDup = HadukTemperatureRelatedResourcesBottom;
      } else if (selectedDataset === UKCP18_HIGH_RES) {
        data = Ukcp18HighResTemperatureRelatedResources;
      } else if (selectedDataset === LOCA) {
        data = temperatureRelatedSourcesLOCA;
      } else {
        data = temperatureRelatedSources;
      }
      break;
    case "Wind":
      data = windRelatedSources;
      break;
    case "Humidity":
      data = humdityRelatedSources;
      break;
    case "Snow":
      data = snowRelatedSources;
      break;
    case "Marine":
      data = marineRelatedDataSources;
      break;
    case "Precipitation":
      if (selectedDataset === UKCP18_PROB) {
        data = Ukcp18ProbPrecipationRelatedResources;
      } else if (selectedDataset === HAD_UK) {
        data = HadukPrecipationRelatedResourcesTop;
        dataDup = HadukPrecipationRelatedResourcesBottom;
      } else if (selectedDataset === UKCP18_HIGH_RES) {
        data = Ukcp18HighResPrecipitationRelatedResources;
      } else {
        data =
          selectedDataset === CORDEX
            ? [
                {
                  title: "Maximum 1 day rainfall",
                  unit: "Rx1day Units: %",
                  description:
                    "The change in annual maximum value of average daily precipitation",
                },
                {
                  title: "Maximum 5 day rainfall",
                  unit: "Rx5day Units: %",
                  description:
                    "The change in annual maximum value of average daily precipitation over 5 consecutive days",
                },
                {
                  title: "Cumulative dry days",
                  unit: "CDD Units: %",
                  description:
                    "Maximum length of dry spell (consecutive days where daily precipitation is less than 1mm)",
                },
                {
                  title: "Average daily precipitation",
                  unit: "Pr Units: %",
                  description: "The change in average daily precipitation ",
                },
                {
                  title: "Precipitation threshold more than",
                  unit: "Units: no. days",
                  description:
                    "Annual count when average daily precipitation is above threshold value",
                },
                {
                  title: "Precipitation threshold less than",
                  unit: "Units: no. days",
                  description:
                    "Annual count when average daily precipitation is below threshold value",
                },
              ]
            : precipitationRelatedDataSources;
      }
      break;
    default:
      break;
  }

  // const [currentPage, setCurrentPage] = useState(1);
  // const [itemsPerPage] = useState(6);

  // const indexOfLastItem = currentPage * itemsPerPage;
  // const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  // const dupItems = dataDup.slice(indexOfFirstItem, indexOfLastItem);

  // const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const resolutionChangeHandler = (event) => {
    const value = event.target.value;
    setSelectedResolution(value);
    setIsResolutionSelected(true);
  };

  const value = () => {
    if (doesStringContainThisText(state.metric, "less than")) {
      return lessThanMoreThanValues.thresholdValueLessThan;
    }

    return lessThanMoreThanValues.thresholdValueMoreThan;
  };

  useEffect(() => {
    if (selectedDataset === UKCP18_PROB) {
      setSelectedResolution("25km");
    }
  }, [selectedDataset, setSelectedResolution]);

  const checkIsResolutionSelected = () => {
    if (selectedDataset === UKCP18_HIGH_RES && selectedResolution === "") {
      setIsResolutionSelected(false);
    } else {
      setIsWindRelatedVariablesModalOpen(false);
      setIsConfigureDatasetVisible(true);
      // setIsProjectDescriptionModalOpen(true);
      // setIsClimateDatasetModalOpen(true);
      setTimePeriodSelectionModal(true);
    }
  };

  return (
    <CustomModal
      title={`${selectedClimateVariable} Related Metric`}
      isOpen={open}
      subTitle=""
      onClick={closeModalHandler}
      paragraph={
        <p
          style={
            {
              // marginTop: "-3rem"
            }
          }
        >
          Next, select a climate variable parameter to display...
        </p>
      }
    >
      <div style={{ marginTop: "10px" }}>
        {selectedDataset === HAD_UK && (
          <div>
            <p style={{ fontWeight: "bold" }}>
              Observational data can be extracted for multiple resolutions - see
              <a
                style={{ color: "rgb(28, 121, 180)", textDecoration: "none" }}
                href="https://arup.sharepoint.com/sites/Kliima/SitePages/Home.aspx"
                rel="noreferrer"
                target="_blank"
              >
                Sharepoint
              </a>
              for guidance, particularly when used alongslide UKCP18 or other
              projection data.
            </p>
            <Box display="flex" flexDirection="column" mb="3.125rem">
              <select
                style={{
                  padding: "3px 10px",
                  width: "15.625rem",
                  marginBottom: "2px",
                }}
                onChange={resolutionChangeHandler}
                value={selectedResolution}
              >
                <option selected hidden>
                  Select Resolution
                </option>
                <option value="25km">25km</option>
                <option value="12km">12km</option>
                <option value="5km">5km</option>
                {selectedDataset !== HAD_UK && <option value="1km">1km</option>}
              </select>
              {!isResolutionSelected && (
                <Typography variant="caption" color="error">
                  *Please select Resolution
                </Typography>
              )}
            </Box>
          </div>
        )}
        {selectedDataset === UKCP18_HIGH_RES ||
          (selectedDataset === UKCP18_PROB && (
            <div className={classes.higRes}>
              <b className={classes.imageHead}>
                {selectedDataset === UKCP18_PROB
                  ? " UKCP18 Probabilistic Projections (25km)"
                  : "UKCP18 Regional (12km) and Local (5km) Projections"}
              </b>
              <p>
                {selectedDataset === UKCP18_PROB
                  ? "Probabilisitc projections provide the most comprehensive data in terms of sample size to represent uncertainty, and multiple emission scenarios. RCP2.6, RCP4.5. RCP6.0 and RCP8.5 will be extracted."
                  : "Extreme values can only be derived from timeseries projections which are more limited in terms of sample uncertainty (12 models) and emission scenario (RCP8.5 only)"}
              </p>
            </div>
          ))}
        {selectedDataset === UKCP18_HIGH_RES && (
          <Box display="flex" flexDirection="column" mb="3.125rem">
            <select
              style={{
                padding: "3px 10px",
                width: "15.625rem",
                marginBottom: "2px",
              }}
              onChange={resolutionChangeHandler}
              value={selectedResolution}
            >
              <option selected hidden>
                Select Resolution
              </option>
              <option value="12km">Regional (12km)</option>
              <option value="5km">Local (5km)</option>
            </select>
            {!isResolutionSelected && (
              <Typography variant="caption" color="error">
                *Please select Resolution
              </Typography>
            )}
          </Box>
        )}

        {selectedDataset === HAD_UK && (
          <b className={classes.imageHead}>Average Climate Variables</b>
        )}
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            marginTop: selectedDataset === HAD_UK ? "10px" : undefined,
          }}
        >
          {data.map((item, i) => (
            <DataSetCard
              onSelect={() => {
                setSelectedClimateVariableSource(item.title);
              }}
              title={item.title}
              description={item.description}
              unit={item.unit}
              selected={item.title === selectedClimateVariableSource}
              key={i}
              imgPath={windDataSetPng}
            />
          ))}
        </div>
        {selectedDataset === HAD_UK && (
          <b className={classes.imageHead}>Extreme Climate Variables</b>
        )}
        {selectedDataset === HAD_UK && (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              marginTop: "10px",
            }}
          >
            {dataDup.map((item, i) => (
              <DataSetCard
                onSelect={() => {
                  setSelectedClimateVariableSource(item.title);
                }}
                title={item.title}
                description={item.description}
                unit={item.unit}
                selected={item.title === selectedClimateVariableSource}
                key={i}
                imgPath={windDataSetPng}
              />
            ))}
          </div>
        )}
        <Grid
          item
          container
          justify="center"
          style={{ marginBottom: "2.25rem" }}
        >
          <Button
            className={classes.btn}
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => {
              setIsWindRelatedVariablesModalOpen(false);
              setIsVariableSelectionModalOpen(true);
            }}
          >
            Go Back
          </Button>
          <Button
            className={classes.btn}
            variant="contained"
            color="secondary"
            disableElevation
            style={{ marginLeft: "3.625rem" }}
            onClick={checkIsResolutionSelected}
            disabled={
              selectedClimateVariableSource.includes("threshold")
                ? selectedDataset === HAD_UK ? !selectedResolution || !value() : !value()
                : selectedDataset === HAD_UK
                ? !selectedResolution || !selectedClimateVariableSource
                : !selectedClimateVariableSource
            }
          >
            Continue
          </Button>
        </Grid>
      </div>

      {/* <Grid container justify="center">
        {data.length > 6 && (
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={data.length}
            paginate={paginate}
            currentPageNumber={currentPage}
          />
        )}
      </Grid> */}
    </CustomModal>
  );
};

export default WindRelatedVariablesModal;
