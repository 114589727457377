import React, { useContext } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { CustomSnackbarType } from "../../../types/CustomSnackbarTypes";
import { SnackbarContext } from "../context/SnackbarContext";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={0} variant="filled" {...props} />;
}

const useStyles = makeStyles(() => ({
  alertStyles: {
    width: "23rem",
    height: "3rem",
    borderRadius: "8px",
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: "normal",
    padding: "0 16px",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default function CustomSnackbar(props: CustomSnackbarType) {
  const classes = useStyles();

  const { snackbarState } = useContext(SnackbarContext);

  const { snackbar, setSnackbar } = snackbarState;

  const { open, color, message } = props;

  const handleClose = (_event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert
          className={classes.alertStyles}
          style={{ color: color === "warning" ? "#1C1C1C" : "#fff" }}
          onClose={handleClose}
          severity={color}
          id="mui-alert-snackbar"
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
