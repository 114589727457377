import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

const MapMarkerIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      width="8"
      height="11"
      viewBox="0 0 8 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4 5.225C3.62112 5.225 3.25776 5.08013 2.98985 4.82227C2.72194 4.56441 2.57143 4.21467 2.57143 3.85C2.57143 3.48533 2.72194 3.13559 2.98985 2.87773C3.25776 2.61987 3.62112 2.475 4 2.475C4.37888 2.475 4.74224 2.61987 5.01015 2.87773C5.27806 3.13559 5.42857 3.48533 5.42857 3.85C5.42857 4.03057 5.39162 4.20937 5.31983 4.37619C5.24804 4.54301 5.14281 4.69459 5.01015 4.82227C4.8775 4.94995 4.72001 5.05123 4.54669 5.12033C4.37337 5.18944 4.1876 5.225 4 5.225ZM4 0C2.93913 0 1.92172 0.405624 1.17157 1.12764C0.421427 1.84965 0 2.82892 0 3.85C0 6.7375 4 11 4 11C4 11 8 6.7375 8 3.85C8 2.82892 7.57857 1.84965 6.82843 1.12764C6.07828 0.405624 5.06087 0 4 0Z" />
    </SvgIcon>
  );
};

export default MapMarkerIcon;
