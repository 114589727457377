import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Button, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";

import SearchBox from "../../partials/SearchBox";
import CustomDropdown from "../../partials/CustomDropdown";
import { CSSTransition } from "react-transition-group";
import { LocationSelectContext } from "../../context/LocationSelectPageContext";
import { RegionContext } from "../../context/RegionContext";

import {
  ConfigureDatasetPropTypes,
  useConfigureDatasetStyles,
} from "./useStyles";
import LatLonTextField from "../../partials/LatLonTextField";
import { CustomDropdownMenuItemsType } from "../../../../types/types";
import {
  CMIP6,
  CORDEX,
  HAD_UK,
  LOCA,
  UKCP18_HIGH_RES,
  UKCP18_PROB,
} from "./datasets";

type RadioType = "ui-1" | "ui-2";

const ConfigureDataset = ({ isVisible }: ConfigureDatasetPropTypes) => {
  const classes = useConfigureDatasetStyles();

  const { mapdata, geoJSON } = useContext(RegionContext);

  const [map] = mapdata;

  const [geoJson] = geoJSON;

  const {
    selectedDatasetState,
    projectDescriptionModalState,
    selectedClimateVariableSourceState,
    selectedClimateVariableState,
    timePeriodSelectionModalState,
    retrivableData,
    climateDatasetModalState,
    lessThanMoreThanValuesState,
    focusOnHistoryProjectionDpdState,
    doesEachFieldOfConfigDatasetCardHasValueState,
    historicalYearState,
    projectedYearState,
    timeDurationState,
    yearDurationState,
    selectedProjectedYearState,
    selectedResolutionState,
    configureDatasetHistoricalDataState,
  } = useContext(LocationSelectContext);
  //eslint-disable-next-line
  const [retriveData, setRetriveData] = retrivableData;
  const { selectedResolution } = selectedResolutionState;
  const {
    selectedProjectedYear,
    setSelectedProjectedYear,
  } = selectedProjectedYearState;

  const { lessThanMoreThanValues } = lessThanMoreThanValuesState;

  const { selectedDataset, setSelectedDataset } = selectedDatasetState;

  const { selectedClimateVariableSource } = selectedClimateVariableSourceState;

  const { selectedClimateVariable } = selectedClimateVariableState;

  const { setIsClimateDatasetModalOpen } = climateDatasetModalState;

  const {
    setIsProjectDescriptionModalOpen,
    isProjectDescriptionModalOpen,
  } = projectDescriptionModalState;

  const { setTimePeriodSelectionModal } = timePeriodSelectionModalState;

  const {
    shouldFocusOnHistoryProjectionDpd,
    setShouldFocusOnHistoryProjectionDpd,
  } = focusOnHistoryProjectionDpdState;

  const {
    setDoesEachFieldOfConfigDatasetCardHasValue,
    doesEachFieldOfConfigDatasetCardHasValue,
  } = doesEachFieldOfConfigDatasetCardHasValueState;

  const { state, setState } = configureDatasetHistoricalDataState;

  const [historicalDataDpdOptions, setHistoricalDataDpdOptions] = useState<
    CustomDropdownMenuItemsType[]
  >([]);

  const [projectedDataDpdOptions, setProjectedDataDpdOptions] = useState<
    CustomDropdownMenuItemsType[]
  >([]);

  const { historicalYear, setHistoricalYear } = historicalYearState;
  const { projectedYear, setProjectedYear } = projectedYearState;

  // const [projectedYear, setProjectedYear] = useState({
  //   from: "",
  //   to: "",
  // });
  const [uiType, setUiType] = useState<RadioType>("ui-1");

  const historicalDpdRef = useRef<HTMLInputElement | null>(null);
  const projectionDpdRef = useRef<HTMLInputElement | null>(null);

  // const [datasets, setDatasets] = useState<CustomDropdownMenuItemsType[]>([]);

  // useEffect(() => {
  //   const sets: CustomDropdownMenuItemsType[] = [];

  //   availableDatasets.forEach((item) => {
  //     sets.push({ label: item.name, disabled: !item.isAvailable });
  //   });

  //   setDatasets(sets);
  // }, []);

  const onChangeHandler = (event) => {
    yearDurationState[1](event.target.value);
    setState((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
    if(event.target.name === "projection"){
      setSelectedProjectedYear(event.target.value);
    }

    //if user select the dataset from the dropdown
    // the selected option will be selected into the modal
    event.target.name === "dataset" && setSelectedDataset(event.target.value);
  };

  const setDpdOptions: () => void = () => {
    switch (state.dataset) {
      case CORDEX:
        setHistoricalDataDpdOptions([
          { label: "1986-2005" },
          { label: "1976-2005" },
        ]);

        if (state.from === "1986-2005") {
          setProjectedDataDpdOptions([
            { label: "2010-2029" },
            { label: "2020-2039" },
            { label: "2030-2049" },
            { label: "2040-2059" },
            { label: "2050-2069" },
            { label: "2060-2079" },
            { label: "2070-2089" },
          ]);
        } else if (state.from === "1976-2005") {
          setProjectedDataDpdOptions([
            { label: "2010-2039" },
            { label: "2020-2049" },
            { label: "2030-2059" },
            { label: "2040-2069" },
            { label: "2050-2079" },
            { label: "2060-2089" },
          ]);
        }
        break;

      case LOCA:
        setHistoricalDataDpdOptions([
          { label: "1986-2005" },
          { label: "1976-2005" },
        ]);
        if (state.from === "1986-2005") {
          setProjectedDataDpdOptions([
            { label: "2010-2029" },
            { label: "2020-2039" },
            { label: "2030-2049" },
            { label: "2040-2059" },
            { label: "2050-2069" },
            { label: "2060-2079" },
            { label: "2070-2089" },
            { label: "2080-2099" },
          ]);
        } else if (state.from === "1976-2005") {
          setProjectedDataDpdOptions([
            { label: "2010-2039" },
            { label: "2020-2049" },
            { label: "2030-2059" },
            { label: "2040-2069" },
            { label: "2050-2079" },
            { label: "2060-2089" },
            { label: "2070-2099" },
          ]);
        }
        break;

      case UKCP18_PROB:
        setHistoricalDataDpdOptions([
          { label: "1981-2000" },
          { label: "1981-2010" },
        ]);

        if (state.from === "1981-2000") {
          setProjectedDataDpdOptions([
            { label: "2010-2029" },
            { label: "2020-2039" },
            { label: "2030-2049" },
            { label: "2040-2059" },
            { label: "2050-2069" },
            { label: "2060-2079" },
            { label: "2070-2089" },
            { label: "2080-2099" },
          ]);
        } else if (state.from === "1981-2010") {
          setProjectedDataDpdOptions([
            { label: "2010-2039" },
            { label: "2020-2049" },
            { label: "2030-2059" },
            { label: "2040-2069" },
            { label: "2050-2079" },
            { label: "2060-2089" },
            { label: "2070-2099" },
          ]);
        }
        break;

      case UKCP18_HIGH_RES:
        selectedResolution !== "5km"
          ? setHistoricalDataDpdOptions([
              { label: "1981-2000" },
              { label: "1981-2010" },
            ])
          : setHistoricalDataDpdOptions([{ label: "1981-2000" }]);

        if (state.from === "1981-2000") {
          selectedResolution !== "5km"
            ? setProjectedDataDpdOptions([
                { label: "2011-2030" },
                { label: "2021-2040" },
                { label: "2031-2050" },
                { label: "2041-2060" },
                { label: "2051-2070" },
                { label: "2061-2080" },
              ])
            : setProjectedDataDpdOptions([
                { label: "2021-2040" },
                { label: "2061-2080" },
              ]);
        } else if (state.from === "1981-2010") {
          setProjectedDataDpdOptions([
            { label: "2011-2040" },
            { label: "2021-2050" },
            { label: "2031-2060" },
            { label: "2041-2070" },
            { label: "2051-2080" },
          ]);
        }
        break;

      case CMIP6:
        setHistoricalDataDpdOptions([
          { label: "1995-2014" },
          { label: "1986-2014" },
        ]);
        if (state.from === "1995-2014") {
          setProjectedDataDpdOptions([
            { label: "2020-2039" },
            { label: "2030-2049" },
            { label: "2040-2059" },
            { label: "2050-2069" },
            { label: "2060-2079" },
            { label: "2070-2089" },
            { label: "2080-2099" },
          ]);
        } else if (state.from) {
          setProjectedDataDpdOptions([
            { label: "2020-2049" },
            { label: "2030-2059" },
            { label: "2040-2069" },
            { label: "2050-2079" },
            { label: "2060-2089" },
            { label: "2070-2099" },
          ]);
        }
        break;

      case HAD_UK:
        selectedResolution !== "5km"
          ? setHistoricalDataDpdOptions([
              { label: "1981-2000" },
              { label: "1981-2010" },
            ])
          : setHistoricalDataDpdOptions([{ label: "1981-2000" }]);
        setProjectedDataDpdOptions([]);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    timeDurationState[0] &&
      setState((prev) => ({
        ...prev,
        from:
          timeDurationState[0] === "20"
            ? historicalDataDpdOptions[0]?.label
            : historicalDataDpdOptions[1]?.label,
      }));
  }, [timeDurationState[0], selectedResolution]);

  useEffect(() => {
    if (selectedDataset) {
      setState({
        ...state,
        dataset: selectedDataset,
      });
    }

    setDpdOptions();

    // if (state.dataset) {
    //   setIsClimateDatasetModalOpen(false);
    //   setIsDatasetSelectionModalOpen(true);
    // }
    //eslint-disable-next-line
  }, [selectedDataset, state.dataset, state.from,selectedResolution]);

  useEffect(() => {
    if (shouldFocusOnHistoryProjectionDpd && !state.from) {
      historicalDpdRef.current?.focus();
    }

    if (shouldFocusOnHistoryProjectionDpd && !state.projection) {
      projectionDpdRef.current?.focus();
    }

    const timeoutId = setTimeout(() => {
      setShouldFocusOnHistoryProjectionDpd(false);
    }, 500);

    //eslint-disable-next-line
    return () => clearTimeout(timeoutId);
    //eslint-disable-next-line
  }, [shouldFocusOnHistoryProjectionDpd]);

  useEffect(() => {
    if (process.env.REACT_APP_ENV === "preproduction") {
      if (state.from && state.projection) {
        setDoesEachFieldOfConfigDatasetCardHasValue(true);
        return;
      }
    } else {
      if (
        (historicalYear.from &&
          historicalYear.to &&
          projectedYear.to &&
          projectedYear.from) ||
        (state.from && state.projection)
      ) {
        setDoesEachFieldOfConfigDatasetCardHasValue(true);
        return;
      }
    }
    setDoesEachFieldOfConfigDatasetCardHasValue(false);
  }, [
    state,
    setDoesEachFieldOfConfigDatasetCardHasValue,
    historicalYear,
    projectedYear,
    doesEachFieldOfConfigDatasetCardHasValue,
  ]);

  let _dataset = selectedDataset;

  if (selectedDataset === UKCP18_PROB) {
    _dataset = "UKCP-PROB";
  } else if (selectedDataset === UKCP18_HIGH_RES) {
    _dataset = "UKCP-HIGH-RES";
  }

  const data = useMemo(
    () => ({
      region: map.region,
      country: map.country,
      lat: map.Longitude,
      lon: map.Latitude,
      climateVariable: selectedClimateVariable,
      climateVariableSource: selectedClimateVariableSource,
      thresholdValueLessThan: lessThanMoreThanValues.thresholdValueLessThan,
      thresholdValueMoreThan: lessThanMoreThanValues.thresholdValueMoreThan,
      dataset: _dataset,
      historical_from_year:
        process.env.REACT_APP_ENV === "preproduction"
          ? state.from.split("-")[0]
          : historicalYear.from,
      historical_to_year:
        process.env.REACT_APP_ENV === "preproduction"
          ? state.from.split("-")[1]
          : historicalYear.to,
      projectional_from_year:
        process.env.REACT_APP_ENV === "preproduction"
          ? state.projection.split("-")[0]
          : projectedYear.from,
      projectional_to_year:
        process.env.REACT_APP_ENV === "preproduction"
          ? state.projection.split("-")[1]
          : projectedYear.to,

      projection: state.from,
      geoJson: geoJson.features,
      projectedData: state.projection,
    }),
    [
      _dataset,
      geoJson.features,
      historicalYear.from,
      historicalYear.to,
      lessThanMoreThanValues.thresholdValueLessThan,
      lessThanMoreThanValues.thresholdValueMoreThan,
      map.Latitude,
      map.Longitude,
      map.country,
      map.region,
      projectedYear.from,
      projectedYear.to,
      selectedClimateVariable,
      selectedClimateVariableSource,
      state.from,
      state.projection,
    ]
  );

  const onRetriveData = () => {
    setRetriveData(data);
    // setIsDatasetSelectionModalOpen(false);
    setIsProjectDescriptionModalOpen(true);
  };

  useEffect(() => setRetriveData(data), [
    data,
    isProjectDescriptionModalOpen,
    setRetriveData,
  ]);

  const clearAllHandler = () => {
    yearDurationState[1]((prev) => ({
      ...prev,
      from: "",
    }));
    setState((prev) => ({
      ...prev,
      from: "",
    }));
    setSelectedProjectedYear("");
    setHistoricalYear({ from: "", to: "" });
    setProjectedYear({ from: "", to: "" });
  };

  return (
    <CSSTransition in={isVisible} timeout={300} classNames="item" unmountOnExit>
      <div className={classes.root}>
        <SearchBox
          overlay={isProjectDescriptionModalOpen}
          title="Configure Dataset"
          editClickHandler={() => {
            setIsProjectDescriptionModalOpen(false);
            // setIsDatasetSelectionModalOpen(true);
            setTimePeriodSelectionModal(true);
          }}
          infoClickHandler={() => {
            setIsClimateDatasetModalOpen(true);
            // setIsDatasetSelectionModalOpen(false);
            setIsProjectDescriptionModalOpen(false);
          }}
        >
          {/* <CustomDropdown
            value={state.dataset}
            onChange={onChangeHandler}
            label="Dataset:"
            placeholder="Select One"
            name="dataset"
            menuItems={datasets}
            isReadOnly={isProjectDescriptionModalOpen}
          /> */}

          {process.env.REACT_APP_ENV !== "preproduction" && (
            <div>
              <RadioGroup
                row
                onChange={(e) => setUiType(e.target.value as RadioType)}
                value={uiType}
              >
                <FormControlLabel
                  value="ui-1"
                  control={<Radio color="primary" />}
                  label="QA Mode"
                />
                <FormControlLabel
                  value="ui-2"
                  control={<Radio color="primary" />}
                  label="User Mode"
                />
              </RadioGroup>
            </div>
          )}

          <div className={classes.dateFieldContainer}>
            <p className={classes.hintText}>Historical Data...</p>
            <br />
            {process.env.REACT_APP_ENV === "preproduction" && (
              <CustomDropdown
                value={state.from}
                onChange={onChangeHandler}
                label="From:"
                placeholder="Select One"
                name="from"
                menuItems={historicalDataDpdOptions}
                // icon="calendar"
                isReadOnly={isProjectDescriptionModalOpen}
                myRef={historicalDpdRef}
              />
            )}

            {process.env.REACT_APP_ENV !== "preproduction" &&
              (uiType === "ui-1" ? (
                <LatLonTextField
                  firstTitle="From"
                  secondTitle="To"
                  name1="from"
                  name2="to"
                  value1={historicalYear.from}
                  value2={historicalYear.to}
                  onChange={(e) =>
                    setHistoricalYear((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }))
                  }
                  // isReadOnly={isProjectDescriptionModalOpen}
                />
              ) : (
                <CustomDropdown
                  value={state.from}
                  onChange={onChangeHandler}
                  label="From:"
                  placeholder="Select One"
                  name="from"
                  menuItems={historicalDataDpdOptions}
                  // icon="calendar"
                  // isReadOnly={isProjectDescriptionModalOpen}
                  myRef={historicalDpdRef}
                  style={{ margin: "0 0 17px 0" }}
                  labelContainerStyle={{ height: "30.5px" }}
                />
              ))}
            {selectedDataset !== "Had-UK (UK Observations)" && (
              <p className={classes.hintText}>Projected Data...</p>
            )}
            <br />
            {process.env.REACT_APP_ENV === "preproduction" && (
              <CustomDropdown
                value={state.projection}
                onChange={onChangeHandler}
                label="Projection:"
                placeholder="Select One"
                name="projection"
                menuItems={projectedDataDpdOptions}
                isReadOnly={isProjectDescriptionModalOpen}
                myRef={projectionDpdRef}
              />
            )}

            {process.env.REACT_APP_ENV !== "preproduction" &&
              (uiType === "ui-1" ? (
                <LatLonTextField
                  firstTitle="From"
                  secondTitle="To"
                  name1="from"
                  name2="to"
                  value1={projectedYear.from || ""}
                  value2={projectedYear.to || ""}
                  onChange={(e) =>
                    setProjectedYear((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }))
                  }
                  // isReadOnly={isProjectDescriptionModalOpen}
                />
              ) : (
                selectedDataset !== "Had-UK (UK Observations)" && (
                  <CustomDropdown
                    value={selectedProjectedYear}
                    onChange={onChangeHandler}
                    label="Projection:"
                    placeholder="Select One"
                    name="projection"
                    menuItems={projectedDataDpdOptions}
                    // isReadOnly={isProjectDescriptionModalOpen}
                    myRef={projectionDpdRef}
                    style={{ margin: "0 0 17px 0" }}
                    labelContainerStyle={{ height: "30px", marginTop: "-1px" }}
                  />
                )
              ))}
          </div>

          <CSSTransition
            in={!isProjectDescriptionModalOpen}
            timeout={300}
            classNames="item"
            unmountOnExit
          >
            <div className={classes.btnContainer}>
              <Button
                disableElevation
                variant="contained"
                color="primary"
                className={classes.btn}
                style={{ marginRight: "16px" }}
                disabled={
                  selectedDataset === "Had-UK (UK Observations)"
                    ? !timeDurationState[0]
                    : !doesEachFieldOfConfigDatasetCardHasValue
                }
                onClick={clearAllHandler}
              >
                Clear All
              </Button>
              <Button
                color="secondary"
                disableElevation
                variant="contained"
                className={classes.btn}
                disabled={
                  (selectedDataset === "Had-UK (UK Observations)" || selectedDataset === "UKCP18 Probabilistic (Average Trends)")
                    ? false
                    : !doesEachFieldOfConfigDatasetCardHasValue
                }
                onClick={() => {
                  // setIsDatasetSelectionModalOpen(false);
                  setIsProjectDescriptionModalOpen(true);
                  setTimePeriodSelectionModal(false);
                  onRetriveData();
                }}
              >
                Continue
              </Button>
            </div>
          </CSSTransition>
        </SearchBox>
      </div>
    </CSSTransition>
  );
};

export default ConfigureDataset;
