export type SourceDataType = {
  title: string;
  unit: string;
  description: string;
};

export const temperatureRelatedSourcesLOCA: SourceDataType[] = [
  {
    title: "Maximum temperature",
    unit: "Txx Units: °C",
    description:
      "The change in annual maximum value of  daily maximum temperature",
  },
  {
    title: "Average daily maximum temperature",
    unit: "Tas max Units: °C",
    description: "The change in daily maximum temperature",
  },
  {
    title: "Average daily minimum temperature",
    unit: "Tas min Units: °C",
    description: "The change in daily minimum temperature",
  },
  {
    title: "Temperature threshold more than",
    unit: "Units: no. days",
    description:
      "Annual count when maximum temperature is above threshold value",
  },
  {
    title: "Temperature threshold less than",
    unit: "Units: no. days",
    description:
      "Annual count when minimum temperature is below threshold value",
  },
];

export const temperatureRelatedSources: SourceDataType[] = [
  {
    title: "Maximum temperature",
    unit: "Txx Units: °C",
    description:
      "The change in annual maximum value of  daily maximum temperature",
  },
  {
    title: "Average daily temperature",
    unit: "Tas Units: °C",
    description: "The change in average daily mean temperature",
  },
  {
    title: "Average daily maximum temperature",
    unit: "Tas max Units: °C",
    description: "The change in daily maximum temperature",
  },
  {
    title: "Average daily minimum temperature",
    unit: "Tas min Units: °C",
    description: "The change in daily minimum temperature",
  },
  {
    title: "Temperature threshold more than",
    unit: "Units: no. days",
    description:
      "Annual count when maximum temperature is above threshold value",
  },
  {
    title: "Temperature threshold less than",
    unit: "Units: no. days",
    description:
      "Annual count when minimum temperature is below threshold value",
  },
];
export const Ukcp18ProbTempratureRelatedResources: SourceDataType[] = [
  {
    title: "Average annual temperature",
    unit: "Tas Units: output °C",
    description: "The change in average annual daily mean temperature",
  },
  {
    title: "Average summer temperature",
    unit: "Tas Units: output °C",
    description:
      "The change in average summer(June-Aug) daily mean temperature",
  },
  {
    title: "Average winter temperature",
    unit: "Tas Units: output °C",
    description: "The change in average winter(Dec-Feb) daily mean temperature",
  },
  {
    title: "Average daily minimum temperature",
    unit: "Tas min Units: output oC",
    description: "The change in average annual daily minimum temperature",
  },
  {
    title: "Average winter daily minimum temperature",
    unit: "Tas min Units: output oC",
    description:
      "The change in average winter (Dec-Feb( daily minimum temperature",
  },
  {
    title: "Average annual daily maximum temperature",
    unit: "Tas max Units: output oC",
    description: "The change in average annual daily maximum temperature",
  },
  {
    title: "Average summer daily maximum temperature",
    unit: "Tas max Units: output oC",
    description:
      "The change in average summer (June-Aug) daily maximum temperature",
  },
];
export const Ukcp18ProbPrecipationRelatedResources: SourceDataType[] = [
  {
    title: "Average annual precipitation",
    unit: "Pr Units: output %",
    description: "The change in average annual daily precipitation",
  },
  {
    title: "Average summer precipitation",
    unit: "Pr Units: output %",
    description: "The change in average summer (June-Aug) daily precipitation",
  },
  {
    title: "Average winter precipitation",
    unit: "Pr Units: output %",
    description: "The change in average winter (Dec-Feb)  daily precipitation",
  },
];
export const HadukTemperatureRelatedResourcesTop: SourceDataType[] = [
  {
    title: "Average annual temperature",
    unit: "Tas Units: output oC",
    description: "The change in average annual daily mean temperature",
  },
  {
    title: "Average summer temperature",
    unit: "Tas Units: output oC",
    description:
      "The change in average summer (June-Aug) daily mean temperature",
  },
  {
    title: "Average winter temperature",
    unit: "Tas Units: output oC",
    description:
      "The change in average winter (Dec-Feb)  daily mean temperature",
  },
  {
    title: "Average annual daily minimum temperature",
    unit: "Tas min Units: output oC",
    description: "The change in average annual daily minimum temperature",
  },
  {
    title: "Average winter daily minimum temperature",
    unit: "Tas min Units: output oC",
    description:
      "The change in average winter (Dec-Feb( daily minimum temperature",
  },
  {
    title: "Average annual daily maximum temperature",
    unit: "Tas max Units: output oC",
    description: "The change in average annual daily maximum temperature",
  },
  {
    title: "Average summer daily maximum temperature",
    unit: "Tas max Units: output oC",
    description:
      "The change in average summer (June-Aug) daily maximum temperature",
  },
];
export const HadukTemperatureRelatedResourcesBottom: SourceDataType[] = [
  {
    title: "Maximum temperature",
    unit: "Txx Units: °C",
    description:
      "The change in annual maximum value of  daily maximum temperature",
  },
  {
    title: "Temperature threshold more than",
    unit: "Units: no. days",
    description:
      "Annual count when maximum temperature is above threshold value",
  },
  {
    title: "Temperature threshold less than",
    unit: "Units: no. days",
    description:
      "Annual count when minimum temperature is below threshold value",
  },
];
export const HadukPrecipationRelatedResourcesTop: SourceDataType[] = [
  {
    title: "Average annual precipitation",
    unit: "Pr Units: output mm/day",
    description: "The change in average annual daily precipitation",
  },
  {
    title: "Average summer precipitation",
    unit: "Pr Units: output mm/day",
    description: "The change in average summer (June-Aug) daily precipitation",
  },
  {
    title: "Average winter precipitation",
    unit: "Pr Units: output mm/day",
    description: "The change in average winter (Dec-Feb)  daily precipitation",
  },
];
export const HadukPrecipationRelatedResourcesBottom: SourceDataType[] = [
  {
    title: "Maximum 1 day rainfall",
    unit: "Rx1 day Units: mm/day",
    description:
      "The change in annual maximum value of  average daily precipitation",
  },
  {
    title: "Maximum 5 day rainfall",
    unit: "Rx5 day Units: mm/day",
    description:
      "The change in annual maximum value of  average daily precipitation over 5 consecutive days",
  },
  {
    title: "Consecutive dry days",
    unit: "CDD Units: mm/day",
    description:
      "Maximum length of dry spell (consecutive days where daily precipitation is less than 1mm)",
  },
  {
    title: "Precipitation threshold more than",
    unit: "Units: no. days",
    description:
      "Annual count when average daily precipitation is above threshold value",
  },
  {
    title: "Precipitation threshold less than",
    unit: "Units: no. days",
    description:
      "Annual count when average daily precipitation is below threshold value",
  },
];

export const Ukcp18HighResTemperatureRelatedResources: SourceDataType[] = [
  {
    title: "Maximum temperature",
    unit: "Txx Units: output: °C",
    description:
      "The change in annual maximum value of daily nmaximum temperature",
  },
  {
    title: "Temperature threshold more than",
    unit: "Units: output: no. days",
    description:
      "Annual count when maximum temperature is above threshold value",
  },
  {
    title: "Temperature threshold less than",
    unit: "Units: output: no. days",
    description:
      "Annual count when maximum temperature is below threshold value",
  },
];
export const Ukcp18HighResPrecipitationRelatedResources: SourceDataType[] = [
  {
    title: "Maximum 1 day rainfall",
    unit: "Rx1 day Units: %",
    description:
      "The change in annual maximum value of average daily precipitation",
  },
  {
    title: "Maximum 5 day rainfall",
    unit: "Rx5 day Units: %",
    description:
      "The change in annual maximum value of average daily precipitation over 5 consecutive days",
  },
  {
    title: "Consecutive dry days",
    unit: "CDD Units: %",
    description:
      "Maximum length of dry spell (consecutive days where daily precipitation is less than 1mm)",
  },
  {
    title: "Precipitation threshold more than",
    unit: "Units: no. days",
    description:
      "Annual count when average daily precipitation is above threshold value",
  },
  {
    title: "Precipitation threshold less than",
    unit: "Units: no. days",
    description:
      "Annual count when average daily precipitation is below threshold value",
  },
];

export const windRelatedSources: SourceDataType[] = [
  {
    title: "Daily max gust",
    unit: "Units: m/s",
    description: "The change in daily maximum wind gust",
  },
  {
    title: "Gust threshold more than.",
    unit: "Units: m/s",
    description:
      "Annual count when daily maximum wind gust is above threshold value",
  },
  {
    title: "Daily average wind speed",
    unit: "Units: m/s",
    description: "The change in daily average surface wind speed",
  },
  {
    title: "Wind threshold more than",
    unit: "Units: m/s",
    description:
      "Annual count when daily average surface wind is above threshold value",
  },
];

export const humdityRelatedSources: SourceDataType[] = [
  {
    title: "Specfic humidity",
    unit: "Units: %",
    description: "The change in annual specifc humidity",
  },
  {
    title: "Relative humidity",
    unit: "Units: %",
    description: "The change in annual relative humidity",
  },
];

export const snowRelatedSources: SourceDataType[] = [
  {
    title: "Snow depth",
    unit: "Units: m",
    description: "The change in thickness of lying snow",
  },
  {
    title: "Snow threshold more than",
    unit: "Units: m",
    description: "Annual count when snow depth is above threshold value",
  },
];

export const marineRelatedDataSources: SourceDataType[] = [
  {
    title: "Sea level Rise",
    unit: "m Unit output: m",
    description: "Time mean sea level rise",
  },
];

export const precipitationRelatedDataSources: SourceDataType[] = [
  {
    title: "Maximum 1 day rainfall",
    unit: "Rx1day Units: %",
    description:
      "The change in annual maximum value of average daily precipitation",
  },
  {
    title: "Maximum 5 day rainfall",
    unit: "Rx5day Units: %",
    description:
      "The change in annual maximum value of average daily precipitation over 5 consecutive days",
  },
  {
    title: "Consecutive dry days",
    unit: "CDD Units: %",
    description:
      "Maximum length of dry spell (consecutive days where daily precipitation is less than 1mm)",
  },
  {
    title: "Average daily precipitation",
    unit: "Pr Units: %",
    description: "The change in average daily precipitation ",
  },
  {
    title: "Precipitation threshold more than",
    unit: "Units: no. days",
    description:
      "Annual count when average daily precipitation is above threshold value",
  },
  {
    title: "Precipitation threshold less than",
    unit: "Units: no. days",
    description:
      "Annual count when average daily precipitation is below threshold value",
  },
];

// export const allMatrixData = {
//   "Maximum temperature": "Maximum temperature",
//   "Average annual temperature": "Average annual temperature",
//   "Average annual daily maximum temperature": "Average annual daily maximum temperature",
//   "Average annual daily minimum temperature": "Average annual daily minimum temperature",
//   "Temperature threshold more than": "Temperature threshold more than",
//   "Temperature threshold less than": "Temperature threshold less than",
//   "Average summer temperature": "Average summer temperature",
//   "Average winter temperature": "Average winter temperature",
//   "Average summer daily maximum temperature":"Average summer daily maximum temperature",
//   "Average winter daily minimum temperature": "Average winter daily minimum temperature",
//   "Average annual precipitation": "Average annual precipitation",
//   "Precipitation threshold more than": "Precipitation threshold more than",
//   "Precipitation threshold less than": "Precipitation threshold less than",
//   "Maximum 1 day rainfall": "Maximum 1 day rainfall",
//   "Maximum 5 day rainfall": "Maximum 5 day rainfall",
//   "Consecutive dry days": "Consecutive dry days",
//   "Average summer precipitation": "Average summer precipitation",
//   "Average winter precipitation": "Average winter precipitation",
//   "Average daily temperature":"Average daily temperature",
//   "Average daily maximum temperature":"Average daily maximum temperature",
//   "Average daily minimum temperature":"Average daily minimum temperature",
//   "Average daily precipitation":"Average daily precipitation",
//   "Cumulative dry days":"Cumulative dry days"
// };
export const allMatrixData = {
  "Maximum temperature" : "Maximum temperature",
  "Average daily temperature":"Average annual temperature",
 "Average daily maximum temperature":"Average annual daily maximum temperature",
 "Average daily minimum temperature":"Average annual daily minimum temperature",
"Temperature threshold more than" : "Temperature threshold more than",
"Temperature threshold less than" : "Temperature threshold less than",
"Average summer temperature" : "Average summer temperature",
"Average winter temperature" : "Average winter temperature",
"Average summer maximum temperature":"Average summer daily maximum temperature",
"Average winter minimum temperature":"Average winter daily minimum temperature",
"Average daily precipitation":"Average annual precipitation",
"Precipitation threshold more than":"Precipitation threshold more than",
"Precipitation threshold less than":"Precipitation threshold less than",
"Maximum 1 day rainfall":"Maximum 1 day rainfall",
"Maximum 5 day rainfall":"Maximum 5 day rainfall",
"Consecutive dry days":"Consecutive dry days",
"Average summer precipitation":"Average summer precipitation",
"Average winter precipitation":"Average winter precipitation",
"Average annual daily maximum temperature": "Average annual daily maximum temperature",
"Average annual daily minimum temperature": "Average annual daily minimum temperature",
"Average summer daily maximum temperature":"Average summer daily maximum temperature",
"Average winter daily minimum temperature": "Average winter daily minimum temperature",
"Average annual temperature": "Average annual temperature",
"Average annual precipitation": "Average annual precipitation",
}
