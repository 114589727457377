import React from "react";

import { makeStyles } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";

export type ConfigureDatasetPropTypes = { isVisible: boolean };

export const useConfigureDatasetStyles = makeStyles({
  root: {},

  dateFieldContainer: {
    "& .MuiFormControl-fullWidth": {
      height:
        process.env.REACT_APP_ENV === "preproduction"
          ? undefined
          : "100% !important",
    },
  },
  btnContainer: {
    display: "flex",
    width: "100%",
  },

  btn: {
    width: "152px",
    fontSize: "0.75rem",
    lineHeight: "14px",
    fontWeight: "normal",
    textTransform: "capitalize",
  },

  hintText: {
    fontSize: "10px",
    lineHeight: "12px",
    color: "#1C1C1C",
    marginBottom: "-14px",
  },
});

export type SimpleDialogProps = {
  open: boolean;
  onClose: (value: string) => void;
  geoJsonSetter?: () => any;
};

export type CsvPropsType = {
  geoJsonSetter?: () => any;
};

export const useCsvPopupStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  dialogPaper: {
    minWidth: "791px",
    minHeight: "404px",
    padding: "30px",
    overflowX: "hidden",
  },
  btn: {
    width: "152px",
    fontSize: "0.75rem",
    lineHeight: "14px",
    fontWeight: "normal",
    textTransform: "capitalize",
  },
  btnContainer: {
    display: "flex",
    width: "100%",
    marginTop: "30px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  links: {
    fontSize: "10px",
    lineHeight: "12px",
    color: "#1C1C1C",
    marginBottom: "17px",
    display: "inline-block",
  },
  dropZone: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .MuiDropzoneArea-textContainer": {
      display: "flex",
      flexDirection: "column-reverse",
      alignItems: "center",
    },

    "& .MuiDropzoneArea-text": {
      fontSize: "0.8375rem",
      lineHeight: "16px",
      color: "#1C1C1C",
    },
  },

  dialogTitleContainer: {
    textAlign: "center",
    padding: "0 0 16px 0",
    "& h2": {
      fontWeight: "normal",
      fontSize: "18px",
      lineHeight: "21px",
      color: "#1C1C1C",
    },
  },
});

export const useLocationSelectPageBackupStyles = makeStyles({
  mainContainer: {
    width: "100%",
    minHeight: "calc(100vh - 32px)",
    paddingTop: "16px",
    overflowX: "hidden",
    background: "rgba(0, 0, 0, .5)",
  },

  modalsContainer: {
    zIndex: 3,
    width: "100%",
    height: "fit-content",
    display: "flex",
    flexDirection: "column",
  },

  searchBoxContainer: {
    display: "flex",
  },

  mapboxContainer: {
    width: "100%",
    height: "calc(100vh - 32px)",
    position: "absolute",
    top: "32px",
  },
});

export type SelectClimateVariablePropTypes = {
  isVisible: boolean;
};

export const useSelectClimateVariableStyles = makeStyles({
  chipContainer: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginTop: "1.375rem",
  },
  chip: {
    width: "101.82px",
    height: "24px",
    border: "1px solid #BEBEBE",
    borderRadius: "19.4889px",
    fontSize: "12px",
    lineHeight: "14px",
    color: "#9B9B9B",
    marginBottom: "8px",
  },
  btnContainer: {
    display: "flex",
    width: "100%",
  },

  btn: {
    width: "152px",
    fontSize: "0.75rem",
    lineHeight: "14px",
    fontWeight: "normal",
    textTransform: "capitalize",
  },
});

export const useSelectLocationStyles = makeStyles({
  btnContainer: {
    display: "flex",
    width: "100%",
  },

  btn: {
    width: "152px",
    fontSize: "0.75rem",
    lineHeight: "14px",
    fontWeight: "normal",
    textTransform: "capitalize",
  },
  links: {
    fontSize: "9.5px",
    lineHeight: "12px",
    color: "#1C1C1C",
    marginBottom: "17px",
  },
});

export type LatLonTextFieldPropsType = {
  value1: string | number;
  value2: string | number;
  id?: string;
  // name?: string;
  icon?: React.ReactNode;
  title?: string;
  onIconClick?: () => any;
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  isReadOnly?: boolean;
  firstTitle: string;
  secondTitle: string;
  name1: string;
  name2: string;
};

export const useLatLonTextFieldStyle = makeStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "32px",
    marginBottom: "17px",
  },
  labelContainer: {
    width: (props: LatLonTextFieldPropsType) => (props.icon ? "56px" : "25%"),
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1.21806px solid #BEBEBE",

    borderRight: "none",
  },
  labels: {
    fontSize: "12px",
    lineHeight: "14px",

    color: "#9B9B9B",
  },
  inputField: {
    width: "88px",
    height: "100%",
    borderRadius: "0px",
    fontSize: "0.75rem",
  },
});

export type MoreThanLessThanTextFieldPropsType = {
  value: string | null;
  unit: string;
  sign: string;
  onChange:
    | React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
    | undefined;
  isReadOnly?: boolean;
};

export const useMoreThanLessThanTextFieldStyle = makeStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "17px",
  },
  labelContainer: {
    width: "56px",
    height: "32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1.21806px solid #BEBEBE",
    borderColor: (props: MoreThanLessThanTextFieldPropsType) =>
      props.value && props.value ? "#000" : "",
    borderRight: "none",
    background: (props: MoreThanLessThanTextFieldPropsType) =>
      props.value && props.value ? "#ADE5F7" : "",
  },
  labels: {
    fontSize: "12px",
    lineHeight: "14px",

    color: (props: MoreThanLessThanTextFieldPropsType) =>
      props.value && props.value ? "black" : "#9B9B9B",
  },
  inputField: {
    height: "32px",
    borderRadius: "0px",
    fontSize: "0.75rem",
  },
});

export const useDatasetSelectCardStyle = makeStyles({
  btnContainer: {
    display: "flex",
    width: "100%",
  },

  btn: {
    width: "152px",
    fontSize: "0.75rem",
    lineHeight: "14px",
    fontWeight: "normal",
    textTransform: "capitalize",
  },
});
