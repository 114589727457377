export const REGION_DATA_SET = [
  {
    region: "South East Asia",
    shortName: "SEA",
    Longitude: 115.66283,
    Latitude: -2.21797,
    CountryList: [
      {
        name: "Brunei",
        shortName: "BN",
        Longitude: "114.727669",
        Latitude: "4.535277",
      },
      {
        name: "Cambodia",
        shortName: "KH",
        Longitude: "104.990963",
        Latitude: "12.565679",
      },
      {
        name: "Indonesia",
        shortName: "ID",
        Longitude: "113.921327",
        Latitude: "-0.789275",
      },
      {
        name: "Malaysia",
        shortName: "MY",
        Longitude: "101.975766",
        Latitude: "4.210484",
      },
      {
        name: "Philippines",
        shortName: "PH",
        Longitude: "121.774017",
        Latitude: "12.879721",
      },
      {
        name: "Singapore",
        shortName: "SG",
        Longitude: "103.819836",
        Latitude: "1.352083",
      },
      {
        name: "Vietnam",
        shortName: "VN",
        Longitude: "108.277199",
        Latitude: "14.058324",
      },
    ],
  },

  {
    region: "East Asia",
    Longitude: "104.195397",
    Latitude: "35.86166",
    shortCode: "EAS",
    CountryList: [
      {
        name: "Bangladesh",
        shortName: "BGD",
        Longitude: "90.356331",
        Latitude: "23.684994",
      },
      {
        name: "Bhutan",
        shortName: "BTN",
        Longitude: 90.48505226910844,
        Latitude: 27.412767131832183,
      },
      {
        name: "China",
        shortName: "CHN",
        Longitude: "104.195397",
        Latitude: "35.86166",
      },
      {
        name: "Hong Kong",
        shortName: "HKG",
        Longitude: 114.18473678040567,
        Latitude: 22.321345676890264,
      },
      {
        name: "India",
        shortName: "IND",
        Longitude: "78.96288",
        Latitude: "20.593684",
      },
      {
        name: "Japan",
        shortName: "JPN",
        Longitude: "138.252924",
        Latitude: "36.204824",
      },
      {
        name: "Laos",
        shortName: "LAO",
        Longitude: "102.495496",
        Latitude: "19.85627",
      },
      {
        name: "Mongolia",
        shortName: "MNG",
        Longitude: 103.846656,
        Latitude: 46.862496,
      },
      {
        name: "Myanmar",
        shortName: "MMR",
        Longitude: "95.956223",
        Latitude: "21.913965",
      },
      {
        name: "Nepal",
        shortName: "NPL",
        Longitude: "84.124008",
        Latitude: "28.394857",
      },
      {
        name: "North Korea",
        shortName: "PRK",
        Longitude: "138.252924",
        Latitude: "40.339852",
      },
      {
        name: "South Korea",
        shortName: "KOR",
        Longitude: "127.766922",
        Latitude: "35.907757",
      },
      {
        name: "Taiwan",
        shortName: "TWN",
        Longitude: 121.23494163958686,
        Latitude: 23.41928980939343,
      },
      {
        name: "Thailand",
        shortName: "THA",
        Longitude: "100.992541",
        Latitude: "15.870032",
      },
    ],
  },

  {
    region: "South Asia",
    Longitude: "78.96288",
    Latitude: "20.593684",
    shortCode: "WAS",
    CountryList: [
      {
        name: "Afghanistan",
        shortName: "AFG",
        Longitude: "67.709953",
        Latitude: "33.93911",
      },
      {
        name: "British Indian Ocean Territory",
        shortName: "SAIOTM",
        Longitude: 72.39157058149355,
        Latitude: -7.273446587547727,
      },
      {
        name: "India",
        shortName: "IND",
        Longitude: "78.96288",
        Latitude: "20.593684",
      },
      {
        name: "Maldives",
        shortName: "MDV",
        Longitude: 73.40009062438195,
        Latitude: -0.6093200810844336,
      },
      {
        name: "Oman",
        shortName: "OMN",
        Longitude: "55.923255",
        Latitude: "21.512583",
      },
      {
        name: "Pakistan",
        shortName: "PAK",
        Longitude: "69.345116",
        Latitude: "30.375321",
      },
      {
        name: "Seychelles",
        shortName: "SYC",
        Longitude: 55.42812167131711,
        Latitude: -4.554980982540241,
      },
      {
        name: "Somalia",
        shortName: "SOM",
        Longitude: "46.199616",
        Latitude: "5.152149",
      },
      {
        name: "Sri Lanka",
        shortName: "LKA",
        Longitude: "80.771797",
        Latitude: "7.873054",
      },
      {
        name: "Yemen",
        shortName: "YEM",
        Longitude: "48.516388",
        Latitude: "15.552727",
      },
    ],
  },

  {
    region: "South America",
    Longitude: "-58.443832",
    Latitude: "-23.442503",
    shortCode: "SAM",
    CountryList: [
      {
        name: "Argentina",
        shortName: "ARG",
        Longitude: "-63.616672",
        Latitude: "-38.416097",
      },
      {
        name: "Bolivia",
        shortName: "BOL",
        Longitude: "-63.588653",
        Latitude: "-16.290154",
      },
      {
        name: "Brazil",
        shortName: "BRA",
        Longitude: "-51.92528",
        Latitude: "-14.235004",
      },
      {
        name: "Chile",
        shortName: "CHL",
        Longitude: "-71.542969",
        Latitude: "-35.675147",
      },
      {
        name: "Colombia",
        shortName: "COL",
        Longitude: "-74.297333",
        Latitude: "4.570868",
      },
      {
        name: "Ecuador",
        shortName: "ECU",
        Longitude: "-78.183406",
        Latitude: "-1.831239",
      },
      {
        name: "French Guiana",
        shortName: "GUF",
        Longitude: -52.80272645976157,
        Latitude: 4.708234704141933,
      },
      {
        name: "Guyana",
        shortName: "GUY",
        Longitude: "-58.93018",
        Latitude: "4.860416",
      },
      {
        name: "Paraguay",
        shortName: "PRY",
        Longitude: "-58.443832",
        Latitude: "-23.442503",
      },
      {
        name: "Peru",
        shortName: "PER",
        Longitude: "-75.015152",
        Latitude: "-9.189967",
      },
      {
        name: "Suriname",
        shortName: "SUR",
        Longitude: "-56.027783",
        Latitude: "3.919305",
      },
      {
        name: "Uruguay",
        shortName: "URY",
        Longitude: "-55.765835",
        Latitude: "-32.522779",
      },
      {
        name: "Venezuela",
        shortName: "VEN",
        Longitude: "-66.58973",
        Latitude: "6.42375",
      },
    ],
  },

  {
    region: "Central America",
    Longitude: "-85.207229",
    Latitude: "12.865416",
    shortCode: "CAM",
    CountryList: [
      {
        name: "Anguilla",
        shortName: "AIA",
        Longitude: -63.03951805341837,
        Latitude: 18.221473794916523,
      },
      {
        name: "Antigua and Barbuda",
        shortName: "ATG",
        Longitude: -61.33455173805225,
        Latitude: 17.162864394472813,
      },
      {
        name: "Aruba",
        shortName: "ABW",
        Longitude: -69.96376917071592,
        Latitude: 12.521838870632953,
      },
      {
        name: "Barbados",
        shortName: "BRB",
        Longitude: -59.59028178872788,
        Latitude: 13.20934729319589,
      },
      {
        name: "Belize",
        shortName: "BLZ",
        Longitude: -88.68697939046524,
        Latitude: 16.82132820682284,
      },
      {
        name: "Bonaire",
        shortName: "BES",
        Longitude: -68.25678608164515,
        Latitude: 12.200555682162275,
      },
      {
        name: "Cayman Islands",
        shortName: "CYM",
        Longitude: -81.23909697075565,
        Latitude: 19.315192959340102,
      },
      {
        name: "Colombia",
        shortName: "COL",
        Longitude: "-74.297333",
        Latitude: "4.570868",
      },
      {
        name: "Costa Rica",
        shortName: "CRI",
        Longitude: -84.20776979201253,
        Latitude: 10.346096208652797,
      },
      {
        name: "Cuba",
        shortName: "CUB",
        Longitude: "-77.781167",
        Latitude: "21.521757",
      },
      {
        name: "Curacao",
        shortName: "CUW",
        Longitude: -68.99896256396742,
        Latitude: 12.167888234054576,
      },
      {
        name: "Dominica",
        shortName: "DMA",
        Longitude: -61.36876641827665,
        Latitude: 15.442202642008244,
      },
      {
        name: "Dominican Republic",
        shortName: "DOM",
        Longitude: -70.09232884466026,
        Latitude: 18.973340858846292,
      },
      {
        name: "Ecuador",
        shortName: "ECU",
        Longitude: "-78.183406",
        Latitude: "-1.831239",
      },
      {
        name: "El Salvador",
        shortName: "SVK",
        Longitude: -88.61132845045438,
        Latitude: 13.7975234083092,
      },
      {
        name: "French Guiana",
        shortName: "GUF",
        Longitude: -52.80272645976157,
        Latitude: 4.708234704141933,
      },
      {
        name: "Grenada",
        shortName: "GRD",
        Longitude: -61.631543216976794,
        Latitude: 12.134768004438904,
      },
      {
        name: "Guadeloupe",
        shortName: "GLP",
        Longitude: "-62.067641",
        Latitude: "16.995971",
      },
      {
        name: "Guatemala",
        shortName: "GTM",
        Longitude: "-90.230759",
        Latitude: "15.783471",
      },
      {
        name: "Guyana",
        shortName: "GUY",
        Longitude: "-58.93018",
        Latitude: "4.860416",
      },
      {
        name: "Haiti",
        shortName: "HTI",
        Longitude: "-72.285215",
        Latitude: "18.971187",
      },
      {
        name: "Honduras",
        shortName: "HND",
        Longitude: "-86.241905",
        Latitude: "15.199999",
      },
      {
        name: "Jamaica",
        shortName: "JAM",
        Longitude: "-77.297508",
        Latitude: "18.109581",
      },
      {
        name: "Martinique",
        shortName: "MTQ",
        Longitude: "-61.024174",
        Latitude: "14.641528",
      },
      {
        name: "Mexico",
        shortName: "MEX",
        Longitude: "-102.552784",
        Latitude: "23.634501",
      },
      {
        name: "Montserrat",
        shortName: "MSR",
        Longitude: "-62.187366",
        Latitude: "16.742498",
      },
      {
        name: "Netherlands Antilles",
        shortName: "ANT",
        Longitude: "-69.060087",
        Latitude: "12.226079",
      },
      {
        name: "Nicaragua",
        shortName: "NIC",
        Longitude: "-85.207229",
        Latitude: "12.865416",
      },
      {
        name: "Panama",
        shortName: "PAN",
        Longitude: "-80.782127",
        Latitude: "8.537981",
      },
      {
        name: "Puerto Rico",
        shortName: "PRI",
        Longitude: "-66.590149",
        Latitude: "18.220833",
      },
      {
        name: "Saint Barthelemy",
        shortName: "BLM",
        Longitude: "-62.83251",
        Latitude: "17.896846",
      },
      {
        name: "Saint Kitts and Nevis",
        shortName: "KNA",
        Longitude: "-62.782998",
        Latitude: "17.357822",
      },
      {
        name: "Saint Lucia",
        shortName: "LCA",
        Longitude: "-60.978893",
        Latitude: "13.909444",
      },
      {
        name: "Saint Martin",
        shortName: "MAF",
        Longitude: "-63.049104",
        Latitude: "18.084103",
      },
      {
        name: "Saint Vincent and the Grenadines",
        shortName: "VCT",
        Longitude: "-61.287228",
        Latitude: "12.984305",
      },
      {
        name: "Sint Maarten",
        shortName: "SXM",
        Longitude: "-63.0551125",
        Latitude: "18.04444",
      },
      {
        name: "Suriname",
        shortName: "SUR",
        Longitude: "-56.027783",
        Latitude: "3.919305",
      },
      {
        name: "Trinidad and Tobago",
        shortName: "TTO",
        Longitude: "-61.222503",
        Latitude: "10.691803",
      },
      {
        name: "Turks and Caicos Islands",
        shortName: "TCA",
        Longitude: "-71.797928",
        Latitude: "21.694025",
      },
      {
        name: "Venezuela",
        shortName: "VEN",
        Longitude: "-66.58973",
        Latitude: "6.42375",
      },
      {
        name: "Virgin Islands, British",
        shortName: "VGB",
        Longitude: "-64.639968",
        Latitude: "18.420695",
      },
      {
        name: "Virgin Islands, U.S.",
        shortName: "VIR",
        Longitude: "-64.896335",
        Latitude: "18.335765",
      },
    ],
  },

  {
    region: "North America",
    Longitude: "105.2551",
    Latitude: "54.5260",
    shortCode: "NAM",
    CountryList: [
      {
        name: "Canada",
        shortName: "CAN",
        Longitude: "-106.346771",
        Latitude: "56.130366",
      },
      {
        name: "United States",
        shortName: "USA",
        Longitude: "-95.712891",
        Latitude: "37.09024",
      },
    ],
  },

  {
    region: "Europe",
    Longitude: "15.2551",
    Latitude: "54.5260",
    shortCode: "EUR",
    CountryList: [
      {
        name: "Aland Islands",
        shortName: "ALA",
        Longitude: "20.117369",
        Latitude: "60.29374",
      },
      {
        name: "Albania",
        shortName: "ALB",
        Longitude: 19.900721265999625,
        Latitude: 41.05666870544252,
      },
      {
        name: "Andorra",
        shortName: "AND",
        Longitude: 1.642748380150478,
        Latitude: 42.55287704231153,
      },
      {
        name: "Austria",
        shortName: "AUT",
        Longitude: "14.550072",
        Latitude: "47.516231",
      },
      {
        name: "Belarus",
        shortName: "BLR",
        Longitude: 27.88726179096965,
        Latitude: 53.27984041590707,
      },
      {
        name: "Belgium",
        shortName: "BEL",
        Longitude: 4.389760912504731,
        Latitude: 50.51700326383112,
      },
      {
        name: "Bosnia and Herzegovina",
        shortName: "BIH",
        Longitude: "17.679076",
        Latitude: "43.915886",
      },
      {
        name: "Bulgaria",
        shortName: "BGR",
        Longitude: "25.48583",
        Latitude: "42.733883",
      },
      {
        name: "Croatia",
        shortName: "HRV",
        Longitude: "15.2",
        Latitude: "45.1",
      },
      {
        name: "Czech Republic",
        shortName: "CZE",
        Longitude: "15.472962",
        Latitude: "49.817492",
      },
      {
        name: "Denmark",
        shortName: "DNK",
        Longitude: "9.501785",
        Latitude: "56.26392",
      },
      {
        name: "Estonia",
        shortName: "EST",
        Longitude: "25.013607",
        Latitude: "58.595272",
      },
      {
        name: "Faroe Islands",
        shortName: "FRO",
        Longitude: -6.916134348570437,
        Latitude: 62.14229048858599,
      },
      {
        name: "France",
        shortName: "FRA",
        Longitude: "2.213749",
        Latitude: "46.227638",
      },
      {
        name: "Germany",
        shortName: "DEU",
        Longitude: "10.451526",
        Latitude: "51.165691",
      },
      {
        name: "Gibraltar",
        shortName: "GIB",
        Longitude: -5.353438054910504,
        Latitude: 36.14230884606669,
      },
      {
        name: "Greece",
        shortName: "GRC",
        Longitude: "21.824312",
        Latitude: "39.074208",
      },
      {
        name: "Guernsey",
        shortName: "GGY",
        Longitude: "-2.585278",
        Latitude: "49.465691",
      },
      {
        name: "Hungary",
        shortName: "HUN",
        Longitude: "19.503304",
        Latitude: "47.162494",
      },
      {
        name: "Ireland",
        shortName: "IRL",
        Longitude: "-8.24389",
        Latitude: "53.41291",
      },
      {
        name: "Isle of Man",
        shortName: "IMN",
        Longitude: "-4.548056",
        Latitude: "54.236107",
      },
      {
        name: "Italy",
        shortName: "ITA",
        Longitude: "12.56738",
        Latitude: "41.87194",
      },
      {
        name: "Jersey",
        shortName: "JEY",
        Longitude: "-2.13125",
        Latitude: "49.214439",
      },
      {
        name: "Latvia",
        shortName: "LVA",
        Longitude: "24.603189",
        Latitude: "56.879635",
      },
      {
        name: "Liechtenstein",
        shortName: "LIE",
        Longitude: "9.555373",
        Latitude: "47.166",
      },
      {
        name: "Lithuania",
        shortName: "LTU",
        Longitude: "23.881275",
        Latitude: "55.169438",
      },
      {
        name: "Luxembourg",
        shortName: "LUX",
        Longitude: "6.129583",
        Latitude: "49.815273",
      },
      {
        name: "Macedonia",
        shortName: "MKD",
        Longitude: "21.745275",
        Latitude: "41.608635",
      },
      {
        name: "Moldova",
        shortName: "MDA",
        Longitude: "28.369885",
        Latitude: "47.411631",
      },
      {
        name: "Monaco",
        shortName: "MCO",
        Longitude: "7.412841",
        Latitude: "43.750298",
      },
      {
        name: "Montenegro",
        shortName: "MNE",
        Longitude: "19.37439",
        Latitude: "42.708678",
      },
      {
        name: "Netherlands",
        shortName: "NLD",
        Longitude: "5.291266",
        Latitude: "52.132633",
      },
      {
        name: "Norway",
        shortName: "NOR",
        Longitude: "8.468946",
        Latitude: "60.472024",
      },
      {
        name: "Poland",
        shortName: "POL",
        Longitude: "19.145136",
        Latitude: "51.919438",
      },
      {
        name: "Portugal",
        shortName: "PRT",
        Longitude: "-8.224454",
        Latitude: "39.399872",
      },
      {
        name: "Romania",
        shortName: "ROU",
        Longitude: "24.96676",
        Latitude: "45.943161",
      },
      {
        name: "San Marino",
        shortName: "SMR",
        Longitude: "12.457777",
        Latitude: "43.94236",
      },
      {
        name: "Serbia",
        shortName: "SRB",
        Longitude: "21.005859",
        Latitude: "44.016521",
      },
      {
        name: "Slovakia",
        shortName: "SVK",
        Longitude: "19.699024",
        Latitude: "48.669026",
      },
      {
        name: "Slovenia",
        shortName: "SVN",
        Longitude: "14.995463",
        Latitude: "46.151241",
      },
      {
        name: "Spain",
        shortName: "ESP",
        Longitude: "-3.74922",
        Latitude: "40.463667",
      },
      {
        name: "Sweden",
        shortName: "SWE",
        Longitude: "18.643501",
        Latitude: "60.128161",
      },
      {
        name: "Switzerland",
        shortName: "CHE",
        Longitude: "8.227512",
        Latitude: "46.818188",
      },
      {
        name: "Ukraine",
        shortName: "UKR",
        Longitude: "31.16558",
        Latitude: "48.379433",
      },
      {
        name: "United Kingdom",
        shortName: "GBR",
        Longitude: "-3.435973",
        Latitude: "55.378051",
      },
      {
        name: "Vatican City",
        shortName: "VAT",
        Longitude: "12.453389",
        Latitude: "41.902916",
      },
    ],
  },

  {
    region: "Africa",
    Longitude: "34.5085",
    Latitude: "8.7832",
    shortCode: "AFR",
    CountryList: [
      {
        name: "Algeria",
        shortName: "DZA",
        Longitude: 1.659626,
        Latitude: 28.033886,
      },
      {
        name: "Angola",
        shortName: "AGO",
        Longitude: 17.873887,
        Latitude: -11.202692,
      },
      {
        name: "Benin",
        shortName: "BEN",
        Longitude: 2.315834,
        Latitude: 9.30769,
      },
      {
        name: "Botswana",
        shortName: "BWA",
        Longitude: 24.684866,
        Latitude: -22.328474,
      },
      {
        name: "Burkina Faso",
        shortName: "BFA",
        Longitude: -1.561593,
        Latitude: 12.238333,
      },
      {
        name: "Burundi",
        shortName: "BDI",
        Longitude: 29.918886,
        Latitude: -3.373056,
      },
      {
        name: "Cameroon",
        shortName: "CMR",
        Longitude: 12.354722,
        Latitude: 7.369722,
      },
      {
        name: "Central African Republic",
        shortName: "CAF",
        Longitude: 20.939444,
        Latitude: 6.611111,
      },
      {
        name: "Chad",
        shortName: "TCD",
        Longitude: 18.732207,
        Latitude: 15.454166,
      },
      {
        name: "Congo",
        shortName: "COG",
        Longitude: 15.827659,
        Latitude: -0.228021,
      },
      {
        name: "Congo, The Democratic Republic of the",
        shortName: "COD",
        Longitude: 21.758664,
        Latitude: -4.038333,
      },
      {
        name: "Côte D'Ivoire",
        shortName: "CIV",
        Longitude: -5.54708,
        Latitude: 7.539989,
      },
      {
        name: "Djibouti",
        shortName: "DJI",
        Longitude: 42.62993715300226,
        Latitude: 11.750935262899002,
      },
      {
        name: "Egypt",
        shortName: "EGY",
        Longitude: 30.802498,
        Latitude: 26.820553,
      },
      {
        name: "Equatorial Guinea",
        shortName: "GNQ",
        Longitude: 10.587486873443106,
        Latitude: 1.5604857907395193,
      },
      {
        name: "Eritrea",
        shortName: "ERI",
        Longitude: 39.782334,
        Latitude: 15.179384,
      },
      {
        name: "Ethiopia",
        shortName: "ETH",
        Longitude: 40.489673,
        Latitude: 9.145,
      },
      {
        name: "Gabon",
        shortName: "GAB",
        Longitude: 11.609444,
        Latitude: -0.803689,
      },
      {
        name: "Gambia",
        shortName: "GMB",
        Longitude: -15.463031479817886,
        Latitude: 13.473771130017388,
      },
      {
        name: "Ghana",
        shortName: "GHA",
        Longitude: -1.023194,
        Latitude: 7.946527,
      },
      {
        name: "Guinea",
        shortName: "GIN",
        Longitude: -9.696645,
        Latitude: 9.945587,
      },
      {
        name: "Guinea-Bissau",
        shortName: "GNB",
        Longitude: -15.180413,
        Latitude: 11.803749,
      },
      {
        name: "Iraq",
        shortName: "IRQ",
        Longitude: 43.679291,
        Latitude: 33.223191,
      },
      {
        name: "Israel",
        shortName: "ISR",
        Longitude: 34.851612,
        Latitude: 31.046051,
      },
      {
        name: "Jordan",
        shortName: "JOR",
        Longitude: 36.238414,
        Latitude: 30.585164,
      },
      {
        name: "Kenya",
        shortName: "KEN",
        Longitude: 37.906193,
        Latitude: -0.023559,
      },
      {
        name: "Kuwait",
        shortName: "KWT",
        Longitude: 47.481766,
        Latitude: 29.31166,
      },
      {
        name: "Lesotho",
        shortName: "LSO",
        Longitude: 28.233608,
        Latitude: -29.609988,
      },
      {
        name: "Liberia",
        shortName: "LBR",
        Longitude: -9.429499,
        Latitude: 6.428055,
      },
      {
        name: "Libya",
        shortName: "LBY",
        Longitude: 17.228331,
        Latitude: 26.3351,
      },
      {
        name: "Madagascar",
        shortName: "MDG",
        Longitude: 46.869107,
        Latitude: -18.766947,
      },
      {
        name: "Malawi",
        shortName: "MWI",
        Longitude: 34.301525,
        Latitude: -13.254308,
      },
      {
        name: "Mali",
        shortName: "MLI",
        Longitude: -3.996166,
        Latitude: 17.570692,
      },
      {
        name: "Mauritania",
        shortName: "MRT",
        Longitude: -10.940835,
        Latitude: 21.00789,
      },
      {
        name: "Mauritius",
        shortName: "MUS",
        Longitude: 57.552152,
        Latitude: -20.348404,
      },
      {
        name: "Morocco",
        shortName: "MAR",
        Longitude: -7.09262,
        Latitude: 31.791702,
      },
      {
        name: "Mozambique",
        shortName: "MOZ",
        Longitude: 35.529562,
        Latitude: -18.665695,
      },
      {
        name: "Namibia",
        shortName: "NAM",
        Longitude: 18.49041,
        Latitude: -22.95764,
      },
      {
        name: "Niger",
        shortName: "NER",
        Longitude: 8.081666,
        Latitude: 17.607789,
      },
      {
        name: "Nigeria",
        shortName: "NGA",
        Longitude: 8.675277,
        Latitude: 9.081999,
      },
      {
        name: "Oman",
        shortName: "OMN",
        Longitude: "55.923255",
        Latitude: "21.512583",
      },
      {
        name: "Qatar",
        shortName: "QAT",
        Longitude: 51.183884,
        Latitude: 25.354826,
      },
      {
        name: "Rwanda",
        shortName: "RWA",
        Longitude: 29.873888,
        Latitude: -1.940278,
      },
      {
        name: "Saudi Arabia",
        shortName: "SAU",
        Longitude: 45.079162,
        Latitude: 23.885942,
      },
      {
        name: "Senegal",
        shortName: "SEN",
        Longitude: -14.452362,
        Latitude: 14.497401,
      },
      {
        name: "Sierra Leone",
        shortName: "SLE",
        Longitude: -11.779889,
        Latitude: 8.460555,
      },
      {
        name: "Somalia",
        shortName: "SOM",
        Longitude: "46.199616",
        Latitude: "5.152149",
      },
      {
        name: "South Africa",
        shortName: "ZAF",
        Longitude: 22.937506,
        Latitude: -30.559482,
      },
      {
        name: "South Sudan",
        shortName: "SSD",
        Longitude: 31.490383,
        Latitude: 5.0729608,
      },
      {
        name: "Sudan",
        shortName: "SDN",
        Longitude: 30.217636,
        Latitude: 12.862807,
      },
      {
        name: "Swaziland",
        shortName: "SWZ",
        Longitude: 31.465866,
        Latitude: -26.522503,
      },
      {
        name: "Syria",
        shortName: "SYR",
        Longitude: 38.996815,
        Latitude: 34.802075,
      },
      {
        name: "Tanzania",
        shortName: "TZA",
        Longitude: 34.888822,
        Latitude: -6.369028,
      },
      {
        name: "Togo",
        shortName: "TGO",
        Longitude: 0.824782,
        Latitude: 8.619543,
      },
      {
        name: "Tunisia",
        shortName: "TUN",
        Longitude: 9.537499,
        Latitude: 33.886917,
      },
      {
        name: "Uganda",
        shortName: "UGA",
        Longitude: 32.290275,
        Latitude: 1.373333,
      },
      {
        name: "United Arab Emirates",
        shortName: "ARE",
        Longitude: 53.847818,
        Latitude: 23.424076,
      },
      {
        name: "Western Sahara",
        shortName: "ESH",
        Longitude: -12.885834,
        Latitude: 24.215527,
      },
      {
        name: "Yemen",
        shortName: "YEM",
        Longitude: "48.516388",
        Latitude: "15.552727",
      },
      {
        name: "Zambia",
        shortName: "ZMB",
        Longitude: 27.849332,
        Latitude: -13.133897,
      },
      {
        name: "Zimbabwe",
        shortName: "ZWE",
        Longitude: 29.154857,
        Latitude: -19.015438,
      },
    ],
  },

  {
    region: "Central Asia",
    Longitude: "68.8319",
    Latitude: "45.4507",
    shortCode: "CAS",
    CountryList: [
      {
        name: "Armenia",
        shortName: "ARM",
        Longitude: 44.556813732004365,
        Latitude: 40.26670713713503,
      },
      {
        name: "Azerbaijan",
        shortName: "AZE",
        Longitude: 47.576927,
        Latitude: 40.143105,
      },
      {
        name: "Georgia",
        shortName: "GEO",
        Longitude: 43.356892,
        Latitude: 42.315407,
      },
      {
        name: "Kazakhstan",
        shortName: "KAZ",
        Longitude: 66.923684,
        Latitude: 48.019573,
      },
      {
        name: "Kyrgyzstan",
        shortName: "KGZ",
        Longitude: 74.766098,
        Latitude: 41.20438,
      },
      {
        name: "Mongolia",
        shortName: "MNG",
        Longitude: 103.846656,
        Latitude: 46.862496,
      },
      {
        name: "Tajikistan",
        shortName: "TJK",
        Longitude: 71.276093,
        Latitude: 38.861034,
      },
      {
        name: "Turkey",
        shortName: "TUR",
        Longitude: 35.243322,
        Latitude: 38.963745,
      },
      {
        name: "Turkmenistan",
        shortName: "TKM",
        Longitude: 59.556278,
        Latitude: 38.969719,
      },
      {
        name: "Uzbekistan",
        shortName: "UZB",
        Longitude: 64.585262,
        Latitude: 41.377491,
      },
    ],
  },

  {
    region: "Australasia",
    Longitude: "145.4915",
    Latitude: "29.5328",
    shortCode: "AUS",
    CountryList: [
      {
        name: "Australia",
        shortName: "AUS",
        Longitude: 133.775136,
        Latitude: -25.274398,
      },
      {
        name: "Fiji",
        shortName: "FJI",
        Longitude: 177.89567011239126,
        Latitude: -17.906276013092434,
      },
      {
        name: "Marshall Islands",
        shortName: "MHL",
        Longitude: 171.184478,
        Latitude: 7.131474,
      },
      {
        name: "Nauru",
        shortName: "NRU",
        Longitude: 166.9387878050076,
        Latitude: -0.5218203133115576,
      },
      {
        name: "New Caledonia",
        shortName: "NCL",
        Longitude: 165.45657975227687,
        Latitude: -21.283428473158253,
      },
      {
        name: "New Zealand",
        shortName: "NZL",
        Longitude: 174.885971,
        Latitude: -40.900557,
      },
      {
        name: "Norfolk Island",
        shortName: "NFK",
        Longitude: 167.94984777302412,
        Latitude: -29.02198370102146,
      },
      {
        name: "Papua New Guinea",
        shortName: "PNG",
        Longitude: 143.95555,
        Latitude: -6.314993,
      },
      {
        name: "Solomon Islands",
        shortName: "SLB",
        Longitude: 160.15218220239942,
        Latitude: -9.620173649678213,
      },
      {
        name: "Timor-Leste",
        shortName: "TLS",
        Longitude: 125.70630512917215,
        Latitude: -8.67910353147889,
      },
      {
        name: "Tuvalu",
        shortName: "TUV",
        Longitude: 179.2080465602081,
        Latitude: -8.519951447196435,
      },
      {
        name: "Vanuatu",
        shortName: "VUT",
        Longitude: 167.21117018328545,
        Latitude: -15.291371869096418,
      },
    ],
  },

  {
    region: "Antarctica",
    Longitude: "135.0000",
    Latitude: "82.8628",
    shortCode: "ANT",
    CountryList: [
      {
        name: "Antarctica",
        shortName: "ATA",
        Longitude: -0.071389,
        Latitude: -75.250973,
      },
    ],
  },

  {
    region: "Arctic",
    Longitude: "100.1140",
    Latitude: "76.2506",
    shortCode: "ARC",
    CountryList: [
      {
        name: "Finland",
        shortName: "FIN",
        Longitude: 25.748151,
        Latitude: 61.92411,
      },
      {
        name: "Greenland",
        shortName: "GRL",
        Longitude: -42.604303,
        Latitude: 71.706936,
      },
      {
        name: "Iceland",
        shortName: "ISL",
        Longitude: -19.020835,
        Latitude: 64.963051,
      },
      {
        name: "Russian Federation",
        shortName: "RUS",
        Longitude: 105.318756,
        Latitude: 61.52401,
      },
      {
        name: "Svalbard and Jan Mayen",
        shortName: "SJM",
        Longitude: 15.643621642861383,
        Latitude: 78.60426110200481,
      },
    ],
  },

  {
    region: "Mediterranean",
    Longitude: "18.0480",
    Latitude: "34.5531",
    shortCode: "MED",
    CountryList: [],
  },

  {
    region: "Middle East and North Africa",
    Longitude: "",
    Latitude: "",
    shortCode: "MNA",
    CountryList: [
      {
        name: "Algeria",
        shortName: "DZA",
        Longitude: 1.659626,
        Latitude: 28.033886,
      },
      {
        name: "Bahrain",
        shortName: "BHR",
        Longitude: 50.565562972335435,
        Latitude: 26.039433298720294,
      },
      {
        name: "Cape Verde",
        shortName: "CPV",
        Longitude: -23.339591127132493,
        Latitude: 14.996882432683238,
      },
      {
        name: "Cyprus",
        shortName: "CYP",
        Longitude: 33.21304919792437,
        Latitude: 35.052920893331454,
      },
      {
        name: "Djibouti",
        shortName: "DJI",
        Longitude: 42.62993715300226,
        Latitude: 11.750935262899002,
      },
      {
        name: "Egypt",
        shortName: "EGY",
        Longitude: 30.802498,
        Latitude: 26.820553,
      },
      {
        name: "Eritrea",
        shortName: "ERI",
        Longitude: 39.782334,
        Latitude: 15.179384,
      },
      {
        name: "Gambia",
        shortName: "GMB",
        Longitude: -15.463031479817886,
        Latitude: 13.473771130017388,
      },
      {
        name: "Guinea",
        shortName: "GIN",
        Longitude: -9.696645,
        Latitude: 9.945587,
      },
      {
        name: "Guinea-Bissau",
        shortName: "GNB",
        Longitude: -15.180413,
        Latitude: 11.803749,
      },
      {
        name: "Iran",
        shortName: "IRN",
        Longitude: 53.688046,
        Latitude: 32.427908,
      },
      {
        name: "Iraq",
        shortName: "IRQ",
        Longitude: 43.679291,
        Latitude: 33.223191,
      },
      {
        name: "Israel",
        shortName: "ISR",
        Longitude: 34.851612,
        Latitude: 31.046051,
      },
      {
        name: "Jordan",
        shortName: "JOR",
        Longitude: 36.238414,
        Latitude: 30.585164,
      },
      {
        name: "Kuwait",
        shortName: "KWT",
        Longitude: 47.481766,
        Latitude: 29.31166,
      },
      {
        name: "Lebanon",
        shortName: "LBN",
        Longitude: 35.920463883876636,
        Latitude: 34.17261584527978,
      },
      {
        name: "Libya",
        shortName: "LBY",
        Longitude: 17.228331,
        Latitude: 26.3351,
      },
      {
        name: "Mali",
        shortName: "MLI",
        Longitude: -3.996166,
        Latitude: 17.570692,
      },
      {
        name: "Malta",
        shortName: "MLT",
        Longitude: 14.375416,
        Latitude: 35.937496,
      },
      {
        name: "Mauritania",
        shortName: "MRT",
        Longitude: -10.940835,
        Latitude: 21.00789,
      },
      {
        name: "Morocco",
        shortName: "MAR",
        Longitude: -7.09262,
        Latitude: 31.791702,
      },
      {
        name: "Palestinian Territory",
        shortName: "PSE",
        Longitude: 35.233154,
        Latitude: 31.952162,
      },
      {
        name: "Qatar",
        shortName: "QAT",
        Longitude: 51.183884,
        Latitude: 25.354826,
      },
      {
        name: "Saudi Arabia",
        shortName: "SAU",
        Longitude: 45.079162,
        Latitude: 23.885942,
      },
      {
        name: "Senegal",
        shortName: "SEN",
        Longitude: -14.452362,
        Latitude: 14.497401,
      },
      {
        name: "Sierra Leone",
        shortName: "SLE",
        Longitude: -11.779889,
        Latitude: 8.460555,
      },
      {
        name: "Syria",
        shortName: "SYR",
        Longitude: 38.996815,
        Latitude: 34.802075,
      },
      {
        name: "Tunisia",
        shortName: "TUN",
        Longitude: 9.537499,
        Latitude: 33.886917,
      },
      {
        name: "United Arab Emirates",
        shortName: "ARE",
        Longitude: 53.847818,
        Latitude: 23.424076,
      },
      {
        name: "Western Sahara",
        shortName: "ESH",
        Longitude: -12.885834,
        Latitude: 24.215527,
      },
    ],
  },
];
