import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

const TemperatureIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    width="70"
    height="74"
    viewBox="0 0 70 74"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M58.9485 32.9734V21.9823H51.5808V32.9734H40.5293V40.3009H51.5808V51.292H58.9485V40.3009H70V32.9734H58.9485ZM29.4777 40.3009V10.9911C29.4777 4.90938 24.5414 0 18.4262 0C12.311 0 7.37468 4.90938 7.37468 10.9911V40.3009C-0.766613 46.3826 -2.42434 57.8501 3.69084 65.9469C9.80602 74.0437 21.3364 75.6924 29.4777 69.6106C37.619 63.5288 39.2768 52.0614 33.1616 43.9646C32.1301 42.5724 30.8776 41.3267 29.4777 40.3009ZM18.4262 7.32743C20.4523 7.32743 22.1101 8.9761 22.1101 10.9911V21.9823H14.7424V10.9911C14.7424 8.9761 16.4001 7.32743 18.4262 7.32743Z" />
  </SvgIcon>
);

export default TemperatureIcon;
