import { Button } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { CustomDropdownMenuItemsType } from "../../../../types/types";
import { LocationSelectContext } from "../../context/LocationSelectPageContext";
import { RegionContext } from "../../context/RegionContext";
import CustomDropdown from "../../partials/CustomDropdown";
import SearchBox from "../../partials/SearchBox";
import { availableDatasets } from "./datasets";
import { useDatasetSelectCardStyle } from "./useStyles";

type PropTypes = {
  isVisible: boolean;
};

function DatasetSelectCard(props: PropTypes) {
  const { isVisible } = props;

  const classes = useDatasetSelectCardStyle();

  const {
    welcomeToTheArupModalState,
    climateDatasetModalState,
    selectedDatasetState,
    selectLocationCardVisibleState,
    handlers,
    datasetSelectionModalState,
    otherToolCardVisibileState,
    selectedClimateVariableState,
    selectedClimateVariableSourceState,
    selectedResolutionState,
    timeDurationState,
    selectedProjectedYearState,
    historicalYearState,
    configureDatasetHistoricalDataState,
    timeDurationDropDown
  } = useContext(LocationSelectContext);
  const {setTimeDuration} = timeDurationDropDown;
  const { geoJSON } = useContext(RegionContext);
  const { setIsOtherToolCardVisibile } = otherToolCardVisibileState;
  const { setHistoricalYear } = historicalYearState;
  const {
    setSelectedProjectedYear,
  } = selectedProjectedYearState;
  const {
    setState
  } = configureDatasetHistoricalDataState;
  const { setIsWelcomeToTheArupModalOpen } = welcomeToTheArupModalState;
  const { selectedDataset, setSelectedDataset } = selectedDatasetState;
  const { setIsClimateDatasetModalOpen } = climateDatasetModalState;
  const {
    isSelectLocationCardVisible,
    setIsSelectLocationCardVisible,
  } = selectLocationCardVisibleState;
  const {
    isDatasetSelectionModalOpen,
    setIsDatasetSelectionModalOpen,
  } = datasetSelectionModalState;
  const { closeModalsHandler, closeAllModalsAndPopups } = handlers;
  const { setSelectedClimateVariable } = selectedClimateVariableState;
  const {
    setSelectedClimateVariableSource,
  } = selectedClimateVariableSourceState;
  const { setSelectedResolution } = selectedResolutionState;
  const [geoJson, setGeoJson] = geoJSON;

  const [datasets, setDatasets] = useState<CustomDropdownMenuItemsType[]>([]);

  const datasetChangeHandler = (e) => {
    const value = e?.target?.value;
    setIsOtherToolCardVisibile(false);
    setIsWelcomeToTheArupModalOpen(false);
    setIsClimateDatasetModalOpen(false);
    setIsDatasetSelectionModalOpen(true);
    setSelectedDataset(value);
  };

  // for dataset dropdown options
  useEffect(() => {
    const sets: CustomDropdownMenuItemsType[] = [];

    availableDatasets.forEach((item) => {
      sets.push({ label: item.name, disabled: !item.isAvailable });
    });

    setDatasets(sets);
  }, []);

  return (
    <CSSTransition in={isVisible} timeout={300} classNames="item" unmountOnExit>
      <div>
        <SearchBox
          title="Select Dataset"
          overlay={isSelectLocationCardVisible}
          editClickHandler={() => {
            setGeoJson((prevState) => {
              return {
                ...prevState,
                features: [
                  {
                    type: "Feature",
                    properties: {
                      title: "blank",
                      short_name: "blank",
                      region: "blank",
                      region_short: "blank",
                    },
                    geometry: {
                      type: "Point",
                      coordinates: [1, 1],
                    },
                  },
                ],
              };
            });
            setTimeDuration("")
            setState({
              dataset: "",
              from: "",
              projection: "",
            });
            setHistoricalYear({ from: "", to: "" });
            timeDurationState[1]("");
            setSelectedProjectedYear("");
            setSelectedResolution("");
            setSelectedClimateVariableSource("");
            setSelectedClimateVariable("");
            setIsSelectLocationCardVisible(false);
            closeAllModalsAndPopups();
            setIsDatasetSelectionModalOpen(true);
          }}
          infoClickHandler={() => {
            setIsWelcomeToTheArupModalOpen(true);
            closeModalsHandler();
          }}
        >
          <CustomDropdown
            value={selectedDataset}
            onChange={datasetChangeHandler}
            label="Dataset:"
            placeholder="Select One"
            name="dataset"
            menuItems={datasets}
            // isReadOnly={!isDatasetSelectionModalOpen}
          />

          <div className={classes.btnContainer}>
            <Button
              disabled={!selectedDataset}
              disableElevation
              variant="contained"
              color="primary"
              className={classes.btn}
              style={{ marginRight: "16px" }}
              onClick={() => setSelectedDataset("")}
            >
              Clear All
            </Button>
            <Button
              onClick={() => {
                setIsSelectLocationCardVisible(true);
                setIsDatasetSelectionModalOpen(false);
              }}
              color="secondary"
              disabled={!selectedDataset}
              disableElevation
              variant="contained"
              className={classes.btn}
            >
              Select &amp; Continue
            </Button>
          </div>
        </SearchBox>
      </div>
    </CSSTransition>
  );
}

export default DatasetSelectCard;
