import React from "react";
import { Typography, Button } from "@material-ui/core";

import ErrorIcon from "@material-ui/icons/Error";

import {
  useClimateDataHistoryCardStyles,
  ClimateDataHistoryCardPropTypes,
} from "./useStyles";

import climateDataHIstorySvg from "../../../../../images/climateDataHIstorySvg.svg";

const ClimateDataHistoryCard = (props: ClimateDataHistoryCardPropTypes) => {
  const {
    isRetrievingData,
    requestedDate,
    dataSource,
    onClick,
    // downloadCsv,
    projectName,
    variable,
    location,
    status,
    style,
    lat,
    long,
  } = props;

  const classes = useClimateDataHistoryCardStyles();

  return (
    <div
      className={classes.mainCardContainer}
      style={{
        backgroundColor: isRetrievingData ? "#DBDBDB" : "#ADE5F7",
        pointerEvents: isRetrievingData ? "none" : "auto",
        ...style,
      }}
      onClick={onClick}
    >
      <div className={classes.imgContainer}>
        <img
          src={climateDataHIstorySvg}
          alt="arup-logo"
          style={{ height: "7rem", mixBlendMode: "lighten" }}
        />
      </div>
      <Typography variant="body1">
        Project Name: {projectName} <br />
        Source: {dataSource.toUpperCase()} <br />
        Variable: {variable} <br />
        Location: {location} <br />
        Requested: {requestedDate} <br />
        <strong>Coordinates</strong>: <br />
        Lat: &nbsp; &nbsp; {lat?.map((number) => number.toFixed(6)).join(", ")} <br />
        Long: {long?.map((number) => number.toFixed(6)).join(", ")} <br />
      </Typography>

      <div className={classes.btnContainer}>
        {isRetrievingData ? (
          <Button
            className={classes.btn}
            style={{
              width: "8.8125rem",
              color: status === "failed" ? "rgba(255, 0, 0, 1) !important" : "",
            }}
            variant="contained"
            disableElevation
            disabled
            startIcon={
              status === "failed" ? (
                <ErrorIcon
                  style={{ color: "rgba(255, 0, 0, 0.5)", fontSize: 18 }}
                />
              ) : undefined
            }
          >
            {status === "failed" ? "Failed" : "In progress..."}
          </Button>
        ) : (
          <>
            <Button
              className={classes.btn}
              variant="contained"
              color="secondary"
              disableElevation
            >
              View Output
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default ClimateDataHistoryCard;
