import React from "react";
import { Box, Button, Typography } from "@material-ui/core";

import weatherShiftLogo from "../../../../images/weatherShiftLogo.png";
import CollapsibleCard from "../../partials/CollapsibleCard";
import { CSSTransition } from "react-transition-group";

type PropsType = {
  isOtherToolCardVisibile: boolean;
};

function OtherToolCard(props: PropsType) {
  const { isOtherToolCardVisibile } = props;

  return (
    <CSSTransition
      in={isOtherToolCardVisibile}
      timeout={300}
      classNames="slide-right-animation"
      unmountOnExit
    >
      <div style={{ marginTop: "auto" }}>
        <CollapsibleCard title="Other Arup Climate Tools...">
          <img
            src={weatherShiftLogo}
            style={{ height: "1.8125rem", marginBottom: "1.875rem" }}
            alt="logo"
          />
          <Typography style={{ fontSize: "0.875rem" }}>
            The WeatherShift™ tool uses data from global climate change
            modelling to produce EPW weather files adjusted for changing climate
            conditions. (EPW files contain hourly values of key weather
            variables for a typical year and are intended to be used for
            simulating building energy requirements.) The projected data can be
            viewed for three future time periods based on the emission scenario
            selected.
            <br />
            <br />
            WeatherShift Rain provides the opportunity for flexible and robust
            analysis by projecting rainfall intensity for multiple rainfall
            durations (3-, 6-, 12-, 24-, and 48-hours), two emission scenarios
            (RCP 4.5 and RCP 8.5), a range of possible climate outcomes (seven
            per emissions scenario) and in years 2035 and 2090. This data allows
            designers and engineers to plan and design measurable and
            cost-effective solutions to mitigate the effects of changing
            rainfall patterns.
          </Typography>
          <Box
            display="flex"
            width="100%"
            justifyContent="center"
            mt="1.875rem"
            mb="1.25rem"
          >
            <Button
              href=" https://www.weathershift.com"
              target="_blank"
              disableElevation
              variant="contained"
              color="secondary"
            >
              Launch Tool
            </Button>
          </Box>
        </CollapsibleCard>
      </div>
    </CSSTransition>
  );
}

export default OtherToolCard;
