/** 
 * This function takes two arg the first arg is the string in which you want to look for
 * and the second arg is the word/text that you are looking for
 * @param str the string in which we want to search for the word or alphabet
   @param searchText the text that we want to find
   @returns It returns boolean value if the searchText exists in the string then it will return true otherwise false
*/
export const doesStringContainThisText = (
  str: string,
  searchText: string
): boolean => {
  return str.toLowerCase().includes(searchText.toLowerCase());
};

/**
 * @param value: string
 * @description This function removes special characters and alphabet and return only numbers as string
 */
export const removeSpecialCharAndAlphabet = (value: string) => {
  // the first regex for removing alphabeat
  return value.replace(/[A-Za-z]/g, "");

  // for removing special char
  // .replace(/[^\w\s]/gi, "")
};

export function validateLatLong(lat, long) {
  const LAT_REGEX = /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/;

  const LONG_REGEX = /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/;

  return (
    LAT_REGEX.test(Number(lat).toFixed(6)) &&
    LONG_REGEX.test(Number(long).toFixed(6))
  );
}
