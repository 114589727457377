export const CORDEX = "CORDEX";
export const LOCA = "LOCA";
export const UKCP18_PROB = "UKCP18 Probabilistic (Average Trends)";
export const UKCP18_HIGH_RES = "UKCP18 High-Res (Extreme Indicators)";
export const HAD_UK = "Had-UK (UK Observations)";
export const CMIP6 = "CMIP6 (coming soon)";

export const availableDatasets: Array<{
  name: string;
  isAvailable: boolean;
}> = [
  { name: CORDEX, isAvailable: true },
  { name: LOCA, isAvailable: true },
  { name: UKCP18_PROB, isAvailable: true },
  { name: UKCP18_HIGH_RES, isAvailable: true },
  { name: HAD_UK, isAvailable: true },
  { name: CMIP6, isAvailable: false },
];

export const apiDataSource = {
  LOCA:"loca",
  HAD_UK:"haduk",
  UKCP18_PROB:"ukcp18-prob",
  UKCP18_HIGH_RES:"ukcp18-high-res",
  CORDEX:'cordex'
}