import React from "react";
import { Typography } from "@material-ui/core";

import {
  ClimateDatasetCardPropTypes,
  useClimateDataSetCardStyles,
} from "./useStyles";

const ClimateDataSetCard = (props: ClimateDatasetCardPropTypes) => {
  const classes = useClimateDataSetCardStyles();

  const { title, description } = props;

  return (
    <div style={{ borderBottom: "1px solid #BEBEBE", paddingBottom: "10px" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "0.9375rem",
        }}
      >
        <span className={classes.dot}></span>
        <Typography variant="h4" className={classes.title}>
          {title}
        </Typography>
      </div>
      <Typography variant="body1" className={classes.description}>
        {description}
      </Typography>
    </div>
  );
};

export default ClimateDataSetCard;
