import React from "react";
import { makeStyles } from "@material-ui/core";

export type ClimateVariableTypeCardPropTypes = {
  title: string;
  description: string;
  btnBackGroundColor: string;
  icon: React.ReactNode;
  onClick: () => void;
  isAvailable: boolean;
};

export const useClimateVariableTypeCardStyles = makeStyles({
  mainContainer: {
    width: "17.0625rem",

    marginBottom: "2.25rem",
  },
  imgContainer: {
    width: "5rem",
    height: "5rem",
    background:
      "linear-gradient(180deg, #1873B0 0%, rgba(18, 212, 255, 0.27) 100%)",
    borderRadius: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  cardMainHeading: {
    fontWeight: 300,
    fontSize: "1.125rem",
    lineHeight: "21px",
    color: " #1C1C1C",

    marginTop: "0.9375rem",
  },

  content: {
    fontSize: "0.75rem",
    lineHeight: "13px",
    color: "#1C1C1C",

    marginTop: "0.875rem",
  },
  btn: {
    width: "11.5rem",
    height: "32px",
    borderRadius: "20px",
    fontSize: "0.75rem",
    lineHeight: "14px",

    marginTop: "0.9375rem",
    backgroundColor: "#ADE5F7",
    "&:hover": {
      backgroundColor: "#ADE5F7",
    },
  },
});

export type VariableSelectionModalPropTypes = {
  open: boolean;
  closeModalHandler: () => void;
};

export const useVariableSelectionModalStyles = makeStyles({
  btn: {
    width: "8.7975rem",
    fontSize: "0.8374rem",
    lineHeight: "16px",
    color: " #1C1C1C",
  },
});
