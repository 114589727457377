import React from "react";
import { makeStyles } from "@material-ui/core";

export type ClimateDataHistoryModalPropsType = {
  open: boolean;
  closeModalHandler: () => void;
};

export const useClimateDataHistoryModalStyles = makeStyles({
  cardsContainer: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",

    marginTop: "2.8125rem",

    maxHeight: "28rem",
    overflowY: "scroll",
  },

  cardAddMoreButton: {
    width: "18rem",
    height: "11rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#E2F1FF",
    mixBlendMode: "multiply",
    borderRadius: "0",
    "&:hover": {
      backgroundColor: "#E2F1FF",
    },
  },
});

export type ClimateDataHistoryCardPropTypes = {
  isRetrievingData: boolean;
  requestedDate: Date | string;
  dataSource: string;
  onClick: () => void;
  downloadCsv: () => void;
  projectName: string;
  variable: string;
  location: string;
  status: string;
  style?: React.CSSProperties;
  lat: number[];
  long: number[];
};

export const useClimateDataHistoryCardStyles = makeStyles({
  mainCardContainer: {
    width: " 18rem",

    height: "fit-content",
    padding: "1.0625rem 0.9375rem 1.125rem 1.125rem",
    backgroundColor: "#ADE5F7",

    margin: "0 0 1rem 0",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    cursor: "pointer",

    "& > p": {
      fontSize: "1rem",
      lineHeight: "19px",
      color: "#1C1C1C",
      zIndex: "1",
    },
  },

  imgContainer: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  btnContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginTop: "auto",
  },

  btn: {
    width: "6.9375rem",
    lineHeight: "16px",
    height: "24px",
    fontSize: "0.6875rem",
    marginTop: "1rem",
  },
});
