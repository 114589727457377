import { makeStyles } from "@material-ui/core";
import { backgroundClip } from "html2canvas/dist/types/css/property-descriptors/background-clip";

export const useTimePeriodSelectionModalStyles = makeStyles((theme) => ({
  select: {
    width: "12.5rem",
    height: "24px",
    borderRadius: "4px",
  },
  imgContainer: {
    width: "60px",
    height: " 60px",
    borderRadius: "50%",
    background:
      "linear-gradient(180deg, #88CFFF 31.77%, rgba(26, 59, 141, 0.69) 100%)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "auto",
  },

  icon: {
    color: "white",
    fontSize: "2rem",
  },

  dateBox: {
    width: "15.625rem",
    height: "30px",
    padding: "2px",
    border: "1px solid black",
    borderRadius: "4px",
    textAlign: "center",
  },

  container: {
    gap: "60px",
    "& .MuiRadio-root": {
      color: "black"
    },

    "& .MuiRadio-colorSecondary.Mui-checked": {
      color: theme.palette.secondary.main,
    },
  },

  disabledRadio: {
    backgroundColor: "transparent !important",
    cursor: "no-drop",

    "& .Mui-disabled": {
      backgroundColor: "transparent !important",
      opacity:0.5
    },

    "& .Mui-disabled .MuiSvgIcon-root": {
      fill: "#757371 !important",
    },
  },

  yearText: {
    position: "absolute",
    top: 50,
    left: -30,
    transform: "rotate(-90deg)"
  },
}));

export type TimePeriodSelectionModalPropTypes = {
  open: boolean;
  closeModalHandler: () => void;
};
