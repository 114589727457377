import { makeStyles } from "@material-ui/core";

export type DataSetCardPropTypes = {
  imgPath: string;
  selected: boolean;
  title: string;
  description: string;
  unit: string;
  onSelect: () => void;
};

export const useDataSetCardStyles = makeStyles({
  mainContainer: {
    marginRight: "4px",
    marginBottom: "2.5rem",
  },
  imgContainer: {
    height: "96px",
    width: "96px",

    background: (props: DataSetCardPropTypes) =>
      props.selected
        ? `url(${props.imgPath}) center`
        : ` linear-gradient(rgba(225,225,225, .5), rgba(225,225,225, .5)), url(${props.imgPath}) center`,

    backgroundSize: "contain",
    borderRadius: "4px",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  title: {
    fontSize: "11px",
    lineHeight: "13px",
    color: "#303030",
    fontWeight: "normal",
    marginTop: "12px",
    maxWidth: "6.125rem",
  },
  bodyText: {
    fontSize: "0.75rem",
    lineHeight: "10.55px",
    color: "#303030",
    maxWidth: "98px",
    marginTop: "8px",
  },
  circle: {
    width: "28px",
    height: "28px",
    borderRadius: "100%",
    background: "#7CD4F2",
    marginRight: "7px",
    margin: "0 10px 7px 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
