import { indicatorThresholdOptions } from "../data";
import {
  IDateRange,
  IThresholdValidation,
  IValueValidation,
} from "../interfaces";

export function validateCoordinates(coordinateValue: any, limit: number) {
  let validationObject: IValueValidation = { value: null, errorMessage: "" };
  const decimalsLimit = {
    lower: 1,
    uppper: 6,
  };
  if (Math.abs(coordinateValue) <= limit) {
    let decimals = coordinateValue.toString().split(".")[1];
    if (
      (decimals?.length >= decimalsLimit.lower &&
        decimals?.length < decimalsLimit.uppper) ||
      coordinateValue === ""
    ) {
      validationObject = {
        value: +coordinateValue,
        errorMessage: "",
      };
    } else {
      validationObject = {
        value: +coordinateValue,
        errorMessage: "Coordinates must have min. 1 decimal place and max. 5",
      };
    }
  } else {
    validationObject = {
      value: +coordinateValue,
      errorMessage: `Coordinates not within the required range -${limit} and +${limit}`,
    };
  }
  return validationObject;
}

export function validateEmail(emailString: string) {
  const allowedDomain = "arup.com";
  let emailValidationObject = {
    value: emailString,
    errorMessage: "",
  };
  let emailDomain = emailString.split("@")[1];
  let username = emailString.split("@")[0];
  if (
    (username !== "" && emailDomain === allowedDomain) ||
    emailString === ""
  ) {
    emailValidationObject = {
      value: emailString,
      errorMessage: "",
    };
  } else {
    emailValidationObject = {
      value: emailString,
      errorMessage: `Email address not valid. Domain must be @${allowedDomain}. Username cannot be empty.`,
    };
  }

  return emailValidationObject;
}

export function validateThreshold(thresholdObject: any) {
  let thresholdValidationObject = {
    value: "",
    isCelsius: true,
    errorMessage: "",
  };

  // temperature input can be a positive or negative number, integer part can have between 0 and 3 digits, max. 1 decimal place
  const regexThresholdValue = /^[+-]?(\d{1,3}|\d{0,3}\.\d{0,1})$/;
  if (regexThresholdValue.test(thresholdObject.value)) {
    if (thresholdObject.isCelsius) {
      if (thresholdObject.value >= -100 && thresholdObject.value <= 60) {
        thresholdValidationObject = {
          value: thresholdObject.value,
          isCelsius: true,
          errorMessage: "",
        };
      } else {
        thresholdValidationObject = {
          value: thresholdObject.value,
          isCelsius: true,
          errorMessage: "Temperature must be between -100 °C and 60 °C",
        };
      }
    } else if (!thresholdObject.isCelsius) {
      if (thresholdObject.value >= -148 && thresholdObject.value <= 140) {
        thresholdValidationObject = {
          value: thresholdObject.value,
          isCelsius: false,
          errorMessage: "",
        };
      } else {
        thresholdValidationObject = {
          value: thresholdObject.value,
          isCelsius: false,
          errorMessage: "Temperature must be between -148 °F and 140 °F",
        };
      }
    }
  } else {
    thresholdValidationObject = {
      value: thresholdObject.value,
      isCelsius: thresholdObject.isCelsius,
      errorMessage:
        "Temperature can have max. 1 decimal place. Digits input only.",
    };
  }
  return thresholdValidationObject;
}

export function convertTemperatureIfNeeded(thresholdObject: any) {
  const { isCelsius, value } = thresholdObject;
  let queryThresholdValue: number;
  if (!isCelsius) {
    const tempInCelsius = ((value - 32) * 5) / 9;
    const absoluteThresholdValue = Math.abs(tempInCelsius) as any;
    const roundedAbsoluteCelsius = Number(
      Math.round((absoluteThresholdValue + "e" + 1) as any) + "e" + 1 * -1,
    );
    tempInCelsius < 0
      ? (queryThresholdValue = -roundedAbsoluteCelsius)
      : (queryThresholdValue = roundedAbsoluteCelsius);
  } else {
    queryThresholdValue = value;
  }
  return queryThresholdValue;
}

export function disableSearchButton(
  indicator: string,
  threshold: IThresholdValidation,
  latitude: IValueValidation,
  longitude: IValueValidation,
  historicalDateRange: IDateRange,
  futureDateRange: IDateRange,
  email: IValueValidation,
) {
  const areValuesFilledAndValidated = () =>
    latitude.value !== "" &&
    latitude.errorMessage === "" &&
    longitude.value !== "" &&
    longitude.errorMessage === "" &&
    historicalDateRange.start !== "" &&
    futureDateRange.start !== "" &&
    email.value !== "" &&
    email.errorMessage === "";

  const isIndicatorThresholdAndValid = () =>
    indicator !== "" &&
    (indicator === indicatorThresholdOptions.moreThan ||
      indicator === indicatorThresholdOptions.lessThan) &&
    threshold.value !== undefined &&
    threshold.errorMessage === "";

  const isIndicatorNotThresholdAndValid = () =>
    indicator !== "" &&
    (indicator !== indicatorThresholdOptions.moreThan ||
      indicator !== indicatorThresholdOptions.lessThan);

  if (
    areValuesFilledAndValidated() &&
    (isIndicatorThresholdAndValid() || isIndicatorNotThresholdAndValid())
  )
    return false;
  return true;
}

export const formatMapBoxCoordinates = (coordinate: number) => {
  const coordinateStringWhole = coordinate.toString().split(".")[0];
  const maxDecimalPlaces = 5;
  const coordinateStringDecimals = coordinate
    .toString()
    .split(".")[1]
    .slice(0, maxDecimalPlaces);

  const correctCoordinate =
    coordinateStringWhole + "." + coordinateStringDecimals;

  return Number(correctCoordinate);
};

export const formatIndicatorInput = (indicator: string) => {
  let formattedIndicatorInput = "";
  const temperatureSlice = 11;
  const dailySliceLimits = {
    lower: 7,
    upper: 14,
  };
  if (indicator.slice(0, temperatureSlice) === "TEMPERATURE") {
    formattedIndicatorInput = indicator.slice(temperatureSlice + 1);
  } else if (indicator.includes("DAILY")) {
    formattedIndicatorInput =
      indicator.slice(0, dailySliceLimits.lower) +
      " " +
      indicator.slice(dailySliceLimits.upper);
  } else if (indicator.includes("-")) {
    const indicatorAcronym = indicator.split("-")[0];
    formattedIndicatorInput = indicatorAcronym.slice(
      0,
      indicatorAcronym.length - 1,
    );
  } else formattedIndicatorInput = indicator;
  return formattedIndicatorInput;
};
