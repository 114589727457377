import React, { useContext, useState } from "react";
import { Grid, SvgIconProps } from "@material-ui/core";

import ClimateVariableTypeCard from "./ClimateVariableTypeCard";

import TemperatureIcon from "../../../Icons/TemperatureIcon";
import WindIcon from "../../../Icons/WindIcon";
import HumidityIcon from "../../../Icons/HumidityIcon";
import SnowIcon from "../../../Icons/SnowIcon";
import MarineIcon from "../../../Icons/MarineIcon";
import PrecipitationIcon from "../../../Icons/PrecipitationIcon";

import Pagination from "../../../partials/Pagination";

import CustomModal from "../../../partials/CustomModal";
import { LocationSelectContext } from "../../../context/LocationSelectPageContext";

import { VariableSelectionModalPropTypes } from "./useStyles";

const VariableSelectionModal = (props: VariableSelectionModalPropTypes) => {
  const { open, closeModalHandler } = props;

  const {
    selectedClimateVariableState,
    variableSelectionModalState,
    windRelatedVariablesModalState,
  } = useContext(LocationSelectContext);

  const { setIsVariableSelectionModalOpen } = variableSelectionModalState;

  const { setIsWindRelatedVariablesModalOpen } = windRelatedVariablesModalState;

  //-----------------selected variable state-----------------//
  const {
    selectedClimateVariable,
    setSelectedClimateVariable,
  } = selectedClimateVariableState;

  //eslint-disable-next-line
  const [dataForClimateVariableTypeCard] = useState<
    Array<{
      title: string;
      description: string;
      icon: React.ElementType<SvgIconProps>;
      isAvailable: boolean;
    }>
  >([
    {
      title: "Temperature",
      description:
        "If you are interested to explore a range of temperature projection variables.",
      isAvailable: true,
      icon: TemperatureIcon,
    },
    {
      title: "Precipitation",
      description:
        "If you are interested to explore a range of precipitation projection variables.",
      isAvailable: true,
      icon: PrecipitationIcon,
    },
    {
      title: "Wind",
      description:
        "If you are interested to explore a range of wind projection variables.",
      isAvailable: false,
      icon: WindIcon,
    },
    {
      title: "Humidity",
      description:
        "If you are interested to explore a range of humidity projection variables.",
      isAvailable: false,
      icon: HumidityIcon,
    },
    {
      title: "Snow",
      description:
        "If you are interested to explore a range of snow projection variables.",
      isAvailable: false,
      icon: SnowIcon,
    },
    {
      title: "Marine",
      description:
        "If you are interested to explore a range of marine projection variables.",
      isAvailable: false,
      icon: MarineIcon,
    },
  ]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(6);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentItems = dataForClimateVariableTypeCard.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  //eslint-disable-next-line
  const closeModal = () => {
    setIsVariableSelectionModalOpen(false);
    setIsWindRelatedVariablesModalOpen(true);
  };

  return (
    <CustomModal
      title="Next, select a climate variable to display…"
      isOpen={open}
      onClick={closeModalHandler}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        {currentItems.map((item, i) => {
          const { icon: Icon } = item;

          return (
            <ClimateVariableTypeCard
              key={i}
              onClick={() => {
                setSelectedClimateVariable(item.title);
                setIsVariableSelectionModalOpen(false);
                setIsWindRelatedVariablesModalOpen(true);
              }}
              title={item.title}
              description={`If you are interested in exploring ${item.title.toLowerCase()} projections.`}
              btnBackGroundColor={
                item.title === selectedClimateVariable ? "#ADE5F7" : "#BEBEBE"
              }
              icon={
                <Icon
                  htmlColor="white"
                  style={{
                    fontSize: "3.125rem",
                  }}
                />
              }
              isAvailable={item.isAvailable}
            />
          );
        })}
      </div>

      <Grid container justify="center">
        {dataForClimateVariableTypeCard.length > 6 && (
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={dataForClimateVariableTypeCard.length}
            paginate={paginate}
            currentPageNumber={currentPage}
          />
        )}
      </Grid>
    </CustomModal>
  );
};

export default VariableSelectionModal;
