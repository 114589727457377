import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const DatasetConfigurationIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      width="67"
      height="67"
      viewBox="0 0 67 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M21.775 0C13.4 0 6.7 6.7 6.7 15.075V16.75C2.9815 16.75 0 19.7315 0 23.45V53.6C0 57.3185 2.9815 60.3 6.7 60.3H22.512C27.403 64.6215 33.701 67 40.2 67C47.3078 67 54.1245 64.1764 59.1505 59.1505C64.1764 54.1245 67 47.3078 67 40.2C67 33.0922 64.1764 26.2755 59.1505 21.2495C54.1245 16.2236 47.3078 13.4 40.2 13.4C39.061 13.4 37.922 13.5005 36.85 13.668C36.046 5.9295 29.547 0 21.775 0ZM21.775 6.7C23.9962 6.7 26.1264 7.58236 27.697 9.15298C29.2676 10.7236 30.15 12.8538 30.15 15.075V16.75H13.4V15.075C13.4 12.8538 14.2824 10.7236 15.853 9.15298C17.4236 7.58236 19.5538 6.7 21.775 6.7ZM40.2 20.1C45.5308 20.1 50.6434 22.2177 54.4128 25.9872C58.1823 29.7566 60.3 34.8691 60.3 40.2C60.3 45.5308 58.1823 50.6434 54.4128 54.4128C50.6434 58.1823 45.5308 60.3 40.2 60.3C34.8691 60.3 29.7566 58.1823 25.9872 54.4128C22.2177 50.6434 20.1 45.5308 20.1 40.2C20.1 34.8691 22.2177 29.7566 25.9872 25.9872C29.7566 22.2177 34.8691 20.1 40.2 20.1ZM36.85 26.8V43.55L49.044 50.8865L51.657 46.565L41.875 40.7025V26.8H36.85Z" />
    </SvgIcon>
  );
};

export default DatasetConfigurationIcon;
