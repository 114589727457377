import React from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ReturnVoid } from "../../../types/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: (props: PropsType) => (props.width ? props.width : "368px"),
      // margin: '16px 0'
    },
    heading: {
      fontWeight: 300,
      fontSize: "1.125rem",
      lineHeight: "21px",
      color: "#1C1C1C",
    },
  })
);

type PropsType = {
  title: string | React.ReactNode;
  children: React.ReactNode;
  width?: number | string;
  expanded?: boolean;
  onChange?: ReturnVoid;
};

export default function CollapsibleCard(props: PropsType) {
  const classes = useStyles(props);
  const { title, children, onChange, expanded } = props;

  return (
    <div className={classes.root}>
      <Accordion
        elevation={0}
        expanded={expanded}
        onChange={onChange ? onChange : undefined}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <span>{children}</span>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
