import { makeStyles } from "@material-ui/core";

export const useSigninStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  heading: {
    color: "#fff",
    textTransform: "capitalize",
    marginBottom: "1.5625rem",
    fontSize: "2.425rem",
    lineHeight: "45.47px",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: "21.3987rem",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
    autoComplete: "off",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  circle: {
    height: "45.5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
    position: "relative",
  },
  input: {
    background: "#fff",
    color: "#000",
    borderRadius: "3.1px",
    width: "21.3987rem",
  },
  inputPlaceholder: {
    "&::placeholder": {
      color: "#303030",
      opacity: "1",
      fontSize: "17px",
    },
  },
  link: {
    color: "#CFCFCF",
    fontSize: "0.9681rem",
    display: "inline-block",
  },
}));
