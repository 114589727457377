import { makeStyles } from "@material-ui/core";

export const useTermsOfUseModalStyles = makeStyles({
  imgContainer: {
    width: "15rem",
    height: "15rem",
    background:
      "linear-gradient(180deg, #1873B0 0%, rgba(18, 212, 255, 0.27) 100%)",
    borderRadius: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  bodyText: {
    fontSize: "1.125rem",
    lineHeight: "19.47px",
    color: "#000000",
    maxWidth: "584px",
    display: "inline-block",

    "& a": {
      color: "#000",
    },
  },
});

export type TermsOfUseModalPropTypes = {
  open: boolean;
  closeModalHandler: () => void;
};
