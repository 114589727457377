import React from "react";
import { Card, IconButton, Typography, Tooltip } from "@material-ui/core";

import EditIcon from "../Icons/EditIcon";
import InfoIcon from "../Icons/InfoIcon";

import { SearchBoxPropTypes, useSearchBoxStyles } from "./useStyles";

const SearchBox = (props: SearchBoxPropTypes) => {
  const classes = useSearchBoxStyles(props);

  const { title, children, editClickHandler, infoClickHandler } = props;

  return (
    <Card className={classes.mainCard}>
      {/* ---------------CARD HEADER--------------- */}
      <div className={classes.cardHeader}>
        <Typography className={classes.cardHeaderHeading} variant="body1">
          {title}
        </Typography>
        <div>
          <Tooltip title={`Edit ${title}`}>
            <IconButton onClick={editClickHandler}>
              <EditIcon htmlColor="#000" className={classes.icon} />
            </IconButton>
          </Tooltip>

          {/* <Tooltip title="Open module information">
            <IconButton onClick={infoClickHandler}>
              <InfoIcon htmlColor="#000" className={classes.icon} />
            </IconButton>
          </Tooltip> */}
        </div>
      </div>

      {/* ---------------CARD MAIN CONTENT--------------- */}
      <div>{children}</div>
    </Card>
  );
};

export default SearchBox;
