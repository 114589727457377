import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

const ChevronRightIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    width="6"
    height="12"
    viewBox="0 0 6 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6 5.58613C6 5.48248 5.96317 5.39363 5.8895 5.3196L0.707182 0.111057C0.633517 0.0370197 0.545119 9.29846e-07 0.441988 9.34354e-07C0.338858 9.38862e-07 0.25046 0.0370197 0.176795 0.111057C0.10313 0.185095 0.0662979 0.27394 0.0662979 0.377593C0.0662979 0.481245 0.10313 0.57009 0.176795 0.644128L5.09392 5.58613L0.0994475 10.6059C0.0331491 10.6799 -2.10358e-08 10.7688 -1.6505e-08 10.8724C-1.19742e-08 10.9761 0.0331492 11.0649 0.0994475 11.1389C0.13628 11.176 0.178637 11.2037 0.226519 11.2222C0.274401 11.2407 0.320442 11.25 0.364641 11.25C0.416206 11.25 0.46593 11.2407 0.513812 11.2222C0.561694 11.2037 0.604052 11.176 0.640884 11.1389L5.8895 5.85267C5.96317 5.77863 6 5.68978 6 5.58613Z" />
  </SvgIcon>
);

export default ChevronRightIcon;
