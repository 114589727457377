import React, { useContext } from "react";
import { Avatar, Box, Container, Link } from "@material-ui/core";
import { useNavigation } from "react-navi";

import { useTopbarStyles } from "./useStyles";
import arupNewLogo from "../../../images/arupNewLogo.svg";
import ArupLogo from "../Icons/ArupLogo";
import { LocationSelectContext } from "../context/LocationSelectPageContext";
import Tooltip from "@material-ui/core/Tooltip";
import { APP_VERSION } from "../../../constants";

const Topbar = () => {
  const classes = useTopbarStyles();
  const navigation = useNavigation();

  const {
    climateDataHistoryModalState,
    termsOfUserModalState,
    datasetSelectCardVisibleState,
    handlers,
  } = useContext(LocationSelectContext);

  const str: any = localStorage.getItem("KLIIMA_PROFILE") || {};
  const profileData = JSON.parse(str);
  const avt = profileData.name;
  const avtImg = avt.toUpperCase().substr(0, 2);

  const { setIsClimateDataHistoryModalOpen } = climateDataHistoryModalState;

  const { setIsTermsOfUseModalOpen } = termsOfUserModalState;

  const { closeAllModalsAndPopups } = handlers;

  const { setIsDatasetSelectCardVisible } = datasetSelectCardVisibleState;

  const lougoutNow = () => {
    localStorage.setItem("data_token_tookit", "");
    navigation.navigate("/login?deauth=true");
  };

  const navLinks: Array<{
    linkName: string;
    path: string;
    onClick?: () => any;
  }> = [
    { linkName: "new request", path: "" },
    { linkName: "View History", path: "#history" },
    { linkName: "Terms of Use", path: "#terms-of-use" },
    { linkName: "Support", path: "#" },
    { linkName: profileData.name, path: "#" },
    { linkName: "Logout", path: "" },
  ];

  const linkClickHandler = (index: number) => {
    switch (index) {
      case 0:
        window.location.reload();
        break;

      case 1:
        closeAllModalsAndPopups();
        setIsDatasetSelectCardVisible(false);
        setIsClimateDataHistoryModalOpen(true);
        break;

      case 2:
        closeAllModalsAndPopups();
        setIsTermsOfUseModalOpen(true);
        break;

      case 3:
        window.open(
          "https://arup.sharepoint.com/sites/Kliima/SitePages/Kliima-Team.aspx"
        );
        break;

      case navLinks.length - 1:
        lougoutNow();
        break;

      default:
        break;
    }
  };

  return (
    <>
      <nav className={classes.nav} style={{ position: "fixed" }}>
        <Container className={classes.contentContainer}>
          <Box display="flex" alignItems="center">
            <img
              src={arupNewLogo}
              alt="aup-logo"
              onClick={() =>
                window.open(
                  "https://arup.sharepoint.com/sites/Kliima/",
                  "_blank"
                )
              }
              style={{ height: "21px", cursor: "pointer", marginRight: "8px" }}
            />

            <p className={classes.navLinks}>{APP_VERSION}</p>
          </Box>

          <div style={{ height: "100%" }}>
            {navLinks.map((item, i) => (
              <span
                key={i}
                className={classes.linkContainer}
                style={{
                  paddingRight: i === navLinks.length - 1 ? 0 : "",
                }}
              >
                <Link
                  href={item.path}
                  className={classes.navLinks}
                  style={{
                    fontWeight: i === navLinks.length - 1 ? 500 : "normal",
                    textDecoration: i === 4 ? "none" : undefined,
                    cursor: i === 4 ? "initial" : undefined,
                  }}
                  onClick={() => linkClickHandler(i)}
                >
                  {item.linkName}
                </Link>

                {i === navLinks.length - 2 && (
                  <>
                    <Avatar
                      className={classes.avatar}
                      style={{ marginLeft: "8px", cursor: "initial" }}
                    >
                      {avtImg}
                    </Avatar>
                  </>
                )}
              </span>
            ))}

            <Tooltip title="Go to arup.com">
              <span>
                <ArupLogo
                  onClick={() => window.open("https://www.arup.com")}
                  style={{
                    width: "42px",
                    height: "12px",
                    marginBottom: "-1.5px",
                    marginLeft: "10px",
                    cursor: "pointer",
                  }}
                />
              </span>
            </Tooltip>
          </div>
        </Container>
      </nav>
      <div style={{ width: "100%", height: "32px" }}></div>
    </>
  );
};

export default Topbar;
