import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const CloseIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.05528 8.57143C8.2027 8.57143 8.32906 8.51881 8.43435 8.41358L15.8421 1.01026C15.9474 0.905025 16 0.778742 16 0.631413C16 0.484083 15.9474 0.357801 15.8421 0.252565C15.7368 0.14733 15.6104 0.0947119 15.463 0.0947119C15.3156 0.0947119 15.1892 0.14733 15.0839 0.252565L8.05528 7.27703L0.916091 0.142068C0.810793 0.047356 0.684436 0 0.537019 0C0.389602 0 0.263245 0.047356 0.157947 0.142068C0.105298 0.194686 0.0658111 0.255196 0.0394867 0.323599C0.0131622 0.392002 0 0.457774 0 0.520916C0 0.59458 0.0131622 0.665614 0.0394867 0.734017C0.0658111 0.80242 0.105298 0.862931 0.157947 0.915549L7.67621 8.41358C7.78151 8.51881 7.90787 8.57143 8.05528 8.57143Z" />
      <path d="M7.94472 7.42857C7.7973 7.42857 7.67094 7.48119 7.56565 7.58642L0.157947 14.9897C0.0526486 15.095 6.80798e-08 15.2213 5.51999e-08 15.3686C4.23199e-08 15.5159 0.0526486 15.6422 0.157947 15.7474C0.263244 15.8527 0.389602 15.9053 0.537018 15.9053C0.684435 15.9053 0.810792 15.8527 0.91609 15.7474L7.94472 8.72297L15.0839 15.8579C15.1892 15.9526 15.3156 16 15.463 16C15.6104 16 15.7368 15.9526 15.8421 15.8579C15.8947 15.8053 15.9342 15.7448 15.9605 15.6764C15.9868 15.608 16 15.5422 16 15.4791C16 15.4054 15.9868 15.3344 15.9605 15.266C15.9342 15.1976 15.8947 15.1371 15.8421 15.0845L8.32379 7.58642C8.21849 7.48119 8.09214 7.42857 7.94472 7.42857Z" />
    </SvgIcon>
  );
};

export default CloseIcon;
