
import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#7CD4F2",
    },
    secondary: {
      main: "#FA9B1E",
    },
    warning: {
      main: "#FA9B1E",
    },

    error: {
      main: "#F40808",
    },
  },
  typography: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    h1: {
      fontSize: "6rem",
      lineHeight: 1.167,
      letterSpacing: "-0.01562em",
    },
  },
  spacing: 8,
  shape: {
    borderRadius: 0,
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 20,
        height: 32,
        color: "#1C1C1C",
        textTransform: "capitalize",
        fontWeight: "normal",
      },
    },
  },
});

export default theme;
