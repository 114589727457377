import React, { createContext, useState } from "react";
import { LocationSelectPageContextType } from "../../../types/LocationSelectPageContextTypes";
import { CORDEX } from "../Pages/LocationSelectPage/datasets";

export const LocationSelectContext = createContext<LocationSelectPageContextType>(
  {} as LocationSelectPageContextType
);

type PropsType = {
  children: React.ReactNode;
};

export const LocationSelectPageContextProvider = ({ children }: PropsType) => {
  const [
    isWelcomeToTheArupModalOpen,
    setIsWelcomeToTheArupModalOpen,
  ] = useState<boolean>(true);

  const [isDatasetSelectCardVisible, setIsDatasetSelectCardVisible] = useState(
    true
  );

  const [
    isSelectLocationCardVisible,
    setIsSelectLocationCardVisible,
  ] = useState(false);

  const [selectedSearchType, setSelectedSearchType] = useState<string>("");

  const [
    isVariableSelectionModalOpen,
    setIsVariableSelectionModalOpen,
  ] = useState<boolean>(false);

  const [
    isSelectClimateVariableCardVisible,
    setIsSelectClimateVariableCardVisible,
  ] = useState<boolean>(false);

  const [
    selectedClimateVariable,
    setSelectedClimateVariable,
  ] = useState<string>("");

  const [
    isWindRelatedVariablesModalOpen,
    setIsWindRelatedVariablesModalOpen,
  ] = useState<boolean>(false);

  const [
    isConfigureDatasetVisible,
    setIsConfigureDatasetVisible,
  ] = useState<boolean>(false);

  const [
    isDatasetSelectionModalOpen,
    setIsDatasetSelectionModalOpen,
  ] = useState<boolean>(false);

  const [
    isClimateDataHistoryModalOpen,
    setIsClimateDataHistoryModalOpen,
  ] = useState<boolean>(false);

  const [
    isProjectDescriptionModalOpen,
    setIsProjectDescriptionModalOpen,
  ] = useState(false);

  const [isClimateDatasetModalOpen, setIsClimateDatasetModalOpen] = useState(
    false
  );

  const [timeDuration, setTimeDuration] = useState("");

  const [isTermsOfUseModalOpen, setIsTermsOfUseModalOpen] = useState(false);

  const [selectedDataset, setSelectedDataset] = useState<string>("");

  const [
    selectedClimateVariableSource,
    setSelectedClimateVariableSource,
  ] = useState<string>("");

  const [isProjectDataDpdOpen, setIsProjectDataDpdOpen] = useState(false);

  const [isRegionDpdOpen, setIsRegionDpdOpen] = useState(false);

  const [
    doesEachFieldOfConfigDatasetCardHasValue,
    setDoesEachFieldOfConfigDatasetCardHasValue,
  ] = useState(false);
  const [state, setState] = useState({
    metric: "",
  });

  const [
    configureDatasetHistoricalData,
    setConfigureDatasetHistoricalData,
  ] = useState({
    dataset: "",
    from: "",
    projection: "",
  });

  const [lessThanMoreThanValues, setLessThanMoreThanValues] = useState({
    thresholdValueLessThan: null,
    thresholdValueMoreThan: null,
  });

  const [isOtherToolCardVisibile, setIsOtherToolCardVisibile] = useState(true);

  const [
    shouldFocusOnHistoryProjectionDpd,
    setShouldFocusOnHistoryProjectionDpd,
  ] = useState(false);

  const [
    isHistoryViewDetailsDialogOpen,
    setIsHistoryViewDetailsDialogOpen,
  ] = useState(false);

  const [timePeriodSelectionModal, setTimePeriodSelectionModal] = useState(
    false
  );
  const [selectedProjectedYear, setSelectedProjectedYear] = useState("");

  const [selectedResolution, setSelectedResolution] = useState("");
  const [isResolutionSelected, setIsResolutionSelected] = useState(true);

  const [historicalYear, setHistoricalYear] = useState({
    from: "",
    to: "",
  });
  const [projectedYear, setProjectedYear] = useState({
    from: "",
    to: "",
  });

  const [timeDurationCtx, setTimeDurationCtx] = useState(0);
  const [yearDurationCtx, setYearDurationCtx] = useState(0);

  const [retriveData, setRetriveData] = useState({
    region: {
      name: "Europe",
      lat: [53.4563],
      lon: [2.3456],
    },
    country: "london",
    project_name: "project",
    job_code: "37291",
    primary_key: 12,
    threshold_value_less_than: null,
    threshold_value_more_than: null,
    main_variable: "Temerature",
    variable: ["dummy"],
    datasource: CORDEX,
    from_date: 2006,
    to_date: 2010,
  });

  const closeAllModalsAndPopups = () => {
    setIsWelcomeToTheArupModalOpen(false);
    setIsVariableSelectionModalOpen(false);
    setIsSelectClimateVariableCardVisible(false);
    setIsWindRelatedVariablesModalOpen(false);
    setIsConfigureDatasetVisible(false);
    setIsDatasetSelectionModalOpen(false);
    setIsClimateDataHistoryModalOpen(false);
    setIsProjectDescriptionModalOpen(false);
    setIsClimateDatasetModalOpen(false);
    setIsOtherToolCardVisibile(false);
    setIsSelectLocationCardVisible(false);
    setIsHistoryViewDetailsDialogOpen(false);
    setIsTermsOfUseModalOpen(false);
    setTimePeriodSelectionModal(false);
  };

  const closeModalsHandler = () => {
    setIsConfigureDatasetVisible(false);
    setIsSelectClimateVariableCardVisible(false);
    setIsProjectDescriptionModalOpen(false);
    setIsVariableSelectionModalOpen(false);
    setIsWindRelatedVariablesModalOpen(false);
    setIsClimateDatasetModalOpen(false);
    setIsDatasetSelectionModalOpen(false);
  };

  return (
    <LocationSelectContext.Provider
      value={{
        timeDurationDropDown: {
          timeDuration,
          setTimeDuration,
        },
        configureDatasetHistoricalDataState: {
          state: configureDatasetHistoricalData,
          setState: setConfigureDatasetHistoricalData,
        },
        selectedProjectedYearState: {
          selectedProjectedYear,
          setSelectedProjectedYear,
        },
        metricState: {
          state,
          setState,
        },
        retrivableData: [retriveData, setRetriveData],
        timeDurationState: [timeDurationCtx, setTimeDurationCtx],
        yearDurationState: [yearDurationCtx, setYearDurationCtx],
        selectedResolutionState: {
          selectedResolution,
          setSelectedResolution,
        },
        resolutionErrorState: {
          isResolutionSelected,
          setIsResolutionSelected,
        },
        welcomeToTheArupModalState: {
          isWelcomeToTheArupModalOpen,
          setIsWelcomeToTheArupModalOpen,
        },

        selectLocationCardVisibleState: {
          isSelectLocationCardVisible,
          setIsSelectLocationCardVisible,
        },
        datasetSelectCardVisibleState: {
          isDatasetSelectCardVisible,
          setIsDatasetSelectCardVisible,
        },
        selectedSearchTypeState: {
          selectedSearchType,
          setSelectedSearchType,
        },

        variableSelectionModalState: {
          isVariableSelectionModalOpen,
          setIsVariableSelectionModalOpen,
        },

        selectClimateVariableCardVisibleState: {
          isSelectClimateVariableCardVisible,
          setIsSelectClimateVariableCardVisible,
        },

        selectedClimateVariableState: {
          selectedClimateVariable,
          setSelectedClimateVariable,
        },

        windRelatedVariablesModalState: {
          isWindRelatedVariablesModalOpen,
          setIsWindRelatedVariablesModalOpen,
        },

        configureDatasetVisibleState: {
          isConfigureDatasetVisible,
          setIsConfigureDatasetVisible,
        },

        datasetSelectionModalState: {
          isDatasetSelectionModalOpen,
          setIsDatasetSelectionModalOpen,
        },

        selectedDatasetState: {
          selectedDataset,
          setSelectedDataset,
        },

        selectedClimateVariableSourceState: {
          selectedClimateVariableSource,
          setSelectedClimateVariableSource,
        },

        climateDataHistoryModalState: {
          isClimateDataHistoryModalOpen,
          setIsClimateDataHistoryModalOpen,
        },

        projectDescriptionModalState: {
          isProjectDescriptionModalOpen,
          setIsProjectDescriptionModalOpen,
        },

        projectDataDpdOpenState: {
          isProjectDataDpdOpen,
          setIsProjectDataDpdOpen,
        },

        regionDpdOpenState: {
          isRegionDpdOpen,
          setIsRegionDpdOpen,
        },

        climateDatasetModalState: {
          isClimateDatasetModalOpen,
          setIsClimateDatasetModalOpen,
        },

        lessThanMoreThanValuesState: {
          lessThanMoreThanValues,
          setLessThanMoreThanValues,
        },

        otherToolCardVisibileState: {
          isOtherToolCardVisibile,
          setIsOtherToolCardVisibile,
        },

        timePeriodSelectionModalState: {
          timePeriodSelectionModal,
          setTimePeriodSelectionModal,
        },

        focusOnHistoryProjectionDpdState: {
          shouldFocusOnHistoryProjectionDpd,
          setShouldFocusOnHistoryProjectionDpd,
        },

        historyDialogState: {
          isHistoryViewDetailsDialogOpen,
          setIsHistoryViewDetailsDialogOpen,
        },

        doesEachFieldOfConfigDatasetCardHasValueState: {
          doesEachFieldOfConfigDatasetCardHasValue,
          setDoesEachFieldOfConfigDatasetCardHasValue,
        },

        historicalYearState: {
          historicalYear,
          setHistoricalYear,
        },

        projectedYearState: { projectedYear, setProjectedYear },

        termsOfUserModalState: {
          isTermsOfUseModalOpen,
          setIsTermsOfUseModalOpen,
        },

        handlers: {
          closeAllModalsAndPopups,
          closeModalsHandler,
        },
      }}
    >
      {children}
    </LocationSelectContext.Provider>
  );
};
