import { makeStyles } from "@material-ui/core";

export const useTopbarStyles = makeStyles({
  nav: {
    width: "100%",
    height: "32px",
    background: "#1C1C1C",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    zIndex: 4,
  },
  contentContainer: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  navHeading: {
    fontSize: "0.75rem",
    fontWeight: 500,
  },
  navLinks: {
    fontSize: "0.75rem",
    lineHeight: "14px",
    color: "#fff",
    textTransform: "capitalize",
  },
  linkContainer: {
    display: "inline-flex",
    padding: "0 15px",
    height: "100%",
    alignItems: "center",
    borderLeft: "1px solid #767676",
  },
  avatar: {
    color: "#000",
    width: "22.51px",
    height: "22.51px",
    fontSize: "0.625rem",
    fontWeight: "bold",
    background: "linear-gradient(154.16deg, #3FDCFF 24.52%, #0265C0 83.68%)",
    cursor: "pointer",
  },
});
