import React from "react";

import { PaginationPropTypes, usePaginationStyles } from "./useStyles";

const Pagination = (props: PaginationPropTypes) => {
  const classes = usePaginationStyles();

  const { itemsPerPage, totalItems, paginate, currentPageNumber } = props;

  const pageNumbers: number[] = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <>
      {pageNumbers.map((number) => (
        <span
          key={number}
          onClick={() => paginate(number)}
          style={{ background: currentPageNumber === number ? "#767676" : "" }}
          className={classes.indicator}
        ></span>
      ))}
    </>
  );
};

export default Pagination;
