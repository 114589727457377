import React from "react";

import ErrImg from "../../../../images/error.jpg";

const NotFound = () => {
  return (
    <div className="not-found-page ">
      <div className="errContainer">
        <div
          className="errBlock"
          style={{ fontFamily: "'Roboto', sans-serif" }}
        >
          {"The page you're looking can't be found..."}
        </div>
        <div className="errBlock">
          <img src={ErrImg} alt="404" />
        </div>
        <div
          className="errBlock"
          style={{ fontFamily: "'Roboto', sans-serif" }}
        >
          Click <a href="/">here</a> to search again...
        </div>
        <div>
          <a
            href="kliimasupport@arup.com"
            style={{ fontFamily: "'Roboto', sans-serif" }}
          >
            kliimasupport@arup.com
          </a>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
