import React from "react";
import { Typography } from "@material-ui/core";

import {
  ReviewDatasetCardPropTypes,
  useReviewDatasetCardStyles,
} from "./useStyles";

const ReviewDatasetCard = (props: ReviewDatasetCardPropTypes) => {
  const classes = useReviewDatasetCardStyles();

  const { title, description, icon, iconPath } = props;

  const vector = () => {
    if (icon) {
      return icon;
    }
    return (
      <img
        src={iconPath}
        alt={title}
        style={{
         
          height: "4rem",
        }}
      />
    );
  };

  return (
    <div>
      <div className={classes.imgContainer}>{vector()}</div>
      <div>
        <Typography variant="h6" className={classes.heading}>
          {title}
        </Typography>
        <Typography variant="body1" className={classes.description}>
          {description}
        </Typography>
      </div>
    </div>
  );
};

export default ReviewDatasetCard;
