import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const ArupLogo = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      width="82"
      height="26"
      viewBox="0 0 82 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M52.7582 23.9402C48.1879 23.9402 46.2074 21.8753 45.4343 20.6451C44.5499 19.2209 44.4355 17.2928 44.4355 15.7709V5.81633C44.4355 5.72009 44.437 5.62383 44.4385 5.52459C44.4551 4.48687 44.4717 3.5063 42.8596 3.45517H42.3466V2.625H50.6108L50.6166 3.46119L50.4694 3.45968C49.8528 3.45366 49.3834 3.61759 49.0496 3.94695C48.6542 4.33949 48.4527 4.96813 48.4527 5.81633V16.7664C48.4527 20.4736 50.0647 22.3535 53.2441 22.3535C55.0428 22.3535 56.5558 21.8227 57.5018 20.8586C59.0311 19.278 59.1532 17.2297 59.1532 14.7151V5.78627C59.1532 4.46582 59.0222 3.45667 57.3678 3.45667H56.9377V2.625H63.0333V3.45667H62.7759C61.5549 3.45667 60.8481 3.83717 60.8481 5.78627V14.867C60.8493 20.9729 58.2025 23.9402 52.7582 23.9402Z"
          fill="#FAFAFA"
        />
        <path
          d="M73.2208 23.4981H64.949V22.6709L65.0889 22.6664C67.0277 22.6107 67.1268 21.9911 67.1268 20.2105V5.8043C67.1268 3.95146 67.0547 3.51232 65.1702 3.45667L65.0303 3.45216V2.625H73.469C78.4543 2.625 81.0622 5.54714 81.0622 8.43317C81.0622 11.7358 78.1175 14.3226 74.3592 14.3226L72.4974 14.2955V12.8337C72.4974 12.8337 73.2027 12.8262 73.4598 12.8262C75.7728 12.8262 76.9625 11.0771 76.9625 8.47378C76.9625 5.76218 75.224 3.93943 72.6373 3.93943L70.974 3.93492V20.5458C70.974 22.2904 71.2897 22.6062 73.0824 22.6649L73.2223 22.6694V23.4981H73.2208Z"
          fill="#FAFAFA"
        />
        <path
          d="M42.8819 23.4981H36.9219L30.5453 13.8007V12.6171L30.6746 12.6036C32.9109 12.3675 35.0104 11.0801 35.0104 8.17299C35.0104 5.73362 33.2554 4.03119 30.7423 4.03119H29.0218V20.5458C29.0218 22.3445 29.8474 22.5235 31.0145 22.5776L31.1512 22.5837V23.4966H23.0647V22.6573L23.2016 22.6498C24.9552 22.5521 25.2033 22.1129 25.2033 20.5443V5.5757C25.1386 3.72287 25.0243 3.48525 23.2076 3.45667L23.0662 3.45517V2.625H31.777C35.0991 2.625 38.9792 4.0853 38.9792 8.20309C38.9792 10.6575 37.4919 12.4186 34.5561 13.4398L37.865 18.3245C38.2257 18.878 39.5522 20.4872 40.1464 21.1429C41.2787 22.3611 42.0938 22.5295 42.6337 22.6408L42.8819 22.6935V23.4981Z"
          fill="#FAFAFA"
        />
        <path
          d="M21.6283 23.498H13.7372V22.6829L13.8771 22.6784C14.8411 22.6453 15.4381 22.4348 15.4381 21.4933C15.4381 21.0722 15.1088 20.2481 14.9313 19.8044L13.6485 16.7364H6.52134L5.21142 19.7202C4.77678 20.7052 4.65045 21.0617 4.65045 21.5234C4.65045 22.2017 4.65045 22.6498 6.23708 22.6784L6.37848 22.6814V23.4995H0.687592V22.6829L0.828962 22.6799C1.95691 22.6543 2.54344 22.0813 3.34052 20.227L11.0602 2.625H11.8528L11.8904 2.71223L19.6326 20.9429C20.1906 22.3009 20.3229 22.6228 21.4915 22.6709L21.6298 22.6769V23.498H21.6283ZM7.10636 15.3242H13.0709L10.1548 8.24971L7.10636 15.3242Z"
          fill="#FAFAFA"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="80.9881"
            height="25.1554"
            fill="white"
            transform="translate(0.380859 0.785156)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default ArupLogo;
