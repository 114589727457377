import React, { Fragment } from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";

import noaaLocaLogo from "../../../../../images/noaa.svg";
import cmip6Logo from "../../../../../images/cmip6.svg";
import cordexLogo from "../../../../../images/cordex.png";
import ukpc18Logo from "../../../../../images/ukpc18.png";
import hadUk from "../../../../../images/hadUk.png";
import { ModelApiResponseType, ModelsEntity } from "../../../../../types/types";

const useStyles = makeStyles({
  heading: {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "15.15px",
  },

  body: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "15.15px",
  },

  sideBySideText: {
    display: "flex",

    "& p": {
      marginLeft: "5px",
    },
  },

  tableContainer: {
    "& table": {
      borderCollapse: "collapse",
      width: "100%",
    },

    "& td, th ": {
      border: "1px solid #dddddd",
      textAlign: "left",
      padding: "8px",
    },
  },

  logo: { height: "9.375rem" },
});

type PropsType = {
  data: ModelApiResponseType;
};

export const CordexContent = (props: PropsType) => {
  const classes = useStyles();

  const { data } = props;

  return (
    <>
      <img
        src={cordexLogo}
        className={classes.logo}
        style={{ width: "11.4375rem" }}
        alt="cordex-dataset-logo"
      />
      <br />
      <div>
        <Typography variant="h6" className={classes.heading}>
          CORDEX
        </Typography>
        <a href="https://cordex.org/" target="_bland" className={classes.body}>
          https://cordex.org/
        </a>
      </div>

      <br />
      <div className={classes.sideBySideText}>
        <Typography variant="h6" className={classes.heading}>
          DATA LAST UPDATED IN KLIIMA
          <br />
          <span style={{ fontWeight: "normal" }}>January 2023</span>
        </Typography>
        <Typography variant="body1" className={classes.body}>
          {data.last_updated}
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          BACKGROUND
        </Typography>

        <br />
        <Typography variant="body1" className={classes.body}>
          The COordinated Regional climate Downscaling EXperiment (CORDEX)
          dataset are a set of Regional Climate Models
          <a
            href="https://arup.sharepoint.com/sites/Kliima/SitePages/FAQs.aspx"
            target="_blank"
            rel="noreferrer"
          >
            RCMs
          </a>
          . It is endorsed by the World Climate Research Programme and is an
          international collaboration between scientific institutions. CORDEX
          data in Kliima is based on
          <a
            href="https://arup.sharepoint.com/sites/Kliima/SitePages/Technical-information.aspx"
            target="_blank"
            rel="noreferrer"
          >
            CMIP5
          </a>
          models. An updated CORDEX dataset from
          <a
            href="https://arup.sharepoint.com/sites/Kliima/SitePages/Technical-information.aspx"
            target="_blank"
            rel="noreferrer"
          >
            CMIP6
          </a>
          models is expected towards the end of 2024.
        </Typography>
      </div>

      <br />

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          Emission Scenarios:
          <br />
          <p style={{ fontWeight: "normal" }}>
            RCP 4.5 (Medium)
            <br />
            RCP 8.5 (High)
          </p>
        </Typography>
        <Typography variant="body1" className={classes.body}>
          {data.covered_emission_scenarios?.map((item, i) => (
            <Fragment key={`${item}-${i}`}>
              {item} <br />
            </Fragment>
          ))}
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          Spatial Resolution:
          <br />
          <p style={{ fontWeight: "normal" }}>
            0.22 (~25km)
            <br />
            0.44 (~50km)
          </p>
        </Typography>
        <Typography variant="body1" className={classes.body}>
          {data.spatial_resolution?.map((item, i) => (
            <Fragment key={`${item}-${i}`}>
              {item} <br />
            </Fragment>
          ))}
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          Temporal Resolution:
          <br />
          <p style={{ fontWeight: "normal" }}>Daily</p>
        </Typography>
        <Typography variant="body1" className={classes.body}>
          {data.temporal_resolution}
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          Time Period Coverage:
          <br />
          <p style={{ fontWeight: "normal" }}>
            Historical (1976-2005)
            <br />
            Future (2010-2100)
          </p>
        </Typography>
        <Typography variant="body1" className={classes.body}>
          {data.time_period_coverage?.map((item, i) => (
            <Fragment key={`${item}-${i}`}>
              {item} <br />
            </Fragment>
          ))}
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          MODELS
          <br />
          <p style={{ fontWeight: "normal" }}>
            For a list of models for each region, please visit the Kliima
            Sharepoint page.
          </p>
        </Typography>{" "}
        <br />
        {/* <Typography
          variant="body1"
          className={classes.body}
          style={{ color: "red" }}
        >
          Model Details <br />
          {data.model_table?.model_details?.map((item, i) => {
            return (
              <span key={`${item.region}-${i}`}>
                {item.model_count},{" "}
                <span style={{ color: "black" }}>Region</span>: {item.region}{" "}
                <br />
              </span>
            );
          })}
        </Typography> */}
        <br />
        <div className={classes.tableContainer}>
          <table>
            <thead>
              <tr>
                {data.model_table?.headers?.map((e, i) => (
                  <th key={`${e}-${i}`}>
                    <Typography variant="h6" className={classes.heading}>
                      {e}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {/* {data.model_table?.rows?.map((item, i) => (
                <tr key={`${item}-${i}`}>
                  <td>
                    <Typography variant="body1" className={classes.body}>
                      {item.institution}
                    </Typography>
                  </td>
                  <td>
                    <Typography variant="body1" className={classes.body}>
                      {item.gcmDrivingModel}
                    </Typography>
                  </td>
                  <td>
                    <Typography variant="body1" className={classes.body}>
                      {item.rcmModel}
                    </Typography>
                  </td>
                </tr>
              ))} */}
              {data.model_table?.rows?.map((item, i) => {
                return (
                  <Fragment key={`${item.region}-${i}`}>
                    <tr>
                      <td colSpan={3} style={{ textAlign: "center" }}>
                        {item.region}
                      </td>
                    </tr>
                    {/* @ts-ignore */}
                    {item.models?.length > 0 ? (
                      item.models?.map((item: ModelsEntity, i) => (
                        <tr key={`${item}-${i}`}>
                          <td>
                            <Typography
                              variant="body1"
                              className={classes.body}
                            >
                              {item.institution}
                            </Typography>
                          </td>
                          <td>
                            <Typography
                              variant="body1"
                              className={classes.body}
                            >
                              {item.gcmDrivingModel}
                            </Typography>
                          </td>
                          <td>
                            <Typography
                              variant="body1"
                              className={classes.body}
                            >
                              {item.rcmModel}
                            </Typography>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={3} style={{ textAlign: "center" }}>
                          No Models are available for this {item.region} region.
                        </td>
                      </tr>
                    )}
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <br />
      <div>
        <Typography variant="h6" className={classes.heading}>
          Arup Notes
        </Typography>
        <Typography variant="body1" className={classes.body}>
          Benefits: <br />
          - Global coverage <br />
          - Regional models at finer resolution <br />- RCMs - more suitable for
          capturing local effects and extreme events
        </Typography>
        <br />
        <Typography variant="body1" className={classes.body}>
          Limitations: <br />- Inconsistent models when comparing datasets from
          different regions <br />
          - Limited number of models in some regions <br />- RCP 4.5 only
          available for courser resolution <br />- For some regions, RCP 4.5 is
          only available for more course resolutions
        </Typography>
      </div>
      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          FURTHER CORDEX PRODUCTS
        </Typography>
        <Typography variant="body1" className={classes.body}>
          A selection of CORDEX models are available at hourly resolution. If
          you would like more information please contact{" "}
          <a style={{color:"#57A0D2"}} href="https://arup.sharepoint.com/sites/Kliima/SitePages/Kliima-Team.aspx">
            {" "}
            Kliima Sharepoint
          </a>
        </Typography>
      </div>

      <br />
      <div>
        <Typography variant="h6" className={classes.heading}>
          REFERENCE &amp; CITATION
        </Typography>

        <Typography variant="body1" className={classes.body}>
          We acknowledge the World Climate Research Programme&apos;s Working
          Group on Regional Climate, and the Working Group on Coupled Modelling,
          former coordinating body of CORDEX and responsible panel for CMIP5. We
          also thank the climate modelling groups for producing and making
          available their model output. We also acknowledge the Earth System
          Grid Federation infrastructure, an international effort led by the
          U.S. Department of Energy&apos;s Program for Climate Model Diagnosis
          and Intercomparison, the European Network for Earth System Modelling
          and other partners in the Global Organisation for Earth System Science
          Portals (GO-ESSP).
        </Typography>
      </div>
    </>
  );
};

export const Ukcp18ProbabilisticContent = () => {
  const classes = useStyles();

  return (
    <>
      <img
        src={ukpc18Logo}
        className={classes.logo}
        style={{ height: "11.4375rem" }}
        alt="ukpc18-dataset-logo"
      />

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          UKCP18 Probabilistic (Average Trends)
        </Typography>
        <a
          href="https://www.metoffice.gov.uk/research/approach/collaboration/ukcp/index"
          target="_blank"
          className={classes.body}
          rel="noreferrer"
        >
          https://www.metoffice.gov.uk/research/approach/collaboration/ukcp/index
        </a>
      </div>

      <br />
      <div className={classes.sideBySideText}>
        <Typography variant="h6" className={classes.heading}>
          DATA LAST UPDATED IN KLIIMA :
        </Typography>
        <Typography variant="body1" className={classes.body}>
          January 2023
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          BACKGROUND
        </Typography>

        <br />
        <Typography variant="body1" className={classes.body}>
          The UK Climate Projections (UKCP) are the most up-to-date assessment
          of how the UK climate may change in the future. The projections form
          part of the Met Office Hadley Centre Climate Programme. The Department
          of Business, Energy and Industrial Strategy (BEIS) and the Department
          for Environment, Food and Rural Affairs (Defra) support the UKCP.
        </Typography>
      </div>

      <br />

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          Emission Scenarios:
        </Typography>
        <Typography variant="body1" className={classes.body}>
          RCP 2.6 (Low) <br />
          RCP 4.5 (Medium) <br />
          ECP 6.0 (Medium) <br />
          RCP 8.5 (High)
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          Spatial Resolution:
        </Typography>
        <Typography variant="body1" className={classes.body}>
          25km
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          Temporal Resolution:
        </Typography>
        <Typography variant="body1" className={classes.body}>
          Annual, seasonal changes
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          Time Period Coverage:
        </Typography>
        <Typography variant="body1" className={classes.body}>
          Historical (1981-2010) <br />
          Future (2010-2099)
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          MODELS
        </Typography>
        <br />
        <Typography variant="body1" className={classes.body}>
          3000 samples
        </Typography>
        <br />
      </div>

      <br />
      <div>
        <Typography variant="h6" className={classes.heading}>
          Arup Notes
        </Typography>
        <Typography variant="body1" className={classes.body}>
          Benefits: <br />
          - Latest projections for the UK from the MetOffice <br />- Based on
          UKCP09 statistical methodology <br /> - Represents full range of
          uncertainty
        </Typography>
        <br />
        <Typography variant="body1" className={classes.body}>
          Limitations: <br />- Unable to extract information on extreme
          <br /> - Only covers UK
        </Typography>
      </div>
      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          FURTHER UKCP18 PRODUCTS
        </Typography>
        <br />
        <Typography variant="body1" className={classes.body}>
          High-resolution projections for extremes based on daily timeseries
          data is also available in Kliima. Values for the probabilistic
          projections of climate extremes (PPCE) for three extreme variables:
          maximum temperature, 1-day precipitation and 5-day precipitation
          across all seasons and three return periods (1 in 20-years, 1 in
          50-years and 1 in 100-years). If you would like more information
          please contact KliimaSupport@arup.com Marine Projections including Sea
          Level Rise are available on the UKCP18 User Interface:
          <a
            href="https://ukclimateprojections-ui.metoffice.gov.uk/ui/home"
            target="_blank"
            rel="noreferrer"
          >
            https://ukclimateprojections-ui.metoffice.gov.uk/ui/home
          </a>
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          USEFUL SOURCES
        </Typography>
        <Typography variant="h6" className={classes.heading}>
          UKCP18 Science Overview Report
          <a
            href="https://www.metoffice.gov.uk/pub/data/weather/uk/ukcp18/science-reports/UKCP18-Overview-report.pdf"
            target="_blank"
            rel="noreferrer"
          >
            https://www.metoffice.gov.uk/pub/data/weather/uk/ukcp18/science-reports/UKCP18-Overview-report.pdf
          </a>
        </Typography>
        <br />
        {/* <Typography variant="h6" className={classes.heading}>
          UKCP18 Science Overview Report
        </Typography>

        <Typography variant="body1" className={classes.body}>
          https://www.metoffice.gov.uk/pub/data/weather/uk/ukcp18/science-reports/UKCP18-Overview-report.pdf
        </Typography> */}
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          REFERENCE &amp; CITATION
        </Typography>

        <Typography variant="body1" className={classes.body}>
          All data is provided under the Open Government Licence. Use of these
          data is covered by:
          <a
            href="http://www.nationalarchives.gov.uk/doc/open-government-licence/version/3/"
            target="_blank"
            rel="noreferrer"
          >
            http://www.nationalarchives.gov.uk/doc/open-government-licence/version/3/
          </a>
          Met Office Hadley Centre (2018): UKCP18 Probabilistic Climate
          Projections. Centre for Environmental Data Analysis,date of citation.{" "}
          <a
            href="http://catalogue.ceda.ac.uk/uuid/9842e395f2d04f48a177c3550756bf98"
            target="_blank"
            rel="noreferrer"
          >
            http://catalogue.ceda.ac.uk/uuid/9842e395f2d04f48a177c3550756bf98
          </a>
        </Typography>
      </div>
    </>
  );
};

export const Ukcp18HighResolutionContent = () => {
  const classes = useStyles();

  return (
    <>
      <img
        src={ukpc18Logo}
        className={classes.logo}
        style={{ height: "11.4375rem" }}
        alt="ukpc18-dataset-logo"
      />

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          UKCP18 High Resolution Projections (Extremes)
        </Typography>
        <a
          href="https://www.metoffice.gov.uk/research/approach/collaboration/ukcp/science/high-res-projections"
          target="_blank"
          className={classes.body}
          rel="noreferrer"
        >
          https://www.metoffice.gov.uk/research/approach/collaboration/ukcp/science/high-res-projections
        </a>
      </div>

      <br />
      <div className={classes.sideBySideText}>
        <Typography variant="h6" className={classes.heading}>
          DATA LAST UPDATED IN KLIIMA :
        </Typography>
        <Typography variant="body1" className={classes.body}>
          January 2023
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          BACKGROUND
        </Typography>

        <br />
        <Typography variant="body1" className={classes.body}>
          The UK Climate Projections (UKCP) are the most up-to-date assessment
          of how the UK climate may change in the future. The projections form
          part of the Met Office Hadley Centre Climate Programme. The Department
          of Business, Energy and Industrial Strategy (BEIS) and the Department
          for Environment, Food and Rural Affairs (Defra) support the UKCP.
        </Typography>
      </div>

      <br />

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          Emission Scenarios:
        </Typography>
        <Typography variant="body1" className={classes.body}>
          RCP 8.5 (High)
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          Spatial Resolution:
        </Typography>
        <Typography variant="body1" className={classes.body}>
          12km (Regional) and 5km (Local)
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          Temporal Resolution:
        </Typography>
        <Typography variant="body1" className={classes.body}>
          Daily
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          Time Period Coverage:
        </Typography>
        <Typography variant="body1" className={classes.body}>
          Historical (1981-2010) <br />
          Future (2010-2079)
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          MODELS
        </Typography>
        <br />
        <Typography variant="body1" className={classes.body}>
          12 ensembles based on one Regional Climate Model
        </Typography>
        <br />
      </div>

      <br />
      <div>
        <Typography variant="h6" className={classes.heading}>
          Arup Notes
        </Typography>
        <Typography variant="body1" className={classes.body}>
          Benefits: <br />
          - Latest projections for the UK from the MetOffice <br />- Daily
          timeseries allows analysis of extremes
        </Typography>
        <br />
        <Typography variant="body1" className={classes.body}>
          Limitations: <br />- Data only for high emission scenario - Only
          covers UK <br />
          - Time periods are limited for the local 5km projections <br />
          - Limited number of models (compared to 3000 samples in probabilistic
          data) <br />- Cannot extract very rare extremes (e.g. less than 1 in
          10 year occurrence)
        </Typography>
      </div>
      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          FURTHER UKCP18 PRODUCTS
        </Typography>
        <br />
        <Typography variant="body1" className={classes.body}>
          Probabilistic projections for average trends are also available in
          Kliima. Values for the probabilistic projections of climate extremes
          (PPCE) for three extreme variables: maximum temperature, 1-day
          precipitation and 5-day precipitation across all seasons and three
          return periods (1 in 20-years, 1 in 50-years and 1 in 100-years). If
          you would like more information please contact KliimaSupport@arup.com
          Marine Projections including Sea Level Rise available on the UKCP18
          User Interface
          <a
            href="https://ukclimateprojections-ui.metoffice.gov.uk/ui/home"
            target="_blank"
            rel="noreferrer"
          >
            https://ukclimateprojections-ui.metoffice.gov.uk/ui/home
          </a>
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          USEFUL SOURCES
        </Typography>
        <Typography variant="h6" className={classes.heading}>
          UKCP18 Science Overview Report
          <a
            href="https://www.metoffice.gov.uk/pub/data/weather/uk/ukcp18/science-reports/UKCP18-Overview-report.pdf"
            target="_blank"
            rel="noreferrer"
          >
            https://www.metoffice.gov.uk/pub/data/weather/uk/ukcp18/science-reports/UKCP18-Overview-report.pdf
          </a>
        </Typography>
        <br />
        {/* <Typography variant="h6" className={classes.heading}>
          UKCP18 Science Overview Report
        </Typography>

        <Typography variant="body1" className={classes.body}>
          https://www.metoffice.gov.uk/pub/data/weather/uk/ukcp18/science-reports/UKCP18-Overview-report.pdf
        </Typography> */}
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          REFERENCE &amp; CITATION
        </Typography>

        <Typography variant="body1" className={classes.body}>
          All data is provided under the Open Government Licence. Use of these
          data is covered by:{" "}
          <a
            href="http://www.nationalarchives.gov.uk/doc/open-government-licence/version/3/"
            target="_blank"
            rel="noreferrer"
          >
            http://www.nationalarchives.gov.uk/doc/open-government-licence/version/3/
          </a>
          . Met Office Hadley Centre (2018): UKCP18 Regional Projections on a
          12km grid over the UK for 1980-2080. Centre for Environmental Data
          Analysis,date of citation.{" "}
          <a
            href="https://catalogue.ceda.ac.uk/uuid/589211abeb844070a95d061c8cc7f604"
            target="_blank"
            rel="noreferrer"
          >
            https://catalogue.ceda.ac.uk/uuid/589211abeb844070a95d061c8cc7f604
          </a>
          Met Office Hadley Centre (2019): UKCP Local Projections on a 5km grid
          over the UK for 1980-2080. Centre for Environmental Data Analysis,date
          of citation.{" "}
          <a
            href="https://catalogue.ceda.ac.uk/uuid/e304987739e04cdc960598fa5e4439d0"
            target="_blank"
            rel="noreferrer"
          >
            https://catalogue.ceda.ac.uk/uuid/e304987739e04cdc960598fa5e4439d0
          </a>
        </Typography>
      </div>
    </>
  );
};

export const HadUkObservationsContent = () => {
  const classes = useStyles();

  return (
    <>
      <img
        src={hadUk}
        className={classes.logo}
        style={{ height: "11.4375rem" }}
        alt="ukpc18-dataset-logo"
      />

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          HadUK-Grid Observations
        </Typography>
        <a
          href="https://www.metoffice.gov.uk/research/climate/maps-and-data/data/haduk-grid/haduk-grid"
          target="_blank"
          className={classes.body}
          rel="noreferrer"
        >
          https://www.metoffice.gov.uk/research/climate/maps-and-data/data/haduk-grid/haduk-grid
        </a>
      </div>

      <br />
      <div className={classes.sideBySideText}>
        <Typography variant="h6" className={classes.heading}>
          DATA LAST UPDATED IN KLIIMA :
        </Typography>
        <Typography variant="body1" className={classes.body}>
          January 2023
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          BACKGROUND
        </Typography>

        <br />
        <Typography variant="body1" className={classes.body}>
          HadUK-Grid is a collection of gridded climate variables derived from
          the network of UK land surface observations. The data have been
          interpolated from meteorological station data onto a uniform grid to
          provide complete and consistent coverage across the UK. The data sets
          cover the UK up to 1km x 1km resolution and a range of other
          resolutions to allow for comparison to data from climate projections.
        </Typography>
      </div>

      <br />

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          Emission Scenarios:
        </Typography>
        <Typography variant="body1" className={classes.body}>
          N/A
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          Spatial Resolution:
        </Typography>
        <Typography variant="body1" className={classes.body}>
          25km, 12km, 5km and 1km
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          Temporal Resolution:
        </Typography>
        <Typography variant="body1" className={classes.body}>
          Daily
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          Time Period Coverage:
        </Typography>
        <Typography variant="body1" className={classes.body}>
          Historical (1981-2010)
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          MODELS
        </Typography>

        <Typography variant="body1" className={classes.body}>
          N/A
        </Typography>
        <br />
      </div>

      <br />
      <div>
        <Typography variant="h6" className={classes.heading}>
          Arup Notes
        </Typography>
        <Typography variant="body1" className={classes.body}>
          HadUK-Grid can be used alongside UKCP18 and other projection datasets
          for the UK. Please see{" "}
          <a
            href="https://arup.sharepoint.com/sites/Kliima/SitePages/Home.aspx"
            target="_blank"
            rel="noreferrer"
          >
            Kliima Sharepoint
          </a>
          for more information. HadUK-Grid can also be used for projects
          requiring historic weather data.
        </Typography>
      </div>
      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          REFERENCE &amp; CITATION
        </Typography>

        <Typography variant="body1" className={classes.body}>
          All data is provided under the Open Government Licence. Use of these
          data is covered by:{" "}
          <a
            href="http://www.nationalarchives.gov.uk/doc/open-government-licence/version/3/"
            target="_blank"
            rel="noreferrer"
          >
            http://www.nationalarchives.gov.uk/doc/open-government-licence/version/3/
          </a>
          . Met Office Hadley Centre (2018): UKCP18 Regional Projections on a
          12km grid over the UK for 1980-2080. Centre for Environmental Data
          Analysis,date of citation.{" "}
          <a
            href="https://catalogue.ceda.ac.uk/uuid/589211abeb844070a95d061c8cc7f604"
            target="_blank"
            rel="noreferrer"
          >
            https://catalogue.ceda.ac.uk/uuid/589211abeb844070a95d061c8cc7f604
          </a>
          Met Office Hadley Centre (2019): UKCP Local Projections on a 5km grid
          over the UK for 1980-2080. Centre for Environmental Data Analysis,date
          of citation.{" "}
          <a
            href="https://catalogue.ceda.ac.uk/uuid/e304987739e04cdc960598fa5e4439d0"
            target="_blank"
            rel="noreferrer"
          >
            https://catalogue.ceda.ac.uk/uuid/e304987739e04cdc960598fa5e4439d0
          </a>
        </Typography>
      </div>
    </>
  );
};

export const NoaaLocaContent = () => {
  const classes = useStyles();

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <img
          src={noaaLocaLogo}
          className={classes.logo}
          alt="noaa-loca-dataset-logo"
        />

        {/* <Box ml="2rem">
          <Typography variant="h6" className={classes.heading}>
            LOCA
          </Typography>
          <br />
          <Typography variant="body1" className={classes.body}>
            The LOCA (Localized Constructed Analogs) dataset includes downscaled
            projections from 32 global climate models calculated for two
            Representative Concentration Pathways (RCP 4.5 and RCP 8.5). Each of
            the climate projections includes daily maximum temperature, minimum
            temperature, and precipitation for every 6x6km for the conterminous
            US from 1950 to 2100. LOCA attempts to better preserve extreme hot
            days and heavy rain events, regional patterns of precipitation.
            <br />
            All data is provided under US government works licence.
          </Typography>
        </Box> */}
      </Box>

      <br />
      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          LOCA
        </Typography>
        <a
          href="http://loca.ucsd.edu/"
          target="_blank"
          className={classes.body}
          rel="noreferrer"
        >
          http://loca.ucsd.edu/
        </a>
      </div>

      <br />
      <div className={classes.sideBySideText}>
        <Typography variant="h6" className={classes.heading}>
          DATA LAST UPDATED IN KLIIMA :
        </Typography>
        <Typography variant="body1" className={classes.body}>
          January 2023
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          BACKGROUND
        </Typography>

        <br />
        <Typography variant="body1" className={classes.body}>
          The LOCA (Localized Constructed Analogs) dataset includes downscaled
          projections from 32 global climate models calculated for two
          Representative Concentration Pathways (RCP 4.5 and RCP 8.5). Each of
          the climate projections includes daily maximum temperature, minimum
          temperature, and precipitation for every 6x6km for the conterminous US
          from 1950 to 2100. LOCA attempts to better preserve extreme hot days,
          heavy rain events, and regional patterns of precipitation.
        </Typography>
      </div>

      <br />

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          Emission Scenarios:
        </Typography>
        <Typography variant="body1" className={classes.body}>
          RCP 4.5 (Medium)
          <br />
          RCP 8.5 (High)
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          Spatial Resolution:
        </Typography>
        <Typography variant="body1" className={classes.body}>
          1/16th degree (6km)
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          Temporal Resolution:
        </Typography>
        <Typography variant="body1" className={classes.body}>
          Daily
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          Time Period Coverage:
        </Typography>
        <Typography variant="body1" className={classes.body}>
          Historical (1950-2005) <br />
          Future (2006-2100)
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          MODELS
        </Typography>{" "}
        <br />
        <Typography variant="body1" className={classes.body}>
          32 global climate models from the CMIP5 archive downscaled.
        </Typography>
        <br />
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          Arup Notes
        </Typography>
        <Typography variant="body1" className={classes.body}>
          Benefits: <br /> - High spatial resolution <br /> - Large number of
          models used in the downscaling
        </Typography>
        <br />
        <Typography variant="body1" className={classes.body}>
          Limitations: <br /> - Limited to US <br /> - Cannot extract very rare
          extremes (e.g. less than 1 in 10 year occurrence)
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          USEFUL SOURCES
        </Typography>

        <a
          href=" https://scenarios.globalchange.gov/loca-viewer/"
          target="_blank"
          className={classes.body}
        >
          https://scenarios.globalchange.gov/loca-viewer/
        </a>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          REFERENCE &amp; CITATION
        </Typography>
        <Typography variant="body1" className={classes.body}>
          All data is provided under US government works licence.
        </Typography>
      </div>
    </>
  );
};

export const Cmip6Content = () => {
  const classes = useStyles();

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <img
          src={cmip6Logo}
          style={{ width: "9.375rem", height: "2rem" }}
          alt="cmip6-dataset-logo"
        />

        {/* <Box ml="2rem">
          <Typography variant="body1" className={classes.body}>
            We acknowledge the World Climate Research Programme&apos;s Working
            Group on Regional Climate, and the Working Group on Coupled
            Modelling, former coordinating body of CORDEX and responsible panel
            for CMIP5. We also thank the climate modelling groups for producing
            and making available their model output. We also acknowledge the
            Earth System Grid Federation infrastructure an international effort
            led by the U.S. Department of Energy&apos;s Program for Climate
            Model Diagnosis and Intercomparison, the European Network for Earth
            System Modelling and other partners in the Global Organisation for
            Earth System Science Portals (GO-ESSP).
            <br />
            <br />
            The Coordinated Regional Downscaling Experiment (CORDEX) is a CMIP6
            diagnostic MIP requesting specific CMIP6 output for regional climate
            downscaling. CORDEX builds on a foundation of previous downscaling
            intercomparison projects to provide a common framework for
            downscaling activities around the world. The CORDEX Regional
            Challenges provide a focus for downscaling research and a basis for
            making use of CMIP6 global output to produce downscaled projected
            changes in regional climates, and assess sources of uncertainties in
            the projections.
          </Typography>
        </Box> */}
      </Box>

      <Box mt="20px">
        <Typography variant="h6" className={classes.heading}>
          CMIP6 (coming soon)
        </Typography>

        <Typography variant="body1" className={classes.body}>
          CMIP6 is the latest global dataset and underpins the AR6 report by the
          IPCC. It is an update from the widely used CMIP5 dataset which
          informed the previous AR5 report.
        </Typography>
      </Box>

      {/* <br />
      <br />

      <br />
      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          CMPI6
        </Typography>
        <Typography variant="body1" className={classes.body}>
          wcrp-climate.org/wgcm-cmip/wgcm-cmip5 <br />
          https://pcmdi.llnl.gov/mips/cmip5/
        </Typography>
      </div>

      <br />
      <div className={classes.sideBySideText}>
        <Typography variant="h6" className={classes.heading}>
          DATA LAST UPDATED IN KLIIMA :
        </Typography>
        <Typography variant="body1" className={classes.body}></Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          BACKGROUND
        </Typography>

        <br />
        <Typography variant="body1" className={classes.body}>
          Under the World Climate Research Programme (WCRP) the Working Group on
          Coupled Modelling (WGCM) established the Coupled Model Intercomparison
          Project (CMIP) as a standard global experimental protocol for studying
          the output of coupled atmosphere-ocean general circulation models
          (GCM). The CMIP5 project is the most current and extensive used in the
          AR5 report by the IPCC (published 2013)
        </Typography>
      </div>

      <br />

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          Emission Scenarios:
        </Typography>
        <Typography variant="body1" className={classes.body}>
          RCP 4.5 <br />
          RCP 8.5
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          Spatial Resolution:
        </Typography>
        <Typography variant="body1" className={classes.body}>
          150km
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          Temporal Resolution:
        </Typography>
        <Typography variant="body1" className={classes.body}>
          Daily
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          Time Period Coverage:
        </Typography>
        <Typography variant="body1" className={classes.body}>
          Historical (1950-2005) <br />
          Future (2006-2100)
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          MODELS
        </Typography>{" "}
        <br />
        <Typography variant="body1" className={classes.body}>
          35 GCMs
        </Typography>
        <br />
      </div>

      <br />
      <div>
        <Typography variant="h6" className={classes.heading}>
          Arup Notes
        </Typography>
        <Typography variant="body1" className={classes.body}>
          Benefits: <br />
          -Globally and extensively used by climate community <br />
          -Used to inform IPCC AR5 Report
        </Typography>
        <br />
        <Typography variant="body1" className={classes.body}>
          Limitations: <br /> - Course resolution
        </Typography>
      </div>

      <br />
      <div>
        <Typography variant="h6" className={classes.heading}>
          FURTHER CMIP PRODUCTS
        </Typography>
        <Typography variant="body1" className={classes.body}>
          CMIP6 has been released and used to inform the 2021 IPCC AR6 Report.
          The Kliima team are currently developing capabilities to add this
          dataset. Please see the Sharepoint for more details.
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          USEFUL SOURCES
        </Typography>
        <br />
        <Typography variant="h6" className={classes.heading}>
          IPCC AR5 Report
        </Typography>
        <Typography variant="body1" className={classes.body}>
          https://www.ipcc.ch/report/ar5/syr/
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          REFERENCE &amp; CITATION
        </Typography>
        <br />
        <Typography variant="body1" className={classes.body}>
          https://pcmdi.llnl.gov/mips/cmip5/citation.html{" "}
        </Typography>

        <Typography variant="body1" className={classes.body}>
          We acknowledge the World Climate Research Programme&apos;s Working
          Group on Regional Climate, and the Working Group on Coupled Modelling,
          former coordinating body of CORDEX and responsible panel for CMIP5. We
          also thank the climate modelling groups for producing and making
          available their model output. We also acknowledge the Earth System
          Grid Federation infrastructure, an international effort led by the
          U.S. Department of Energy&apos;s Program for Climate Model Diagnosis
          and Intercomparison, the European Network for Earth System Modelling
          and other partners in the Global Organisation for Earth System Science
          Portals (GO-ESSP).
        </Typography>
      </div> */}
    </>
  );
};
