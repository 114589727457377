import React, { createContext, useState } from "react";
import { TableData } from "../../../types/types";
import { REGION_DATA_SET } from "./RegionData";

export const RegionContext = createContext<any | null>(null);

export const RegionProvider = (props) => {
  const [map, setMap] = useState({
    region: "Asia",
    country: "INDIA",
    Longitude: 78.387451,
    Latitude: 11.059821,
    zoom: 0,
    shortName: "IND",
  });
  const [markerLocation, setMaarkerLocation] = useState({
    markerLongitude: 0,
    markerLatitude: 0,
  });
  const [colorRegion, setcolorRegion] = useState([""]);

  const [region, setRegion] = useState(REGION_DATA_SET);
  const [userView, setuserView] = useState("");
  const [clickedMarker, setClickedMarker] = useState(0);
  const [viewport, setViewport] = useState({
    latitude: 0,
    longitude: 0,
    width: "100%",
    height: "100vh",
    zoom: 0,
  });
  const [outputMarker, setOutputMarker] = useState({
    visible: false,
    selected: 0,
  });
  const [geoJson, setGeoJson] = useState({
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        properties: {
          title: "blank",
          short_name: "blank",
          region: "blank",
          region_short: "blank",
        },
        geometry: {
          type: "Point",
          coordinates: [1, 1],
        },
      },
    ],
  });
  const [prepareInputList, setPrepareInputList] = useState({});
  const [latLon, setLatLon] = useState<{
    lat?: number;
    lon?: number;
  }>({
    lat: undefined,
    lon: undefined,
  });
  const [markerDroped, setMarkerDroped] = useState(false);
  const [ssoProfile, setSsoProfile] = useState({
    userName: "",
    name: "",
    email: "",
    token: "",
  });
  const [historyTable, setHistoryTable] = useState({} as TableData);

  return (
    <RegionContext.Provider
      value={{
        regionName: [region, setRegion],
        mapdata: [map, setMap],
        markerPosition: [markerLocation, setMaarkerLocation],
        regionColor: [colorRegion, setcolorRegion],
        view: [userView, setuserView],
        geoJSON: [geoJson, setGeoJson],
        mapViewPort: [viewport, setViewport],
        inputList: [prepareInputList, setPrepareInputList],
        getlatLon: [latLon, setLatLon],
        markerDroper: [markerDroped, setMarkerDroped],
        markerClick: [clickedMarker, setClickedMarker],
        historyTab: [historyTable, setHistoryTable],
        outMMarker: [outputMarker, setOutputMarker],
        ssoID: [ssoProfile, setSsoProfile],
      }}
    >
      {props.children}
    </RegionContext.Provider>
  );
};
