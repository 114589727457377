import React, { useContext, useState, useEffect, useCallback } from "react";
import { Box, Typography } from "@material-ui/core";
import CustomModal from "../../../partials/CustomModal";
import {
  TimePeriodSelectionModalPropTypes,
  useTimePeriodSelectionModalStyles,
} from "./useStyles";
import DatasetConfigurationIcon from "../../../Icons/DatasetConfigurationIcon";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import { LocationSelectContext } from "../../../context/LocationSelectPageContext";
import { CustomDropdownMenuItemsType } from "../../../../../types/types";
import {
  CMIP6,
  CORDEX,
  HAD_UK,
  LOCA,
  UKCP18_HIGH_RES,
  UKCP18_PROB,
} from "../datasets";

//
const timeValueMapping = {
  0: "20 year",
  1: "30 year",
};

function TimePeriodSelectionModal(props: TimePeriodSelectionModalPropTypes) {
  const { open, closeModalHandler } = props;
  const classes = useTimePeriodSelectionModalStyles();

  const {
    selectedDatasetState,
    historicalYearState,
    projectedYearState,
    timeDurationState,
    yearDurationState,
    selectedResolutionState,
    selectedProjectedYearState,
    timeDurationDropDown,
  } = useContext(LocationSelectContext);

  const { selectedDataset } = selectedDatasetState;
  const { selectedResolution } = selectedResolutionState;

  const [historicalYears, setHistoricalYears] = useState<
    CustomDropdownMenuItemsType[]
  >([]);

  const [firstFutureYears, setFirstFutureYears] = useState<
    CustomDropdownMenuItemsType[]
  >([]);

  const [secondFutureYears, setSecondFutureYears] = useState<
    CustomDropdownMenuItemsType[]
  >([]);

  const { timeDuration, setTimeDuration } = timeDurationDropDown;
  const {
    selectedProjectedYear,
    setSelectedProjectedYear,
  } = selectedProjectedYearState;

  const { setHistoricalYear } = historicalYearState;
  const { setProjectedYear } = projectedYearState;

  const historicalDataChangeHandler = (from, to) => {
    setHistoricalYear({ from, to });
  };

  useEffect(() => {
    if (Boolean(timeDuration) && historicalYears.length) {
      if (timeDuration === "20") {
        const yearData = historicalYears[0].label.split("-");
        historicalDataChangeHandler(yearData[0], yearData[1]);
      } else {
        const yearData = historicalYears[1].label.split("-");
        historicalDataChangeHandler(yearData[0], yearData[1]);
      }
    }
  }, [timeDuration]);

  useEffect(() => {
    const from = selectedProjectedYear.split("-")[0] || "";
    const to = selectedProjectedYear.split("-")[1] || "";
    setProjectedYear({ from, to });
  }, [selectedProjectedYear, setProjectedYear]);

  const setDpdOptions: () => void = useCallback(() => {
    switch (selectedDataset) {
      case CORDEX:
        setHistoricalYears([{ label: "1986-2005" }, { label: "1976-2005" }]);

        setFirstFutureYears([
          { label: "2010-2029" },
          { label: "2020-2039" },
          { label: "2030-2049" },
          { label: "2040-2059" },
          { label: "2050-2069" },
          { label: "2060-2079" },
          { label: "2070-2089" },
        ]);
        setSecondFutureYears([
          { label: "2010-2039" },
          { label: "2020-2049" },
          { label: "2030-2059" },
          { label: "2040-2069" },
          { label: "2050-2079" },
          { label: "2060-2089" },
        ]);

        break;

      case LOCA:
        setHistoricalYears([{ label: "1986-2005" }, { label: "1976-2005" }]);
        setFirstFutureYears([
          { label: "2010-2029" },
          { label: "2020-2039" },
          { label: "2030-2049" },
          { label: "2040-2059" },
          { label: "2050-2069" },
          { label: "2060-2079" },
          { label: "2070-2089" },
          { label: "2080-2099" },
        ]);
        setSecondFutureYears([
          { label: "2010-2039" },
          { label: "2020-2049" },
          { label: "2030-2059" },
          { label: "2040-2069" },
          { label: "2050-2079" },
          { label: "2060-2089" },
          { label: "2070-2099" },
        ]);
        break;

      case UKCP18_PROB:
        setHistoricalYears([{ label: "1981-2000" }, { label: "1981-2010" }]);

        setFirstFutureYears([
          { label: "2010-2029" },
          { label: "2020-2039" },
          { label: "2030-2049" },
          { label: "2040-2059" },
          { label: "2050-2069" },
          { label: "2060-2079" },
          { label: "2070-2089" },
          { label: "2080-2099" },
        ]);
        setSecondFutureYears([
          { label: "2010-2039" },
          { label: "2020-2049" },
          { label: "2030-2059" },
          { label: "2040-2069" },
          { label: "2050-2079" },
          { label: "2060-2089" },
          { label: "2070-2099" },
        ]);
        break;

      case UKCP18_HIGH_RES:
        selectedResolution === "5km" &&
          setHistoricalYears([{ label: "1981-2000" }]);

        selectedResolution === "5km" &&
          setFirstFutureYears([{ label: "2021-2040" }, { label: "2061-2080" }]);
        selectedResolution === "5km" && setSecondFutureYears([]);

        selectedResolution === "12km" &&
          setHistoricalYears([{ label: "1981-2000" }, { label: "1981-2010" }]);

        selectedResolution === "12km" &&
          setFirstFutureYears([
            { label: "2011-2030" },
            { label: "2021-2040" },
            { label: "2031-2050" },
            { label: "2041-2060" },
            { label: "2051-2070" },
            { label: "2061-2080" },
          ]);
        selectedResolution === "12km" &&
          setSecondFutureYears([
            { label: "2011-2040" },
            { label: "2021-2050" },
            { label: "2031-2060" },
            { label: "2041-2070" },
            { label: "2051-2080" },
          ]);

        break;

      case CMIP6:
        setHistoricalYears([{ label: "1995-2014" }, { label: "1986-2014" }]);
        setFirstFutureYears([
          { label: "2020-2039" },
          { label: "2030-2049" },
          { label: "2040-2059" },
          { label: "2050-2069" },
          { label: "2060-2079" },
          { label: "2070-2089" },
          { label: "2080-2099" },
        ]);
        setSecondFutureYears([
          { label: "2020-2049" },
          { label: "2030-2059" },
          { label: "2040-2069" },
          { label: "2050-2079" },
          { label: "2060-2089" },
          { label: "2070-2099" },
        ]);

        break;

      case HAD_UK:
        setHistoricalYears([{ label: "1981-2000" }, { label: "1981-2010" }]);
        setFirstFutureYears([]);
        setSecondFutureYears([]);
        break;

      default:
        break;
    }
  }, [selectedDataset, selectedResolution]);

  useEffect(() => {
    const x = Math.abs(eval(yearDurationState[0])) + 1 + "";
    if (x === "20") {
      setTimeDuration(x);
    } else {
      setTimeDuration(x);
    }
  }, [yearDurationState[0]]);

  useEffect(() => {
    setDpdOptions();
  }, [selectedDataset, setDpdOptions]);

  return (
    <CustomModal
      title="Time Period Selection"
      headingBottomText="Select time period (s) of interest"
      paragraph="Climate projections are extracted in 20 or 30 year periods as anomalies (differences) between a future and historic time period."
      isOpen={open}
      headingMaxWidth="fit-content"
      onClick={closeModalHandler}
    >
      {
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Typography variant="h6">Time Duration</Typography>

          <select
            className={classes.select}
            onChange={(e) => {
              setTimeDuration(e.target.value);
              setSelectedProjectedYear("");
              setHistoricalYear({ from: "", to: "" });
              setProjectedYear({ from: "", to: "" });
              timeDurationState[1](e.target.value);
            }}
            value={timeDuration}
          >
            <option selected hidden>
              Select One
            </option>
            <option value="20">20 year</option>
            {selectedResolution !== "5km" && (
              <option value="30">30 year</option>
            )}
          </select>
        </Box>
      }

      <Box
        width="100%"
        display="flex"
        flex={1}
        minHeight="21.875rem"
        marginTop="1.875rem"
        className={classes.container}
      >
        <Box
          flex={1}
          // borderRight="1px solid"
          display="flex"
          justifyContent="center"
          flexDirection="column"
        >
          {selectedDataset !== "UKCP18 Probabilistic (Average Trends)" && (
            <div className={classes.imgContainer}>
              <DatasetConfigurationIcon className={classes.icon} />
            </div>
          )}
          {selectedDataset !== "UKCP18 Probabilistic (Average Trends)" && (
            <Box width="100%" flex={1} borderRight="1px solid">
              <Typography variant="h6">Historical Data</Typography>

              {historicalYears.map((e, i) => (
                <Box
                  key={e.label}
                  height="40%"
                  display="flex"
                  marginTop={i === 0 ? 2 : "15px"}
                >
                  <Box
                    width="100%"
                    height="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    position="relative"
                  >
                    <Typography>{e.label}</Typography>
                    <Typography className={classes.yearText}>
                      {timeValueMapping[i]}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          )}
        </Box>
        <Box
          flex={1}
          // paddingLeft="2rem"
          display="flex"
          justifyContent="center"
          flexDirection="column"
        >
          {selectedDataset !== "Had-UK (UK Observations)" && (
            <div className={classes.imgContainer}>
              <DatasetConfigurationIcon className={classes.icon} />
            </div>
          )}{" "}
          <Box width="100%" flex={1}>
            {selectedDataset !== "Had-UK (UK Observations)" && (
              <Typography variant="h6">Future Data</Typography>
            )}
            <Box height="40%" marginTop={2} pb="10px">
              <RadioGroup
                aria-label="future_data"
                name="future_data"
                row
                onChange={(e) => {
                  setSelectedProjectedYear(e.target.value);
                  const data = historicalYears[0].label.split("-");
                  historicalDataChangeHandler(data[0], data[1]);
                }}
                value={selectedProjectedYear}
              >
                {firstFutureYears.map((e) => (
                  <FormControlLabel
                    disabled={timeDuration !== "20"}
                    key={e.label}
                    value={e.label}
                    control={<Radio />}
                    label={e.label}
                    classes={{ disabled: classes.disabledRadio }}
                  />
                ))}
              </RadioGroup>
            </Box>
            <Box height="40%" marginTop="10px">
              <RadioGroup
                aria-label="future_data"
                name="future_data"
                row
                onChange={(e) => {
                  setSelectedProjectedYear(e.target.value);
                  const data = historicalYears[1].label.split("-");
                  historicalDataChangeHandler(data[0], data[1]);
                }}
                value={selectedProjectedYear}
              >
                {secondFutureYears.map((e) => (
                  <FormControlLabel
                    disabled={timeDuration !== "30"}
                    key={e.label}
                    value={e.label}
                    control={<Radio />}
                    label={e.label}
                    classes={{ disabled: classes.disabledRadio }}
                  />
                ))}
              </RadioGroup>
            </Box>
          </Box>
        </Box>
      </Box>
    </CustomModal>
  );
}

export default TimePeriodSelectionModal;
