import { TextField } from "@material-ui/core";
import React from "react";

import {
  useMoreThanLessThanTextFieldStyle,
  MoreThanLessThanTextFieldPropsType,
} from "../Pages/LocationSelectPage/useStyles";

function MoreThanLessThanTextField(props: MoreThanLessThanTextFieldPropsType) {
  const classes = useMoreThanLessThanTextFieldStyle(props);

  const { value, unit, sign, onChange, isReadOnly } = props;

  return (
    <div
      className={classes.container}
      style={{ pointerEvents: isReadOnly ? "none" : "auto" }}
    >
      <div className={classes.labelContainer}>
        <label
          className={classes.labels}
          htmlFor="sign"
          style={{ fontSize: "1.5625rem", fontWeight: 400 }}
        >
          {sign}
        </label>
      </div>
      <TextField
        name="sign"
        fullWidth
        id="sign"
        value={value}
        variant="outlined"
        className={classes.inputField}
        inputProps={{
          style: {
            height: value ? "30px" : "32px",
            padding: "0 14px",
            border: value ? "1.21806px solid #000" : "",
          },
        }}
        onChange={onChange}
      />

      <div
        className={classes.labelContainer}
        style={{
          borderLeft: "none",
          borderRight: value
            ? "1.21806px solid #000"
            : "1.21806px solid #BEBEBE",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {unit}
      </div>
    </div>
  );
}

export default MoreThanLessThanTextField;
