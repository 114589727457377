import React from "react";
import { Typography, CardActionArea } from "@material-ui/core";
import DoneIcon from "../../../Icons/DoneIcon";

import { DataSetCardPropTypes, useDataSetCardStyles } from "./useStyles";

const DataSetCard = (props: DataSetCardPropTypes) => {
  const classes = useDataSetCardStyles(props);

  const { selected, title, onSelect, description, unit } = props;

  return (
    <div className={classes.mainContainer}>
      <CardActionArea style={{ width: "fit-content" }} onClick={onSelect}>
        <div className={classes.imgContainer}>
          <span
            className={classes.circle}
            style={{
              background: selected ? "#7CD4F2" : "#BEBEBE",
              cursor: "pointer",
            }}
          >
            <DoneIcon style={{ fontSize: "18px" }} />
          </span>
        </div>
      </CardActionArea>
      <Typography className={classes.title} variant="h6">
        {title}
      </Typography>
      <Typography variant="body1" className={classes.bodyText}>
        {unit}
      </Typography>
      <Typography variant="body1" className={classes.bodyText}>
        {description}
      </Typography>
    </div>
  );
};

export default DataSetCard;
