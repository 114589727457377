import React from "react";
import { makeStyles } from "@material-ui/core";

import availableDatasetPng from "../../../../../images/availableDataset.png";

export type DatasetSelectionModalPropTypes = {
  open: boolean;
  closeModalHandler: () => void;
};

export const useDatasetSelectionModalStyles = makeStyles({
  contentContainer: {
    padding: "0 0 0 3.75rem",
    display: "flex",
    flex: 1,
    flexDirection: "column",

    // maxHeight: "30.8rem",
    maxHeight: "fit-content",
    overflowY: "scroll",
    paddingRight: "20px",
    paddingBottom: "20px",
  },
  btn: {
    width: "8.7975rem",
    lineHeight: "16px",
    color: " #1C1C1C",
    backgroundColor: "#F99052",
    fontSize: "0.8rem",
    marginBottom: "2rem",
    "&:hover": {
      backgroundColor: "#F99052",
    },
  },
});

export type AvailableDatasetCardPropTypes = {
  title: string;
  selected: boolean;
  onSelect: () => void;
  style: React.CSSProperties;
  isAvailable: boolean;
};

export const useAvailableDatasetCardStyles = makeStyles({
  root: {
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      transform: "rotate(0deg)",
      borderRadius: "4px",
      display: (props: AvailableDatasetCardPropTypes) =>
        props.isAvailable ? "none" : "",
    },
  },

  cardContainer: {
    width: "18.1319rem",
    height: "12.1475rem",
    borderRadius: "3.21657px",
    marginBottom: "10px",
    background: (props: AvailableDatasetCardPropTypes) =>
      props.selected
        ? `url(${availableDatasetPng})`
        : ` linear-gradient(rgba(225,225,225, .5), rgba(225,225,225, .5)), url(${availableDatasetPng})`,
    padding: "2.375rem 0.9375rem 1.25rem 1.25rem",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },

  mainTitle: {
    fontSize: "1.625rem",
    lineHeight: "36px",
    color: "#FFFFFF",
    marginBottom: "0.9375rem",
  },

  bodyText: {
    width: "12.5rem",
    fontSize: "1.2062rem",
    lineHeight: "23px",
    color: "#FFFFFF",
  },

  circle: {
    width: "33.38px",
    height: "33.38px",
    borderRadius: "100%",
    background: "#4EC0EB",
    marginRight: "7px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
