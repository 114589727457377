import React from "react";
import { Button, Typography } from "@material-ui/core";

import {
  LevelLocationDetailCardPropTypes,
  useLevelLocationDetailCardStyles,
} from "./useStyles";

const LevelLocationDetailCard = (props: LevelLocationDetailCardPropTypes) => {
  const classes = useLevelLocationDetailCardStyles();

  const {
    title,
    question,
    description,
    disabled,
    src,
    imgHeight,
    onClick,
  } = props;

  return (
    <div className={classes.mainContainer}>
      <div className={classes.imgContainer}>
        <img
          src={src}
          alt={title}
          style={{ height: imgHeight, marginBottom: "12px" }}
        />
      </div>

      <div
        style={{
          height: "100px",
        }}
      >
        <Typography variant="h6" className={classes.cardMainHeading}>
          {title}
        </Typography>

        <Typography variant="body1" className={classes.content}>
          {question}
        </Typography>

        <Typography variant="body1" className={classes.content}>
          {description}
        </Typography>

        {disabled && (
          <Typography
            variant="body1"
            className={classes.content}
            style={{ fontSize: "11px", color: "#575757" }}
          >
            *We currently do not offer search data outputs at a global scale.
          </Typography>
        )}
      </div>
      <Button
        onClick={onClick}
        disabled={disabled}
        disableElevation
        color="primary"
        variant="contained"
        className={classes.btn}
      >
        {disabled ? "Coming Soon" : "Select & Continue"}
      </Button>
    </div>
  );
};

export default LevelLocationDetailCard;
