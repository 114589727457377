/* eslint-disable */
import React, { useEffect, useState, useContext, useCallback } from "react";

import { RegionContext } from "../../context/RegionContext";
import ReactMapGL, {
  Marker,
  Layer,
  NavigationControl,
  MapContext,
} from "react-map-gl";
import Pointer from "./pointer";

import { MAP_BOX_CONFIG } from "../../../../kliima.env";

export type LayerProps = {
  filter: any;
  props: any;
};
let currentUserZoomed;
function CurrentZoomLevel() {
  const context: any = useContext(MapContext);
  currentUserZoomed = context.viewport.zoom;
  return <div>{""}</div>;
}
const locationLayerRegion: any = {
  type: "fill",
  source: {
    type: "vector",
    url: "mapbox://bharatdigiryte.d7eimuvr",
  },
  "source-layer": "ne_10m_admin_0_countries-1yg149",
  paint: {
    "fill-color": "#ADE5F7",
    "fill-outline-color": "#91b0c0",
    "fill-opacity": 0.3,
  },
};
const locationLayerCountry: any = {
  type: "fill",
  source: {
    type: "vector",
    url: "mapbox://bharatdigiryte.d7eimuvr",
  },
  "source-layer": "ne_10m_admin_0_countries-1yg149",
  paint: {
    "fill-color": "#ADE5F7",
    "fill-outline-color": "#91b0c0",
    "fill-opacity": 0.3,
  },
};
const navStyle = {
  position: "absolute" as "absolute",
  top: 20,
  right: 40,
  padding: "10px",
};

const MapBox = () => {
  const {
    mapdata,
    regionColor,
    view,
    geoJSON,
    mapViewPort,
    getlatLon,
    markerDroper,
    markerClick,
    historyTab,
    outMMarker,
  } = useContext(RegionContext);
  const [map, setMap] = mapdata;
  const [lng, setLng] = useState(78.387451);
  const [lat, setLat] = useState(11.059821);
  const [zoom, setZoom] = useState(0);
  const [shortName, setShortName] = useState("");
  const [colorRegion, setcolorRegion] = regionColor;
  const [userView, setuserView] = view;
  const [geoJson, setGeoJson] = geoJSON;
  const [viewport, setViewport] = mapViewPort;
  const [latLon, setLatLon] = getlatLon;
  const [clickedMarker, setClickedMarker] = markerClick;
  const [marker, setMarker] = useState({
    latitude: 20.593684,
    longitude: 78.96288,
  });
  const [events, logEvents] = useState({});
  const [historyTable, setHistoryTable] = historyTab;
  const [outputMarker, setOutputMarker] = outMMarker;
  const onMarkerDragStart = useCallback((event) => {
    logEvents((_events) => ({ ..._events, onDragStart: event.lngLat }));
  }, []);

  const onMarkerDrag = useCallback((event) => {
    logEvents((_events) => ({ ..._events, onDrag: event.lngLat }));
  }, []);

  const onMarkerDragEnd = useCallback((event) => {
    logEvents((_events) => ({ ..._events, onDragEnd: event.lngLat }));
    setMarker({
      longitude: event.lngLat[0],
      latitude: event.lngLat[1],
    });
    // console.log(event, "Stoped dragging");
    // console.log(currentUserZoomed, "currentUserZoomed");
    // console.log('on marker drag', event)
    // event.lngLat = [lon, lat], longitude at 0 index and latitude at 1 index
    setViewport({
      ...viewport,
      zoom: currentUserZoomed,
      longitude: event.lngLat[0],
      latitude: event.lngLat[1],
    });
    setLatLon({ ...latLon, lat: event.lngLat[1], lon: event.lngLat[0] });
    //eslint-disable-next-line
  }, []);

  const [markerDroped, setMarkerDroped] = markerDroper;

  const downloadFile = async () => {
    const myData = geoJson;
    const fileName = "geodata";
    const json = JSON.stringify(myData);
    const blob = new Blob([json], { type: "application/json" });
    const href = await URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = href;
    link.download = fileName + ".geojson";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const markerIdentity = (index) => {
    setClickedMarker(index);
  };
  useEffect(() => {
    function setMapX() {
      setLng(map.Longitude);
      setLat(map.Latitude);
      setZoom(4);
      setShortName(map.shortName);
    }
    setMapX();
    /*------------------------------
    
    
    
    ---------------------------------------------*/

    // Clean up on unmount
    return () => {};
  });

  return (
    <div>
      <ReactMapGL
        {...viewport}
        mapboxApiAccessToken={MAP_BOX_CONFIG.mapboxApiAccessToken}
        mapStyle={MAP_BOX_CONFIG.mapStyle}
        onViewportChange={(viewport) => {
          setViewport(viewport);
        }}
      >
        <CurrentZoomLevel />
        {userView === "REGION_VIEW" && (
          <Layer
            {...locationLayerRegion}
            filter={["in", "ADM0_A3_IS"].concat(colorRegion)}
          />
        )}
        {userView === "COUNTRY_VIEW" && (
          <Layer
            {...locationLayerCountry}
            filter={["in", "ADM0_A3_IS"].concat(shortName)}
          />
        )}
        {geoJson.features.length > 1 &&
          geoJson.features.map((location, index: number) => (
            <Marker
              className={"hideFirst"}
              key={location.properties.title}
              latitude={Number(location.geometry.coordinates[0])}
              longitude={Number(location.geometry.coordinates[1])}
            >
              <div
                onClick={() => {
                  markerIdentity(index);
                }}
              >
                <div className={"markerNumber"}>{index}</div>
                <img
                  className={"markerImage"}
                  src="/marker.svg"
                  alt="Countries"
                />
              </div>
            </Marker>
          ))}
        {/* -----------------------OUTPUT TABLE HISTORY----------------------------------------------- */}

        {outputMarker.visible === true &&
          historyTable.table_structure_location?.row?.map(
            (location, index: number) => {
              if(!location.value[0] && !location.value[1]) return 
              return (
                <Marker
                  className={"hideFirstv"}
                  key={`Index__${index}`}
                  latitude={parseInt(location.value[0])}
                  longitude={parseInt(location.value[1])}
                >
                  <div
                    onClick={() => {
                      markerIdentity(index);
                    }}
                  >
                    <div
                      className={`markerNumber ${
                        index === outputMarker.selected ? "active" : ""
                      }`}
                    >
                      {index + 1}
                    </div>
                    <img
                      className={`markerImage ${
                        index === outputMarker.selected ? "active" : ""
                      }`}
                      src="/marker.svg"
                      alt="Countries"
                    />
                  </div>
                </Marker>
              );
            }
          )}
        {/* --------------/usr/share/code/resources/app/out/vs/code/electron-sandbox/workbench/workbench.html----------------------------------------------------------- */}
        {markerDroped === true && (
          <Marker
            longitude={viewport.longitude}
            latitude={viewport.latitude}
            offsetTop={5}
            offsetLeft={-4}
            draggable
            onDragStart={onMarkerDragStart}
            onDrag={onMarkerDrag}
            onDragEnd={onMarkerDragEnd}
            className={"marker-draggging"}
          >
            <Pointer size={30} />
          </Marker>
        )}

        <div className="nav" style={navStyle}>
          <NavigationControl />
        </div>

        {/* {markerDroped == true && (
          <button style={btnStyle} onClick={downloadFile}>
            <img src="/download.png" alt="download" />
          </button>
        )} */}
      </ReactMapGL>
    </div>
  );
};

export default MapBox;
