import React, { useContext, useState } from "react";
import { Button, Grid } from "@material-ui/core";

import CustomModal from "../../../partials/CustomModal";

import ClimateDataSetCard from "./ClimateDataSetCard";

import climateDataSetSvg from "../../../../../images/climateDataSet.svg";

import {
  useClimateDataSetModalStyles,
  ClimateDataSetModalPropsType,
} from "./useStyles";
import { LocationSelectContext } from "../../../context/LocationSelectPageContext";
import Pagination from "../../../partials/Pagination";

const ClimateDataSetModal = (props: ClimateDataSetModalPropsType) => {
  const classes = useClimateDataSetModalStyles();

  const { open, closeModalHandler } = props;

  const {
    datasetSelectionModalState,
    climateDatasetModalState,
    variableSelectionModalState,
    configureDatasetVisibleState,
    welcomeToTheArupModalState,
  } = useContext(LocationSelectContext);

  const { setIsDatasetSelectionModalOpen } = datasetSelectionModalState;

  const { setIsWelcomeToTheArupModalOpen } = welcomeToTheArupModalState;

  const { setIsClimateDatasetModalOpen } = climateDatasetModalState;

  const { setIsVariableSelectionModalOpen } = variableSelectionModalState;

  const { setIsConfigureDatasetVisible } = configureDatasetVisibleState;

  // only for rendering content dynamically
  // this data for  the ClimateDataSetCard
  const [climateDataSetCardData] = useState<
    Array<{ title: string; description: string | React.ReactNode }>
  >([
    {
      title: "Cordex",
      description: (
        <>
          CORDEX provides access to
          <a
            href="https://arup.sharepoint.com/sites/Kliima/SitePages/Climate-modelling-basics---downscaling.aspx"
            target="_blank"
            rel="noreferrer"
          >
            dynamically downscaled
          </a>
          data for regions covering most land areas on the globe. Spatial
          resolutions vary from 25-50km depending on the region for a range of
          emission scenarios. Currently the regions covered in Kliima are North
          America, South America, Australasia, East Asia and Africa, with Europe
          and South East Asia coming soon.
        </>
      ),
    },
    {
      title: "LOCA",
      description: (
        <>
          LOCA covers the United States at a 6km resolution for emission scenarios
          RCP4.5 and RCP8.5. It is{" "}
          <a
            href="https://arup.sharepoint.com/sites/Kliima/SitePages/Climate-modelling-basics---downscaling.aspx"
            target="_blank"
            rel="noreferrer"
          >
            statistically downscaled
          </a>
          data which has been used to inform the National Climate Assessment 4
          (NCA4) in the United States.
        </>
      ),
    },
    {
      title: " UKCP18 Probabilistic",
      description: `UKCP18 is the latest set of projections produced for the UK by the Met Office. It provide the most up-to date assessment of how the climate of the UK may change over the 21st century.
        The probabilistic projections at 25km combine information from several collections of computer models and observations, and data is available for multiple emission scenarios to provide information on average future trends.`,
    },
    {
      title: "UKCP18 High-Resolution",
      description: `UKCP18 is the latest set of projections for the UK produced by the Met Office. It provides the most up-to date assessment of how the climate of the UK may change over the 21st century.
        The Regional (12km) and Local (5km) projections are available for RCP 8.5 only and cover a more limited set of climate models. The daily resolution of the data allows projections of future extreme hazards to be explored.`,
    },
    {
      title: "HadUK-Grid",
      description: `HadUK-Grid is an observational gridded dataset covering the UK and produced by the Met Office.
        The observational data from meteorological stations have been interpolated onto a uniform grid at various spatial resolutions. Observational data is often used with climate change projections for bias adjustment of climate model outputs, and to provide absolute values for future projections which were reported as anomalies.
        `,
    },
    {
      title: "CMIP6 (Coming Soon)",
      description:
        "CMIP6 is the latest global dataset and underpins the AR6 report by the IPCC. Resolutions of CMIP6 models can vary from 50-250km for a range of emission scenarios.",
    },
  ]);

  //for pagination
  // const [currentPage, setCurrentPage] = useState(1);
  // const [itemsPerPage] = useState(4);

  // get current items
  // const indexOfLastItem = currentPage * itemsPerPage;
  // const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // getting current items to show on the page
  // const currentItems = climateDataSetCardData.slice(
  //   indexOfFirstItem,
  //   indexOfLastItem
  // );

  // for paginating to the next page
  // getting pageNumber from the Pagination Component
  // const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  return (
    <CustomModal
      title="How we use climate datasets..."
      isOpen={open}
      onClick={closeModalHandler}
      paragraph={
        <p>
          Several different climate datasets are available in Kliima. Each
          dataset has different characteristics, so it is important to choose
          the dataset that is right for you.
          <br /> <br />
          General Circulation Models (GCMs) are physically based models which
          simulate the earth system under current and future climate conditions
          at a relatively coarse resolution. Regional climate models (RCMs)
          provide higher resolution climate projections for a limited region on
          the globe based on boundary conditions set by the coarse resolution
          GCMs. The sources we currently access are:
        </p>
      }
    >
      <>
        <div
          style={{
            width: "100%",
            display: "flex",
            marginTop: "15px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              flex: 1,
              gap: 30,
            }}
          >
            {climateDataSetCardData.map((item, i) => (
              <ClimateDataSetCard
                key={i}
                title={item.title}
                description={item.description}
              />
            ))}
          </div>
          <img
            src={climateDataSetSvg}
            alt="cimate data set"
            className={classes.img}
          />
        </div>
        <Grid item container justify="center" style={{ marginTop: "30px" }}>
          <Button
            className={classes.btn}
            variant="contained"
            color="primary"
            disableElevation
            style={{ backgroundColor: "#ADE5F7" }}
            onClick={() => {
              // setIsConfigureDatasetVisible(false);
              setIsClimateDatasetModalOpen(false);
              // setIsVariableSelectionModalOpen(true);
              setIsWelcomeToTheArupModalOpen(true);
            }}
          >
            Go Back
          </Button>
          <Button
            className={classes.btn}
            variant="contained"
            color="secondary"
            disableElevation
            style={{ marginLeft: "3.625rem", backgroundColor: "#F99052" }}
            onClick={() => {
              setIsClimateDatasetModalOpen(false);
              setIsDatasetSelectionModalOpen(true);
            }}
          >
            Continue
          </Button>
        </Grid>
      </>

      {/* <Grid container justify="center">
        {climateDataSetCardData.length > 4 && (
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={climateDataSetCardData.length}
            paginate={paginate}
            currentPageNumber={currentPage}
          />
        )}
      </Grid> */}
    </CustomModal>
  );
};

export default ClimateDataSetModal;
