import React, { useContext, useEffect, useState } from "react";
import { Box, Button, CircularProgress, makeStyles } from "@material-ui/core";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

// import CloseIcon from "../../../Icons/CloseIcon";
// import CustomDialog from "../../../partials/CustomDialog";
import CustomTabs from "../../../partials/CustomTabs";
import Output from "./Output";
// import Graph from "./Graph";
import { ReturnVoid } from "../../../../../types/types";
import CollapsibleCard from "../../../partials/CollapsibleCard";
import { CSSTransition } from "react-transition-group";
import { RegionContext } from "../../../context/RegionContext";
import { APP_VERSION } from "../../../../../constants";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    bottom: 10,
    left: "50%",
    transform: "translate(-50%, 0)",
    width: "95%",

    "& .MuiAccordionDetails-root": {
      display: "inherit",
    },
  },
}));

type PropsType = {
  open: boolean;
  dialogCloseHandler: ReturnVoid;
  downloadCsvHandler: (type: "raw" | "model") => void;
  backToHistoryHandler: ReturnVoid;
  selectedHistoryCardId?: number;
  datasource: string;
};

function HistoryViewDetailsDialog(props: PropsType) {
  const {
    open,
    dialogCloseHandler,
    downloadCsvHandler,
    backToHistoryHandler,
    selectedHistoryCardId,
    datasource,
  } = props;

  const classes = useStyles();

  const { historyTab } = useContext(RegionContext);
  const [historyTable] = historyTab;

  const [expanded, setExpanded] = useState(true);

  const [genratingPdf, setGenratingPdf] = useState(false);

  useEffect(() => {
    setExpanded(true);
  }, []);

  const printDocument = () => {
    const input = document.getElementById("tableToDownloaded")!;

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png", 1.0);
      const pdf = new jsPDF("p", "px", "a4");

      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();

      const widthRatio = pageWidth / canvas.width;
      const heightRatio = pageHeight / canvas.height;
      const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

      const canvasWidth = canvas.width * ratio;
      const canvasHeight = canvas.height * ratio;

      let multiple = 0;
      console.log(historyTable);

      // const tableMt =
      //   10 *
      //   (Object.keys(historyTable).length +
      //     historyTable["modelsUsed"].length +
      //     22);
      // pdf.addImage(imgData, "JPEG", 0, tableMt + 50, canvasWidth, canvasHeight);

      Object.keys(historyTable).forEach((key) => {
        if (Array.isArray(historyTable[key])) {
          const title = `${key.split("_").join(" ").toLowerCase()}:`;

          multiple++;
          key !== "table_data" &&
            key !== "modelsUsed" &&
            key !== "rx5" &&
            pdf.text(title, 10, 20 * multiple);

          key === "modelsUsed" && pdf.text("models used", 10, 20 * multiple);

          key === "modelsUsed" &&
            historyTable[key]?.forEach((item) => {
              multiple++;
              pdf.text(item.region, 10, 20 * multiple);

              item.models?.forEach((e) => {
                multiple++;
                pdf.text(e, 10, 20 * multiple);
              });
            });

          key !== "table_data" &&
            key !== "modelsUsed" &&
            historyTable[key].forEach((text: string) => {
              multiple++;
              pdf.text(` • ${text}`, 20, 18 * multiple);
            });
        } else if (typeof historyTable[key] === "object") {
          console.log(`This is an object do nothing`);
        } else if (
          typeof historyTable[key] === "string" ||
          typeof historyTable[key] === "number"
        ) {
          multiple++;
          if (key === "requested_time") {
            pdf.text(`version: ${APP_VERSION}`, 10, 20 * multiple);
            multiple++;
          }
          if (key === "requested_time") {
            pdf.text(
              `${key.split("_").join(" ")}: ${new Date(
                historyTable[key]
              ).toDateString()}`,
              10,
              20 * multiple
            );
          } else if (key === "historical_Period") {
            pdf.text(
              `${key.split("_")[0] + " period"}: ${historyTable[key]}`,
              10,
              20 * multiple
            );
          } else {
            console.log(historyTable[key]);

            pdf.text(
              `${key.split("_").join(" ")}: ${historyTable[key]}`,
              10,
              20 * multiple
            );
          }
        }
      });

      pdf.addPage();
      pdf.addImage(imgData, "JPEG", 5, 10, canvasWidth - 10, canvasHeight);

      setGenratingPdf(true);
      setTimeout(() => {
        pdf.save(
          `${historyTable["project_name"]}-${historyTable["requested_time"]}.pdf`
        );
        setGenratingPdf(false);
      }, 600);
    });
  };

  return (
    <CSSTransition in={open} timeout={300} classNames="item" unmountOnExit>
      <div className={classes.root}>
        <CollapsibleCard
          expanded={expanded}
          onChange={() => setExpanded((prevState) => !prevState)}
          width="100%"
          title={expanded ? "" : "Show Data Outputs"}
        >
          <Box paddingX="1rem">
            <CustomTabs
              tabNames={["OUTPUTS"]}
              tabContent={[
                <Output
                  selectedHistoryCardId={selectedHistoryCardId}
                  datasource={datasource}
                  key={0}
                />,
              ]}
            />

            <Box width="100%" display="flex" justifyContent="space-between">
              <Button
                style={{ width: "9.5rem" }}
                color="primary"
                disableElevation
                variant="contained"
                onClick={() => {
                  dialogCloseHandler();
                  backToHistoryHandler();
                }}
              >
                Back to History
              </Button>

              <Box>
                {datasource === "CORDEX" && (
                  <>
                  <Button
                    style={{ minWidth: "9.5rem", marginRight: 16 }}
                    color="primary"
                    disableElevation
                    variant="contained"
                    onClick={() => downloadCsvHandler("model")}
                  >
                    Download Per Model Data
                  </Button>
                  <Button
                    style={{ minWidth: "9.5rem", marginRight: 16 }}
                    color="primary"
                    disableElevation
                    variant="contained"
                    onClick={() => downloadCsvHandler("raw")}
                  >
                    Download Raw Data
                  </Button>
                  </>
                )}
                <Button
                  onClick={printDocument}
                  style={{ width: "9.5rem" }}
                  color="primary"
                  disableElevation
                  disabled={genratingPdf}
                  variant="contained"
                >
                  {genratingPdf ? (
                    <CircularProgress size={20} color="secondary" />
                  ) : (
                    "Download Report"
                  )}
                </Button>
              </Box>
            </Box>
          </Box>
        </CollapsibleCard>
      </div>
    </CSSTransition>
  );
}

export default HistoryViewDetailsDialog;
