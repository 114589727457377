import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

const MarineIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    width="68"
    height="52"
    viewBox="0 0 68 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M61.2 24.8637H68V31.6477H61.2C56.508 31.6477 51.884 30.4605 47.6 28.2557C39.1 32.6654 28.9 32.6654 20.4 28.2557C16.116 30.4605 11.458 31.6477 6.8 31.6477H0V24.8637H6.8C11.526 24.8637 16.252 23.2694 20.4 20.3522C28.696 26.1526 39.304 26.1526 47.6 20.3522C51.748 23.2694 56.474 24.8637 61.2 24.8637ZM61.2 4.51142H68V11.2955H61.2C56.508 11.2955 51.884 10.1083 47.6 7.90346C39.1 12.3131 28.9 12.3131 20.4 7.90346C16.116 10.1083 11.458 11.2955 6.8 11.2955H0V4.51142H6.8C11.526 4.51142 16.252 2.91716 20.4 0C28.696 5.80039 39.304 5.80039 47.6 0C51.748 2.91716 56.474 4.51142 61.2 4.51142ZM61.2 45.2159H68V52H61.2C56.508 52 51.884 50.8128 47.6 48.608C39.1 53.0176 28.9 53.0176 20.4 48.608C16.116 50.8128 11.458 52 6.8 52H0V45.2159H6.8C11.526 45.2159 16.252 43.6217 20.4 40.7045C28.696 46.5049 39.304 46.5049 47.6 40.7045C51.748 43.6217 56.474 45.2159 61.2 45.2159Z" />
  </SvgIcon>
);

export default MarineIcon;
