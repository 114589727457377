import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

const PlusIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    width="9"
    height="9"
    viewBox="0 0 9 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 5.14286L5.14286 5.14286L5.14286 9L3.85714 9L3.85714 5.14286L8.99206e-07 5.14286L6.74404e-07 3.85714L3.85714 3.85714L3.85714 -6.74404e-07L5.14286 -8.99206e-07L5.14286 3.85714L9 3.85714L9 5.14286Z"
      fill="#1C1C1C"
    />
  </SvgIcon>
);

export default PlusIcon;
