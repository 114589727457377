export const APP_VERSION = "v1.0-beta.1";

export const ukcpProbOutputKeys = [
    "10th_percentile_result",
    "50th_percentile_result",
    "90th_percentile_result",
  ];
  export const OtherDataserOutputKeys = [
    "min_model_result_RCP_one",
    "mean_model_result_RCP_one",
    "median_model_result_RCP_one",
    "max_model_result_RCP_one",
  ];
  export const hadukOutputKeys = ["result"];

  export const emailsArrray = [
    "kliima.arup@outlook.com",
    "arupkliima@outlook.com",
    "kliimatest@outlook.com",
    "kliimadigiryte@outlook.com",
    "arup.kliima@outlook.com",
    "kliimateam@outlook.com",
  ];