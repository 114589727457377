/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import SearchBox from "../../partials/SearchBox";
import CustomDropdown from "../../partials/CustomDropdown";
import LatLonTextField from "../../partials/LatLonTextField";
import ReadOnlyLatLonTextField from "../../partials/ReadOnlyLatLonTextField";
import { v4 as uuidv4 } from "uuid";
import { RegionContext } from "../../context/RegionContext";
import { Button } from "@material-ui/core";

import MapMarkerIcon from "../../Icons/MapMarkerIcon";
import PlusIcon from "../../Icons/PlusIcon";

import CsvPopup from "./CsvPopup";
import { LocationSelectContext } from "../../context/LocationSelectPageContext";
import { CSSTransition } from "react-transition-group";
import { useSelectLocationStyles } from "./useStyles";
import { SnackbarContext } from "../../context/SnackbarContext";
import { validateLatLong } from "../../../../utility/helperFunctions";
import { CustomDropdownMenuItemsType } from "../../../../types/types";
import {
  availableDatasets,
  CORDEX,
  HAD_UK,
  LOCA,
  UKCP18_HIGH_RES,
  UKCP18_PROB,
} from "./datasets";

const AVAILABLE_REGIONS = [
  "Africa",
  "North America",
  "East Asia",
  "South America",
  "Australasia",
  "South East Asia",
];

const regionsNotToShow = [
  "Antarctica",
  "Arctic",
  "Middle East and North Africa",
  "Mediterranean",
];

interface ChoiceSelected {
  region: string;
  country: string;
  Longitude?: any;
  Latitude?: any;
  zoom: number;
  shortName?: string;
}

interface PropsType {
  isVisible: boolean;
}

const SelectLocation = (props: PropsType) => {
  const classes = useSelectLocationStyles();

  const { isVisible } = props;

  const {
    snackbarState: { setSnackbar },
  } = useContext(SnackbarContext);

  //------------------------------------------- Variable Selection Modal state -------------------------------------------//
  const {
    variableSelectionModalState,
    selectClimateVariableCardVisibleState,
    welcomeToTheArupModalState,
    regionDpdOpenState,
    configureDatasetVisibleState,
    projectDescriptionModalState,
    windRelatedVariablesModalState,
    climateDatasetModalState,
    datasetSelectionModalState,
    otherToolCardVisibileState,
    retrivableData,
    selectedDatasetState,
    handlers,
    doesEachFieldOfConfigDatasetCardHasValueState,
    selectedClimateVariableState,
    selectedClimateVariableSourceState,
    selectedResolutionState,
    selectLocationCardVisibleState,
    timePeriodSelectionModalState,
    climateDataHistoryModalState,
    lessThanMoreThanValuesState,
    metricState,
    historicalYearState,
    timeDurationState,
    selectedProjectedYearState,
  } = useContext(LocationSelectContext);

  const { setSelectedProjectedYear } = selectedProjectedYearState;

  const { setHistoricalYear } = historicalYearState;

  const { setTimePeriodSelectionModal } = timePeriodSelectionModalState;

  const { setIsClimateDataHistoryModalOpen } = climateDataHistoryModalState;

  const {
    setSelectedClimateVariableSource,
  } = selectedClimateVariableSourceState;
  const { setSelectedResolution } = selectedResolutionState;

  const { setIsSelectLocationCardVisible } = selectLocationCardVisibleState;

  const { setSelectedClimateVariable } = selectedClimateVariableState;

  const [retriveData, setRetriveData] = retrivableData;

  const { setIsWelcomeToTheArupModalOpen } = welcomeToTheArupModalState;

  const { setIsProjectDescriptionModalOpen } = projectDescriptionModalState;

  const { setIsWindRelatedVariablesModalOpen } = windRelatedVariablesModalState;

  const { setIsClimateDatasetModalOpen } = climateDatasetModalState;

  const { setIsDatasetSelectionModalOpen } = datasetSelectionModalState;

  const { setIsOtherToolCardVisibile } = otherToolCardVisibileState;

  const { setLessThanMoreThanValues } = lessThanMoreThanValuesState;

  const { state, setState } = metricState;

  //------------------------------------------- select climate variable card state -------------------------------------------//
  // if it is true; select & continue and clear buttons will be unmounted from this components
  const { setIsVariableSelectionModalOpen } = variableSelectionModalState;

  const { isRegionDpdOpen, setIsRegionDpdOpen } = regionDpdOpenState;

  const {
    isSelectClimateVariableCardVisible,
    setIsSelectClimateVariableCardVisible,
  } = selectClimateVariableCardVisibleState;

  const { closeModalsHandler } = handlers;
  const { setIsConfigureDatasetVisible } = configureDatasetVisibleState;

  const {
    regionName,
    mapdata,
    regionColor,
    view,
    geoJSON,
    mapViewPort,
    getlatLon,
    markerDroper,
    markerClick,
  } = useContext(RegionContext);

  const {
    setDoesEachFieldOfConfigDatasetCardHasValue,
  } = doesEachFieldOfConfigDatasetCardHasValueState;

  const [region, setRegion] = regionName;

  const [map, setMap] = mapdata;
  const [regionList, SetRegionList] = useState([]);
  const [countryList, SetCountryList] = useState([" "]);
  const [countriesLongitude, setcountriesLongitude] = useState<any>(0);
  const [countriesLatitude, setcountriesLatitude] = useState<any>(0);
  const [countryShortName, setCountryShortName] = useState<any>(0);

  const [colorRegion, setcolorRegion] = regionColor;

  const [userView, setuserView] = view;
  const [geoJson, setGeoJson] = geoJSON;

  const [zoom, setZoom] = useState(0);
  const [viewport, setViewport] = mapViewPort;
  const [latLon, setLatLon] = getlatLon;
  const [markerDroped, setMarkerDroped] = markerDroper;
  let [elmCount, setElmCount] = useState(0);

  const [error, setError] = useState("");

  const [clickedMarker, setClickedMarker] = markerClick;
  const [selectedChoice, setSelectedChoice] = useState<ChoiceSelected>({
    region: "",
    country: "",
    Longitude: 78.387451,
    Latitude: 11.059821,
    zoom: 0,
    shortName: "",
  });

  const [datasets, setDatasets] = useState<CustomDropdownMenuItemsType[]>([]);
  const [disabledFields, setDisabledFields] = useState(false);
  const { selectedDataset, setSelectedDataset } = selectedDatasetState;

  const [availableRegions, setAvailableRegions] = useState(AVAILABLE_REGIONS);

  useEffect(() => {
    if (selectedDataset === CORDEX) {
      const cordexRegions = [...AVAILABLE_REGIONS].filter(
        (item) => item !== AVAILABLE_REGIONS[4]
      );

      setAvailableRegions(cordexRegions);
    }
  }, [selectedDataset]);

  // const datasetChangeHandler = (e) => {
  //   const value = e?.target?.value;

  //   setIsWelcomeToTheArupModalOpen(false);
  //   setIsClimateDatasetModalOpen(true);
  //   setSelectedDataset(value);
  // };

  let countryID = [];
  const dropdownSelectHandler = (evt) => {
    // setIsDatasetSelectionModalOpen(false);
    // setIsClimateDatasetModalOpen(false);
    const selectedValue = evt.target.value;
    let regionLan: number = 0;
    let regionLat: number = 0;

    region.map((item: any) => {
      if (item.region === selectedValue) {
        countryID = item.CountryList;
        regionLan = item.Longitude;
        regionLat = item.Latitude;
      }
    });

    setSelectedChoice((prevState) => ({
      ...prevState,
      [evt.target.name]: selectedValue,
      Longitude: regionLan,
      Latitude: regionLat,
      zoom: 3.7,
    }));

    const get_countriesName = countryID.map((item: any) => {
      return item.name;
    });
    const get_countriesLongitude = countryID.map((item: any) => {
      return item.Longitude;
    });
    const get_countriesLatitude = countryID.map((item: any) => {
      return item.Latitude;
    });
    const get_CountryShortName = countryID.map((item: any) => {
      return item.shortName;
    });

    SetCountryList(get_countriesName);
    setcountriesLongitude(get_countriesLongitude);
    setcountriesLatitude(get_countriesLatitude);
    setCountryShortName(get_CountryShortName);
    setuserView("REGION_VIEW");
    const settingLaong: number = Number(regionLan);
    const settingLati: number = Number(regionLat);
    setViewport({
      ...viewport,
      zoom: 0,
      latitude: settingLati,
      longitude: settingLaong,
    });
    setLessThanMoreThanValues({
      thresholdValueLessThan: null,
      thresholdValueMoreThan: null,
    });
    setState({
      ...state,
      metric: "",
    });
  };

  const countrySelectHandler = (evt) => {
    const get_countriesName = countryID.map((item: any) => {
      return item.name;
    });
    const get_countriesLongitude = countryID.map((item: any) => {
      return item.Longitude;
    });
    const get_countriesLatitude = countryID.map((item: any) => {
      return item.Latitude;
    });
    const get_CountryShortName = countryID.map((item: any) => {
      return item.shortName;
    });

    const selectedValue = evt.target.value;
    const pos = get_countriesName.length
      ? get_countriesName.indexOf(selectedValue)
      : countryList.indexOf(selectedValue);

    setSelectedChoice((prevState) => ({
      ...prevState,
      [evt.target.name]: selectedValue,
      Longitude: get_countriesLongitude.length
        ? get_countriesLongitude[pos]
        : countriesLongitude[pos],
      Latitude: get_countriesLatitude.length
        ? get_countriesLatitude[pos]
        : countriesLatitude[pos],
      zoom: 4,
      shortName: get_CountryShortName.length
        ? get_CountryShortName[pos]
        : countryShortName[pos],
    }));

    setuserView("COUNTRY_VIEW");
    const settingLaongCountry: number = Number(
      get_countriesLongitude.length
        ? get_countriesLongitude[pos]
        : countriesLongitude[pos]
    );
    const settingLatiCountry: number = Number(
      get_countriesLatitude.length
        ? get_countriesLatitude[pos]
        : countriesLatitude[pos]
    );
    setViewport({
      ...viewport,
      zoom: 3,
      latitude: settingLatiCountry,
      longitude: settingLaongCountry,
    });
    setLessThanMoreThanValues({
      thresholdValueLessThan: null,
      thresholdValueMoreThan: null,
    });
    setState({
      ...state,
      metric: "",
    });
  };

  useEffect(() => {
    // This is for auto populating region and county according to the selected dataset
    // if selected dataset is LOCA
    if (selectedDataset === LOCA) {
      // mocking event object
      setDisabledFields(true);
      dropdownSelectHandler({
        target: { value: "North America", name: "region" },
      });

      countrySelectHandler({
        target: { value: "United States", name: "country" },
      });
    } else if (
      // UKCP-18
      selectedDataset === UKCP18_PROB ||
      selectedDataset === UKCP18_HIGH_RES ||
      // HAD-UK
      selectedDataset === HAD_UK
    ) {
      setDisabledFields(true);
      dropdownSelectHandler({
        target: { value: "Europe", name: "region" },
      });

      countrySelectHandler({
        target: { value: "United Kingdom", name: "country" },
      });
    } else {
      setDisabledFields(false);
      setSelectedChoice({
        region: "",
        country: "",
        Longitude: 78.387451,
        Latitude: 11.059821,
        zoom: 0,
        shortName: "",
      });
    }
  }, [selectedDataset]);

  const sendDatatoMap = () => {
    dropingMarker();
    setMap(selectedChoice);

    // setting country and region name in the global context state
    setRetriveData((prevState) => {
      return {
        ...prevState,
        region: selectedChoice.region,
        country: selectedChoice.country,
      };
    });

    // closing the welcome to the arup modal
    setIsWelcomeToTheArupModalOpen(false);

    // opening second modal after closing previous one
    setIsVariableSelectionModalOpen(true);

    //it will render the selectClimateVariableCard
    setIsSelectClimateVariableCardVisible(true);

    // closing climate other tools card
    setIsOtherToolCardVisibile(false);
  };

  const clearData = () => {
    if (
      selectedDataset === LOCA ||
      selectedDataset === UKCP18_PROB ||
      selectedDataset === UKCP18_HIGH_RES ||
      selectedDataset === HAD_UK
    ) {
      setSelectedChoice({
        region: selectedChoice.region,
        country: selectedChoice.country,
        Longitude: 0,
        Latitude: 0,
        zoom: 0,
        shortName: "",
      });
      setGeoJson((prevState) => {
        return {
          ...prevState,
          features: [],
        };
      });
    } else {
      setSelectedChoice({
        region: "",
        country: "",
        Longitude: 78.387451,
        Latitude: 11.059821,
        zoom: 0,
        shortName: "",
      });
      setGeoJson((prevState) => {
        return {
          ...prevState,
          features: [
            {
              type: "Feature",
              properties: {
                title: "blank",
                short_name: "blank",
                region: "blank",
                region_short: "blank",
              },
              geometry: {
                type: "Point",
                coordinates: [1, 1],
              },
            },
          ],
        };
      });
    }
  };

  let uploadStatus = false;

  /*-------------------------ADD LAT LANG COMPONENT-----------------------------------------*/

  const handleRemoveClick = (index, name) => {
    setElmCount((elmCount -= 1));

    var filtered = geoJson.features.filter(function (key, value) {
      return key.properties.title !== name;
    });

    setGeoJson({
      ...geoJson,
      features: [...filtered],
    });
  };

  //------------------------------------------------------------------------------------------

  const dropingMarker = () => {
    const latVP = viewport.latitude;
    const lonVP = viewport.longitude;
    if (markerDroped === false) {
      setMarkerDroped(true);
    } else {
      setMarkerDroped(false);
    }
    setViewport({
      ...viewport,
      zoom: viewport.zoom,
      latitude: latVP,
      longitude: lonVP,
    });
  };
  const handleAddClick = () => {
    if (!validateLatLong(latLon.lat, latLon.lon)) {
      setSnackbar({
        open: true,
        color: "error",
        message: "Error: Please Enter Valid Coordinates!",
      });
      return;
    }

    uploadStatus = true;
    const fetchGeoJsona = async () => {
      const data = await geoJson;
      return data;
    };

    const maxElementCount =
      process.env.REACT_APP_ENV === "development" ? 30 : 30;

    if (elmCount < maxElementCount) {
      fetchGeoJsona()
        .catch((e) => {})
        .then((data) => {
          const res = data["features"];
          const val = [latLon.lat, latLon.lon];
          let isDuplicateCordinate = false;

          res.map(function (item) {
            if (
              JSON.stringify(item.geometry.coordinates) === JSON.stringify(val)
            ) {
              isDuplicateCordinate = true;
            }
          });

          if (isDuplicateCordinate) {
            setSnackbar({
              open: true,
              color: "error",
              message: "Error: The same co-ordinate has been duplicated ",
            });
            setElmCount(elmCount - 1);
            return;
          }

          setGeoJson({
            ...geoJson,
            features: [
              ...geoJson.features,
              {
                type: "Feature",
                properties: {
                  title: uuidv4(),
                  short_name: "blank",
                  region: "blank",
                  region_short: "blank",
                },
                geometry: {
                  type: "Point",
                  coordinates: [latLon.lat, latLon.lon],
                },
              },
            ],
          });
        });
    } else {
      setSnackbar({
        open: true,
        color: "error",
        message: `Error: The maximum number of co-ordinates is ${maxElementCount}`,
      });
      setElmCount(maxElementCount);
    }

    const lat: number = latLon.lat;
    const lon: number = latLon.lon;

    setViewport((prevState) => ({
      ...prevState,
      latitude: Number(lat),
      longitude: Number(lon),
    }));

    setLatLon({
      lat: "",
      lon: "",
    });
    setElmCount((elmCount += 1));
  };

  const markerSelected = (index) => {
    if (index === clickedMarker && clickedMarker > 0) {
      return <div className="marker-identifier"></div>;
    } else {
      return;
    }
  };

  // for dataset dropdown options
  useEffect(() => {
    const sets: CustomDropdownMenuItemsType[] = [];

    availableDatasets.forEach((item) => {
      sets.push({ label: item.name, disabled: !item.isAvailable });
    });

    setDatasets(sets);
  }, []);

  useEffect(() => {
    setMap(selectedChoice);

    const regionList = region.map((item: any) => {
      return item.region;
    });

    let uniqueRegion: any = [...new Set(regionList)];
    SetRegionList(uniqueRegion);

    setcolorRegion(countryShortName);

    // if there is a selected value in the dropdown; the welcom modal and
    // the other tools card will be closed
    if (selectedChoice.region) {
      setIsWelcomeToTheArupModalOpen(false);
      setIsOtherToolCardVisibile(false);
    }
  }, [selectedChoice, geoJson, latLon]);

  // Show the warning message if user selects the North america or Africa
  useEffect(() => {
    selectedChoice.region &&
      setSnackbar({
        open: true,
        color: "warning",
        message: "Warning: Please be aware that the outputs have not been QA'd",
      });
  }, [selectedChoice.region]);

  // should button diabled
  const shouldButtonDisabled = () => {
    if (
      !selectedDataset ||
      !selectedChoice.region ||
      !selectedChoice.country ||
      !(geoJson.features.length > 1)
    ) {
      return true;
    }
    return false;
  };

  React.useEffect(() => {
    const result = !shouldButtonDisabled();
    setDoesEachFieldOfConfigDatasetCardHasValue(result);
  }, [
    selectedDataset,
    selectedChoice.region,
    selectedChoice.country,
    geoJson.features.length,
  ]);

  return (
    <CSSTransition in={isVisible} timeout={300} classNames="item" unmountOnExit>
      <SearchBox
        overlay={isSelectClimateVariableCardVisible}
        title="Select a location..."
        isBtnDisabled={!selectedChoice.region}
        editClickHandler={() => {
          setSelectedProjectedYear("");
          timeDurationState[1]("");
          setHistoricalYear({ from: "", to: "" });
          setIsWelcomeToTheArupModalOpen(false);
          setIsVariableSelectionModalOpen(false);
          setIsSelectClimateVariableCardVisible(false);
          setIsWindRelatedVariablesModalOpen(false);
          setIsConfigureDatasetVisible(false);
          setIsDatasetSelectionModalOpen(false);
          setIsClimateDataHistoryModalOpen(false);
          setIsProjectDescriptionModalOpen(false);
          setIsClimateDatasetModalOpen(false);
          setIsOtherToolCardVisibile(false);
          setTimePeriodSelectionModal(false);
          setIsSelectLocationCardVisible(false);
          setIsSelectLocationCardVisible(true);
          setIsDatasetSelectionModalOpen(false);
          setSelectedResolution("");
          setSelectedClimateVariableSource("");
          setSelectedClimateVariable("");
        }}
        infoClickHandler={() => {
          setIsWelcomeToTheArupModalOpen(true);
          closeModalsHandler();
        }}
      >
        {/* <CustomDropdown
          value={selectedDataset}
          onChange={datasetChangeHandler}
          label="Dataset:"
          placeholder="Select One"
          name="dataset"
          menuItems={datasets}
          isReadOnly={isSelectClimateVariableCardVisible}
        /> */}

        <CustomDropdown
          onChange={dropdownSelectHandler}
          name="region"
          menuItems={regionList
            .sort()
            .filter((e) => !regionsNotToShow.includes(e))
            .map((e) => ({
              label: e,
              disabled: !availableRegions.includes(e),
            }))}
          label="Region:"
          placeholder="Select a region"
          value={selectedChoice.region}
          isDropdownOpen={isRegionDpdOpen}
          closeDropDownHandler={() => setIsRegionDpdOpen(false)}
          openDropdownHandler={() => setIsRegionDpdOpen(true)}
          isReadOnly={isSelectClimateVariableCardVisible || disabledFields}
        />
        <CustomDropdown
          name="country"
          menuItems={countryList.sort().map((e) => ({ label: e }))}
          label="Country:"
          placeholder="Select a country"
          onChange={countrySelectHandler}
          value={selectedChoice.country}
          isReadOnly={isSelectClimateVariableCardVisible || disabledFields}
        />
        {geoJson.features.map((item, i) => {
          return (
            <div
              className="box"
              key={item.properties.title}
              id={item.properties.title}
            >
              {markerSelected(i)}

              {i > 0 && (
                <ReadOnlyLatLonTextField
                  className="markerIdent"
                  key={item.properties.title}
                  lat={item.geometry.coordinates[0].toFixed(3)}
                  lon={item.geometry.coordinates[1].toFixed(3)}
                  onDeleteHandler={() =>
                    handleRemoveClick(i, item.properties.title)
                  }
                  disabled={isSelectClimateVariableCardVisible}
                />
              )}
            </div>
          );
        })}
        <LatLonTextField
          firstTitle="Lat"
          secondTitle="Lon"
          name1="lat"
          name2="lon"
          value2={latLon.lon}
          value1={latLon.lat}
          id="mainField"
          icon={<PlusIcon style={{ fontSize: "9px" }} />}
          onIconClick={handleAddClick}
          onChange={(evt) => {
            const value =
              Number(evt.target.value) === 0
                ? evt.target.value
                : Number(evt.target.value)?.toFixed(3)!;
            setLatLon({
              ...latLon,
              [evt.target.name]: Number(value) === 0 ? value : Number(value),
            });
          }}
          isReadOnly={isSelectClimateVariableCardVisible}
        />
        <span
          style={{
            fontSize: "0.6875rem",
            display: "inline-block",
            marginBottom: "1.0625rem",
            pointerEvents: isSelectClimateVariableCardVisible ? "none" : "auto",
          }}
        >
          <span
            style={{
              color: "#EF4F4F",
              textDecoration: "underline",
              fontSize: "10px",
              cursor: "pointer",
            }}
            onClick={dropingMarker}
          >
            Drop a Map Pin <MapMarkerIcon style={{ fontSize: "0.6875rem" }} />
          </span>
          or &nbsp;
          <CsvPopup />
        </span>
        <CSSTransition
          in={!isSelectClimateVariableCardVisible}
          timeout={300}
          classNames="item"
          unmountOnExit
        >
          <div className={classes.btnContainer}>
            <Button
              disabled={shouldButtonDisabled()}
              disableElevation
              variant="contained"
              color="primary"
              className={classes.btn}
              style={{ marginRight: "16px" }}
              onClick={clearData}
            >
              Clear All
            </Button>
            <Button
              onClick={sendDatatoMap}
              color="secondary"
              disabled={shouldButtonDisabled()}
              disableElevation
              variant="contained"
              className={classes.btn}
            >
              Select &amp; Continue
            </Button>
          </div>
        </CSSTransition>
      </SearchBox>
    </CSSTransition>
  );
};

export default SelectLocation;
