import React, { useContext, useEffect, useState } from "react";
import SearchBox from "../../partials/SearchBox";
import CustomDropdown from "../../partials/CustomDropdown";

import { Button, Chip } from "@material-ui/core";
import { LocationSelectContext } from "../../context/LocationSelectPageContext";
import { CSSTransition } from "react-transition-group";
import MoreThanLessThanTextField from "../../partials/MoreThanLessThanTextField";

import { doesStringContainThisText } from "../../../../utility/helperFunctions";

import {
  temperatureRelatedSources,
  windRelatedSources,
  humdityRelatedSources,
  snowRelatedSources,
  marineRelatedDataSources,
  precipitationRelatedDataSources,
  Ukcp18HighResTemperatureRelatedResources,
  HadukTemperatureRelatedResourcesBottom,
  HadukPrecipationRelatedResourcesBottom,
  temperatureRelatedSourcesLOCA,
} from "./WindRelatedVariablesModal/data";
import {
  HadukPrecipationRelatedResourcesTop,
  Ukcp18ProbTempratureRelatedResources,
  HadukTemperatureRelatedResourcesTop,
  Ukcp18ProbPrecipationRelatedResources,
} from "./WindRelatedVariablesModal/data";

import {
  SelectClimateVariablePropTypes,
  useSelectClimateVariableStyles,
} from "./useStyles";
import { CustomDropdownMenuItemsType } from "../../../../types/types";
import { CORDEX, HAD_UK, LOCA, UKCP18_HIGH_RES, UKCP18_PROB } from "./datasets";

const variables: Array<{ name: string; isAvailable: boolean }> = [
  { name: "Temperature", isAvailable: true },
  { name: "Wind", isAvailable: false },
  { name: "Humidity", isAvailable: false },
  { name: "Snow", isAvailable: false },
  { name: "Marine", isAvailable: false },
  { name: "Precipitation", isAvailable: true },
];

const SelectClimateVariable = (props: SelectClimateVariablePropTypes) => {
  const classes = useSelectClimateVariableStyles();

  const { isVisible } = props;

  const {
    selectedClimateVariableState,
    windRelatedVariablesModalState,
    configureDatasetVisibleState,
    variableSelectionModalState,
    datasetSelectionModalState,
    selectedClimateVariableSourceState,
    climateDatasetModalState,
    lessThanMoreThanValuesState,
    projectDescriptionModalState,
    selectedDatasetState,
    timePeriodSelectionModalState,
    resolutionErrorState,
    selectedResolutionState,
    metricState,
    timeDurationState,
  } = useContext(LocationSelectContext);

  const { selectedDataset } = selectedDatasetState;

  const { setIsResolutionSelected } = resolutionErrorState;

  const { selectedResolution, setSelectedResolution } = selectedResolutionState;

  const { selectedClimateVariable, setSelectedClimateVariable } =
    selectedClimateVariableState;

  const { setIsWindRelatedVariablesModalOpen } = windRelatedVariablesModalState;

  const { isConfigureDatasetVisible, setIsConfigureDatasetVisible } =
    configureDatasetVisibleState;

  const { setIsVariableSelectionModalOpen } = variableSelectionModalState;

  const { setIsDatasetSelectionModalOpen } = datasetSelectionModalState;

  const { setIsClimateDatasetModalOpen } = climateDatasetModalState;

  const { setIsProjectDescriptionModalOpen } = projectDescriptionModalState;

  const { setTimePeriodSelectionModal } = timePeriodSelectionModalState;

  const { selectedClimateVariableSource, setSelectedClimateVariableSource } =
    selectedClimateVariableSourceState;

  const { setLessThanMoreThanValues, lessThanMoreThanValues } =
    lessThanMoreThanValuesState;

  const [shouldShowMoreThanLessThanField, setShouldShowMoreThanLessThanField] =
    useState(false);

  const [variableUnit, setVariableUnit] = useState("");

  const { state, setState } = metricState;

  const dropdownSelectHandler = (evt) => {
    const selectedValue = evt.target.value;

    setState({
      ...state,
      [evt.target.name]: selectedValue,
    });

    setSelectedClimateVariableSource(selectedValue);
  };

  useEffect(() => {
    if (state.metric) {
      setIsVariableSelectionModalOpen(false);
      setIsWindRelatedVariablesModalOpen(true);
    }
    //eslint-disable-next-line
  }, [state.metric]);

  useEffect(() => {
    if (selectedClimateVariableSource) {
      setState({
        ...state,
        metric: selectedClimateVariableSource,
      });
    }

    if (
      doesStringContainThisText(state.metric, "more than") ||
      doesStringContainThisText(state.metric, "less than")
    ) {
      setShouldShowMoreThanLessThanField(true);
    } else {
      setShouldShowMoreThanLessThanField(false);
    }
    //eslint-disable-next-line
  }, [state.metric, selectedClimateVariableSource]);

  //-------------------------------------------------------------------------------------
  const [dpdItems, setDpdItems] = useState<CustomDropdownMenuItemsType[]>([]);

  useEffect(() => {
    switch (selectedClimateVariable.toLowerCase()) {
      case "temperature":
        {
          let items: CustomDropdownMenuItemsType[] = [];
          if (selectedDataset === HAD_UK) {
            let hadUkArray: CustomDropdownMenuItemsType[] = [];
            HadukTemperatureRelatedResourcesTop.map((item) =>
              hadUkArray.push({ label: item.title })
            );
            HadukTemperatureRelatedResourcesBottom.map((item) =>
              hadUkArray.push({ label: item.title })
            );

            setDpdItems(hadUkArray);
          } else if (selectedDataset === UKCP18_PROB) {
            let itemArray: CustomDropdownMenuItemsType[] = [];
            Ukcp18ProbTempratureRelatedResources.map((item) =>
              itemArray.push({ label: item.title })
            );
            setDpdItems(itemArray);
          } else if (selectedDataset === UKCP18_HIGH_RES) {
            let highResArray: CustomDropdownMenuItemsType[] = [];
            Ukcp18HighResTemperatureRelatedResources.map((item) =>
              highResArray.push({ label: item.title })
            );
            setDpdItems(highResArray);
          } else if (selectedDataset === LOCA) {
            let locaArray: CustomDropdownMenuItemsType[] = [];
            temperatureRelatedSourcesLOCA.map((item) =>
              locaArray.push({ label: item.title })
            );
            setDpdItems(locaArray);
          } else {
            let cordexArray: CustomDropdownMenuItemsType[] = [];
            temperatureRelatedSources.map((item) =>
              cordexArray.push({ label: item.title })
            );
            setDpdItems(cordexArray);
          }
          setVariableUnit("°C");
        }
        break;
      // Ukcp18HighResTemperatureRelatedResources
      case "wind":
        {
          let items: CustomDropdownMenuItemsType[] = [];
          windRelatedSources.map((item) => items.push({ label: item.title }));
          setDpdItems(items);
          setVariableUnit("m/s");
        }
        break;

      case "humidity":
        {
          let items: CustomDropdownMenuItemsType[] = [];
          humdityRelatedSources.map((item) =>
            items.push({ label: item.title })
          );
          setDpdItems(items);
          setVariableUnit("%");
        }
        break;

      case "snow":
        {
          let items: CustomDropdownMenuItemsType[] = [];
          snowRelatedSources.map((item) => items.push({ label: item.title }));
          setDpdItems(items);
          setVariableUnit("m");
        }
        break;

      case "marine":
        {
          let items: CustomDropdownMenuItemsType[] = [];
          marineRelatedDataSources.map((item) =>
            items.push({ label: item.title })
          );
          setDpdItems(items);
          setVariableUnit("m");
        }
        break;

      case "precipitation":
        {
          let items: CustomDropdownMenuItemsType[] = [];
          if (selectedDataset === HAD_UK) {
            let hadukArray: CustomDropdownMenuItemsType[] = [];
            HadukPrecipationRelatedResourcesTop.map((item) =>
              hadukArray.push({ label: item.title })
            );
            HadukPrecipationRelatedResourcesBottom.map((item) =>
              hadukArray.push({ label: item.title })
            );
            setDpdItems(hadukArray);
          } else if (selectedDataset === UKCP18_PROB) {
            let ukProbArray: CustomDropdownMenuItemsType[] = [];
            Ukcp18ProbPrecipationRelatedResources.map((item) =>
              ukProbArray.push({ label: item.title })
            );
            setDpdItems(ukProbArray);
          } else if (selectedDataset === UKCP18_HIGH_RES) {
            let ukHighRes: CustomDropdownMenuItemsType[] = [];
            HadukPrecipationRelatedResourcesBottom.map((item) =>
              ukHighRes.push({ label: item.title })
            );
            setDpdItems(ukHighRes);
          } else {
            let arrayItems: CustomDropdownMenuItemsType[] = [];
            selectedDataset === CORDEX
              ? [
                  {
                    title: "Maximum 1 day rainfall",
                    unit: "Rx1day Units: %",
                    description:
                      "The change in annual maximum value of average daily precipitation",
                  },
                  {
                    title: "Maximum 5 day rainfall",
                    unit: "Rx5day Units: %",
                    description:
                      "The change in annual maximum value of average daily precipitation over 5 consecutive days",
                  },
                  {
                    title: "Cumulative dry days",
                    unit: "CDD Units: %",
                    description:
                      "Maximum length of dry spell (consecutive days where daily precipitation is less than 1mm)",
                  },
                  {
                    title: "Average daily precipitation",
                    unit: "Pr Units: %",
                    description: "The change in average daily precipitation ",
                  },
                  {
                    title: "Precipitation threshold more than",
                    unit: "Units: no. days",
                    description:
                      "Annual count when average daily precipitation is above threshold value",
                  },
                  {
                    title: "Precipitation threshold less than",
                    unit: "Units: no. days",
                    description:
                      "Annual count when average daily precipitation is below threshold value",
                  },
                ].map((item) => arrayItems.push({ label: item.title }))
              : precipitationRelatedDataSources.map((item) =>
                  arrayItems.push({ label: item.title })
                );
            setDpdItems(arrayItems);
          }
          setVariableUnit("mm");
        }
        break;

      default:
        break;
    }
  }, [selectedClimateVariable]);

  const updateThresholdLessThanMoreThanValuesHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    if (doesStringContainThisText(state.metric, "less than")) {
      setLessThanMoreThanValues({
        ...lessThanMoreThanValues,

        thresholdValueLessThan: value.replace(
          /[A-Za-z,/]/,
          ""
        ) as unknown as number,
      });
    } else {
      setLessThanMoreThanValues({
        ...lessThanMoreThanValues,
        thresholdValueMoreThan: value.replace(
          /[A-Za-z,/]/,
          ""
        ) as unknown as number,
      });
    }
  };

  // if input field is not visibile then sets it's value to zero
  useEffect(() => {
    if (!shouldShowMoreThanLessThanField) {
      setLessThanMoreThanValues({
        thresholdValueLessThan: null,
        thresholdValueMoreThan: null,
      });
    }
    //eslint-disable-next-line
  }, [shouldShowMoreThanLessThanField]);

  // it returns value for the lessThanMoreThan field according to the less than or more than field
  const value = () => {
    if (doesStringContainThisText(state.metric, "less than")) {
      return lessThanMoreThanValues.thresholdValueLessThan;
    }

    return lessThanMoreThanValues.thresholdValueMoreThan;
  };

  const closeModalsHandler = () => {
    setIsConfigureDatasetVisible(false);
    setIsClimateDatasetModalOpen(false);
    setIsDatasetSelectionModalOpen(false);
    setIsProjectDescriptionModalOpen(false);
    setTimePeriodSelectionModal(false);
  };

  const clearAllHandler = () => {
    setIsVariableSelectionModalOpen(true);
    setIsWindRelatedVariablesModalOpen(false);
    setState({
      metric: "",
    });
    setVariableUnit("");
    // setIsVariableSelectionModalOpen(true);
    setSelectedClimateVariableSource("");
    setLessThanMoreThanValues({
      thresholdValueLessThan: null,
      thresholdValueMoreThan: null,
    });
    setSelectedClimateVariable("");
  };

  const checkIsResolutionSelected = () => {
    if (selectedDataset === UKCP18_HIGH_RES && selectedResolution === "") {
      setIsResolutionSelected(false);
    } else {
      setIsConfigureDatasetVisible(true);
      setIsVariableSelectionModalOpen(false);
      // setIsClimateDatasetModalOpen(true);
      setIsWindRelatedVariablesModalOpen(false);
      // setIsProjectDescriptionModalOpen(true)
      setTimePeriodSelectionModal(true);
    }
  };

  return (
    <CSSTransition in={isVisible} timeout={300} classNames="item" unmountOnExit>
      <div>
        <SearchBox
          overlay={isConfigureDatasetVisible}
          title="Select Climate Variable..."
          isBtnDisabled={!state.metric}
          editClickHandler={() => {
            closeModalsHandler();
            setIsWindRelatedVariablesModalOpen(true);
            timeDurationState[1]("");
          }}
          infoClickHandler={() => {
            setIsVariableSelectionModalOpen(true);
            setIsWindRelatedVariablesModalOpen(false);
            setTimePeriodSelectionModal(false);
            closeModalsHandler();
          }}
        >
          <div className={classes.chipContainer}>
            {variables.map((item, i) => (
              <Chip
                key={i}
                onClick={() => {
                  setSelectedResolution("");
                  setLessThanMoreThanValues({
                    ...lessThanMoreThanValues,

                    thresholdValueLessThan: null,
                    thresholdValueMoreThan: null,
                  });
                  setSelectedClimateVariableSource("");
                  setSelectedClimateVariable(item.name);
                  setIsVariableSelectionModalOpen(false);
                  setIsWindRelatedVariablesModalOpen(true);
                  setState({
                    ...state,
                    metric: "",
                  });
                }}
                className={classes.chip}
                label={item.name}
                variant={
                  item.name === selectedClimateVariable ? "default" : "outlined"
                }
                style={{
                  color:
                    item.name === selectedClimateVariable ? "#000" : "#9B9B9B",
                  background:
                    item.name === selectedClimateVariable ? " #ADE5F7" : "",
                  border:
                    item.name === selectedClimateVariable
                      ? "1.21806px solid black"
                      : "",
                  pointerEvents:
                    isConfigureDatasetVisible || !item.isAvailable
                      ? "none"
                      : "auto",
                }}
                disabled={!item.isAvailable}
              />
            ))}
          </div>

          <CustomDropdown
            value={state.metric}
            onChange={dropdownSelectHandler}
            name="metric"
            menuItems={dpdItems}
            label="Metric:"
            isReadOnly={isConfigureDatasetVisible}
          />

          <CSSTransition
            in={shouldShowMoreThanLessThanField}
            timeout={300}
            classNames="item"
            unmountOnExit
          >
            <MoreThanLessThanTextField
              value={value() === null ? null : value().toString()}
              unit={variableUnit}
              sign={
                doesStringContainThisText(state.metric, "less than") ? "<" : ">"
              }
              onChange={updateThresholdLessThanMoreThanValuesHandler}
              isReadOnly={isConfigureDatasetVisible}
            />
          </CSSTransition>

          <CSSTransition
            in={!isConfigureDatasetVisible}
            timeout={300}
            classNames="item"
            unmountOnExit
          >
            <div className={classes.btnContainer}>
              <Button
                disabled={!state.metric}
                disableElevation
                variant="contained"
                color="primary"
                className={classes.btn}
                style={{ marginRight: "16px" }}
                onClick={clearAllHandler}
              >
                Clear All
              </Button>
              <Button
                onClick={checkIsResolutionSelected}
                color="secondary"
                disabled={
                  selectedClimateVariableSource.includes("threshold")
                    ? selectedDataset === HAD_UK
                      ? !selectedResolution || !value()
                      : !value()
                    : selectedDataset === HAD_UK
                    ? !selectedResolution || !state.metric
                    : !state.metric
                }
                disableElevation
                variant="contained"
                className={classes.btn}
              >
                Select &amp; Continue
              </Button>
            </div>
          </CSSTransition>
        </SearchBox>
      </div>
    </CSSTransition>
  );
};

export default SelectClimateVariable;
