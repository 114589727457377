import React from "react";
import { Typography } from "@material-ui/core";
import CloseIcon from "../Icons/CloseIcon";

import {
  useReadOnlyLatLonTextFieldStyles,
  ReadOnlyLatLonTextFieldPropTypes,
} from "./useStyles";

const ReadOnlyLatLonTextField = (props: ReadOnlyLatLonTextFieldPropTypes) => {
  const classes = useReadOnlyLatLonTextFieldStyles();

  const { lat, lon, onDeleteHandler, disabled } = props;

  return (
    <div
      className={classes.container}
      style={{ pointerEvents: disabled ? "none" : "auto" }}
    >
      <div className={classes.labelContainer}>
        <label className={classes.labels} htmlFor="lat">
          Lat:
        </label>
      </div>
      <div className={classes.displayValueContainer}>
        <Typography noWrap style={{ fontSize: "12px" }}>
          {lat}
        </Typography>
      </div>
      <div className={classes.labelContainer} style={{ borderLeft: "none" }}>
        <label className={classes.labels} htmlFor="lat">
          Lon:
        </label>
      </div>
      <div className={classes.displayValueContainer}>
        {" "}
        <Typography noWrap style={{ fontSize: "12px" }}>
          {lon}
        </Typography>
      </div>
      <div
        className={classes.labelContainer}
        style={{
          width: "32px",
          borderLeft: "none",
          borderRight: "1.21806px solid #000",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          background: "#ADE5F7",
        }}
        onClick={onDeleteHandler}
      >
        <CloseIcon style={{ fontSize: "9px", color: "black" }} />
      </div>
    </div>
  );
};

export default ReadOnlyLatLonTextField;
