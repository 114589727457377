import React from "react";
import LocationSelectPage_Backup from "./LocationSelectPage_Backup";

import { RegionProvider } from "../../context/RegionContext";

const LocationSelectPage = () => {
  return (
    <RegionProvider>
      <LocationSelectPage_Backup />
    </RegionProvider>
  );
};

export default LocationSelectPage;
