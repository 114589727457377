import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

const PrecipitationIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    width="83"
    height="74"
    viewBox="0 0 83 74"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M29.9697 37.4621C31.9552 37.9866 33.154 40.047 32.6295 42.0325L27.7594 60.1267C27.235 62.1497 25.1745 63.311 23.1891 62.7865C21.1661 62.262 20.0048 60.2016 20.6042 58.2161L25.3993 40.1219C25.9238 38.099 27.9842 36.9376 29.9697 37.4621ZM44.9545 37.4621C46.94 37.9866 48.1388 40.047 47.6144 42.0325L39.8597 70.9907C39.3352 73.0511 37.2748 74.175 35.2519 73.6505C33.2664 73.0511 32.0676 71.0656 32.592 69.0427L40.3842 40.1219C40.9086 38.099 42.9691 36.9376 44.9545 37.4621ZM59.9394 37.4621C61.9249 37.9866 63.1237 40.047 62.5992 42.0325L57.7291 60.1267C57.2047 62.1497 55.1442 63.311 53.1587 62.7865C51.1358 62.262 49.9745 60.2016 50.5739 58.2161L55.369 40.1219C55.8935 38.099 57.9539 36.9376 59.9394 37.4621ZM59.9394 29.9697V26.2235C59.9394 21.2557 57.9659 16.4914 54.4532 12.9786C50.9404 9.46587 46.1761 7.49242 41.2083 7.49242C31.8428 7.49242 24.1631 14.3105 22.702 23.1891C21.4658 22.7395 20.1172 22.4773 18.7311 22.4773C15.7504 22.4773 12.8918 23.6613 10.7841 25.769C8.67649 27.8766 7.49242 30.7352 7.49242 33.7159C7.49242 37.8742 9.74015 41.508 13.1117 43.4561V43.4186C14.9848 44.4675 15.5093 46.8277 14.4978 48.5509C13.4489 50.3116 11.2386 50.9485 9.36553 49.8995V49.937C3.74621 46.7153 0 40.6464 0 33.7159C0 28.7481 1.97345 23.9838 5.4862 20.471C8.99896 16.9583 13.7633 14.9848 18.7311 14.9848C22.4773 6.18125 31.0936 0 41.2083 0C54.0578 0 64.5847 9.96492 65.5587 22.5897L67.4318 22.4773C71.406 22.4773 75.2175 24.056 78.0277 26.8662C80.8379 29.6764 82.4167 33.4879 82.4167 37.4621C82.4167 43.0814 79.4197 47.8391 74.9242 50.424C73.0511 51.4355 70.8409 50.8361 69.7919 49.0379C68.7804 47.2397 69.3049 44.9545 71.178 43.9056V43.9431C73.4258 42.6694 74.9242 40.2343 74.9242 37.4621C74.9242 35.475 74.1349 33.5693 72.7298 32.1642C71.3247 30.7591 69.4189 29.9697 67.4318 29.9697H59.9394Z" />
  </SvgIcon>
);

export default PrecipitationIcon;
