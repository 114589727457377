import * as React from 'react';

const ICON = `M25.4672 34.6991C23.055 34.6991 20.7415 33.7371 19.0358 32.0246C17.3301 30.3121 16.3718 27.9895 16.3718 25.5678C16.3718 23.146 17.3301 20.8234 19.0358 19.1109C20.7415 17.3985 23.055 16.4364 25.4672 16.4364C27.8795 16.4364 30.193 17.3985 31.8987 19.1109C33.6044 20.8234 34.5627 23.146 34.5627 25.5678C34.5627 26.7669 34.3274 27.9543 33.8703 29.0622C33.4132 30.17 32.7433 31.1767 31.8987 32.0246C31.0541 32.8725 30.0514 33.5451 28.9479 34.004C27.8444 34.4629 26.6617 34.6991 25.4672 34.6991ZM25.4672 0C18.7129 0 12.2352 2.69374 7.45918 7.48862C2.68315 12.2835 0 18.7868 0 25.5678C0 44.7436 25.4672 73.0507 25.4672 73.0507C25.4672 73.0507 50.9345 44.7436 50.9345 25.5678C50.9345 18.7868 48.2513 12.2835 43.4753 7.48862C38.6992 2.69374 32.2216 0 25.4672 0Z`;

const pinStyle = {
  fill: '#00ff00',
  stroke: '#000',
  with:'25px',
  height:"36px"
};

function Pointer(props) {
  const {size = 20} = props;

  return (
    <svg height={size} viewBox="0 0 51 74" style={pinStyle}>
      <path d={ICON} />
    </svg>
  );
}

export default React.memo(Pointer);
