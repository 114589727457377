import React, { createContext, useState } from "react";
import { CustomSnackbarType } from "../../../types/CustomSnackbarTypes";

interface SnackbarStateType {
  snackbar: CustomSnackbarType;
  setSnackbar: React.Dispatch<React.SetStateAction<CustomSnackbarType>>;
}

interface SnackbarContextType {
  snackbarState: SnackbarStateType;
}

export const SnackbarContext = createContext<SnackbarContextType>(
  {} as SnackbarContextType
);

type PropsType = {
  children: React.ReactNode;
};

export const SnackbarProvider = ({ children }: PropsType) => {
  const [snackbar, setSnackbar] = useState<CustomSnackbarType>({
    open: false,
    color: undefined,
    message: "",
  });

  return (
    <SnackbarContext.Provider
      value={{
        snackbarState: {
          snackbar,
          setSnackbar,
        },
      }}
    >
      {children}
    </SnackbarContext.Provider>
  );
};
