import React from "react";
import { makeStyles } from "@material-ui/core";

export type ReviewDatasetCardPropTypes = {
  title: string;
  description: string | React.ReactNode;
  icon?: React.ReactNode;
  iconPath?: string;
};

export const useReviewDatasetCardStyles = makeStyles({
  imgContainer: {
    width: "80px",
    height: " 80px",
    borderRadius: "50%",
    background:
      "linear-gradient(180deg, #88CFFF 31.77%, rgba(26, 59, 141, 0.69) 100%)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  heading: {
    marginTop: "2rem",
    fontWeight: "normal",
    fontSize: "1rem",
    lineHeight: "19px",
    color: "#1C1C1C",
  },

  description: {
    fontSize: "0.875rem",
    lineHeight: "16px",
    color: "#1C1C1C",
    marginTop: "0.625rem",
  },
});

export type ProjectDescriptionModalPropTypes = {
  open: boolean;
  closeModalHandler: () => void;
};

export const useProjectDescriptionModalStyles = makeStyles({
  iconsContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },

  contentContainer: {
    maxWidth: "349px",
    margin: "0 auto",

    marginTop: "1.875rem",

    "& p": {
      fontSize: "0.875rem",
      lineHeight: "15px",
      color: "#1C1C1C",
    },
  },

  textFieldContainer: {
    margin: "1.0625rem 0 0.9375rem 0",
  },

  textField: {
    height: "32px",
    "& input::placeholder": {
      color: "#1C1C1C",
      opacity: "1",
    },
  },

  btn: {
    width: "8.7975rem",
    lineHeight: "16px",
    color: " #1C1C1C",
    backgroundColor: "#F99052",
    fontSize: "0.8125rem",
    "&:hover": {
      backgroundColor: "#F99052",
    },
  },
});
